import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";

export default function TableTipsSort(props) {
  const { sx } = props;
  const theme = useTheme();
  const mode = theme?.palette?.mode;

  const slideStyles = {
    ...sx,
    background: theme?.palette?.info?.[mode === "dark" ? "light" : "dark"], //use light background for dark mode, vice versa
    color: theme?.palette?.background?.default,
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography component="h1" variant="h1">
            <FontAwesomeIcon icon={faArrowUp} />
            &nbsp;Sort
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            <strong>Get your ducks in a row (er, column).</strong> Use the Sort
            feature to order data alphabetically, numerically, or by&nbsp;date.
          </Typography>
        </Box>

        <Grid2 container spacing={2}>
          <Grid2 item>
            <img
              src={
                "https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-sort-1.png"
              }
              alt="screenshot with sort button"
            />
          </Grid2>
          <Grid2 item>
            <Typography variant="h4" component="h2">
              Click, click, click...
            </Typography>
            <Typography component="div" sx={{ maxWidth: 356 }}>
              Hover over any column header to reveal the sort
              button.&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowUp} />
              <ol>
                <li>Click once to sort&nbsp;ascending.</li>
                <li>Click again to sort&nbsp;descending.</li>
                <li>Click again to&nbsp;unsort.</li>
              </ol>
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2}>
          <Grid2 item xs={6}>
            <img
              src={
                "https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-sort-2.png"
              }
              alt="screenshot with sort button"
            />
          </Grid2>
          <Grid2 item xs={6}>
            <Typography variant="h4" component="h2">
              Or use the menu...
            </Typography>
            <Typography sx={{ maxWidth: 356 }}>
              Hover over any column header to reveal the menu
              button.&nbsp;&nbsp;
              <FontAwesomeIcon icon={faEllipsisV} />
            </Typography>
            <Typography gutterBottom sx={{ maxWidth: 356 }}>
              <ol>
                <li style={{ marginBottom: "1rem" }}>Click the menu button.</li>
                <li>
                  Choose to sort ascending or descending. If the column is
                  already sorted, you&apos;ll also see Unsort as an&nbsp;option.
                </li>
              </ol>
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}
