import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentAttendance({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const chartOptions = useMemo(() => {
    const distinctYears = Array.from(
      new Set(data?.map((d) => d.schoolYear))
    ).sort();

    const categorizeAttendance = (year, category) => {
      const filteredData = data.filter((item) => item.schoolYear === year);
      const count = filteredData.reduce((count, item) => {
        const combinedPercentage = item.excused + item.unexcused;
        const attendancePercentage = 100 - combinedPercentage * 100;
        if (
          category === "warning" &&
          attendancePercentage >= 90 &&
          attendancePercentage <= 94
        ) {
          return count + 1;
        } else if (category === "chronic" && attendancePercentage < 90) {
          return count + 1;
        }
        return count;
      }, 0);
      return count;
    };

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: (params) => {
          let tooltipText = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.value}<br/>`;
          });
          return tooltipText;
        },
      },
      xAxis: {
        type: "category",
        data: distinctYears,
      },
      yAxis: {
        type: "value",
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        textStyle: {
          color: theme?.palette?.text?.secondary,
        },
      },
      series: [
        {
          name: "Warning (90-94%)",
          type: "bar",
          label: {
            show: true,
            formatter: (params) => (params.value === 0 ? "" : params.value),
          },
          emphasis: {
            focus: "series",
          },
          data: distinctYears?.map((year) =>
            categorizeAttendance(year, "warning")
          ),
          color: theme?.palette?.chart && theme?.palette?.chart[0],
        },
        {
          name: "Chronic (<90%)",
          type: "bar",
          label: {
            show: true,
            formatter: (params) => (params.value === 0 ? "" : params.value),
          },
          emphasis: {
            focus: "series",
          },
          data: distinctYears?.map((year) =>
            categorizeAttendance(year, "chronic")
          ),
          color: theme?.palette?.chart && theme?.palette?.chart[1],
        },
      ],
    };
  }, [data, theme]);

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography variant="h6" color={theme?.palette?.text?.secondary}>
        Students with poor attendance
      </Typography>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <EChartsReact option={chartOptions} />
      )}
    </Paper>
  );
}
