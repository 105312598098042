import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetUsers() {
  const token = GetAccessToken();
  const url = "person/GetUsers";
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });
  return query;
}

export function useGetRoles() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `roles/GetRoles`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting roles. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );

  return query;
}
