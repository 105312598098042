import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation, QueryCache } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

//Get Activities
export function useGetActivities() {
  const token = GetAccessToken();
  const url = "activities/GetActivities";
  const query = useQuery(url, async ({ signal }) => {
    return await AppsGet(token, url, signal);
  });

  return query;
}

export function useGetActivityTypes() {
  const token = GetAccessToken();
  const url = `activities/GetActivityTypes`;
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });

  return query;
}

//Get Activity
export function useGetActivity(activityId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `activities/GetActivity/${activityId}`;

  const query = useQuery(
    url,
    async ({ signal }) => {
      if (!Boolean(activityId)) {
        return Promise.resolve();
      }
      return await AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting activity information. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  return query;
}

//Post Activity
export function usePostActivity(activity) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "activities/PostActivity";
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, activity);
    },
    {
      onSuccess: () => {
        queryClient.queryCache.clear();
        snackbar.enqueueSnackbar(`Activity Created`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error creating this activity. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );

  return mutation;
}

//Put Activity
export function usePutActivity(activityId, activity) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `activities/PutActivity/${activityId}`;
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      await AppsPut(token, url, activity);
    },
    {
      onSuccess: () => {
        queryClient.queryCache.clear();
        snackbar.enqueueSnackbar(`Activity Updated`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error updating this activity. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );

  return mutation;
}
