import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons";
import { useGetWaitListStats } from "../../services/hooks/programsHook";

export default function HomeChartWaitlist({ data, percentComplete, finished }) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const waitlistStatusQuery = useGetWaitListStats();
  const waitlistStats = waitlistStatusQuery.data?.data;

  const chartOptions = useMemo(() => {
    if (!waitlistStats) return {};

    const schoolYear = waitlistStats.schoolYear;
    const activeCount =
      waitlistStats.waitlistStats.find((status) => status.status === "Active")
        ?.count || 0;

    const inactiveRegisteredCount =
      waitlistStats.waitlistStats.find(
        (status) => status.status === "Inactive - registered"
      )?.count || 0;

    const totalCount = waitlistStats.waitlistStats.reduce(
      (sum, status) => sum + status.count,
      0
    );

    const inactiveNotRegisteredCount = waitlistStats.waitlistStats
      .filter(
        (status) =>
          status.status !== "Active" &&
          status.status !== "Inactive - registered"
      )
      .reduce((sum, status) => sum + status.count, 0);

    const colors = theme.palette.chart || [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.warning.main,
    ];

    return {
      //   title: {
      //     text: `Waitlist by School Year: ${schoolYear}`,
      //     left: "center",
      //     textStyle: {
      //       color: theme.palette.text.primary,
      //     },
      //   },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: [
          "Actively on waitlist",
          "Registered from waitlist",
          "Withdrawn from waitlist",
          "Total waitlist count",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Count",
          type: "bar",
          data: [
            activeCount,
            inactiveRegisteredCount,
            inactiveNotRegisteredCount,
            totalCount,
          ],
          itemStyle: {
            color: (params) => colors[params.dataIndex % colors.length],
          },
        },
      ],
    };
  }, [waitlistStats, theme]);

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        Waitlist by School Year ({waitlistStats?.schoolYear})
      </Typography>
      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
