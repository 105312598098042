import React, { useState, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import * as currency from "currency.js";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendar,
  faSave,
  faFamily,
  faChevronDown,
  faPersonChalkboard,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Error from "../common/Error";
import { useParams } from "react-router-dom";
import { useGetProgramDetailsPage } from "../../services/hooks/programsHook";
import { useGetProgramDaysAndAttendanceCountByProgramId } from "../../services/hooks/programDaysHook";
import {
  useGetParticipantAttendanceByProgramDayId,
  useBatchMergeProgramAttendance,
} from "../../services/hooks/programAttendancesHook";
import { TakeAttendance } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";
import {
  getTimeRange,
  ScrollToTopOnMount,
  getGradeName,
} from "../../services/utilities";
import ProgramDaysCalendar from "../common/ProgramDaysCalendar";
import Overflow from "../common/Overflow";

export default function Attendance() {
  const { user } = useUser();
  const { programId } = useParams();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();

  const [selectedDay, setSelectedDay] = useState();
  const programDaysQuery =
    useGetProgramDaysAndAttendanceCountByProgramId(programId);
  const programDays = useMemo(
    () =>
      programDaysQuery?.data?.data?.sort((a, b) => {
        return dayjs(a.scheduledDate).diff(dayjs(b.scheduledDate));
      }),
    [programDaysQuery]
  );

  //Default selected day to nearest day before today
  useEffect(() => {
    if (programDays && selectedDay == null) {
      const nearestDay = programDays.find((day) =>
        dayjs(day.scheduledDate).isBefore(dayjs())
      );
      setSelectedDay(nearestDay ?? programDays[0]);
    }
  }, [programDays, selectedDay]);

  const detailsQuery = useGetProgramDetailsPage(programId);
  const details = detailsQuery?.data?.data;

  const attendanceDayQuery = useGetParticipantAttendanceByProgramDayId(
    selectedDay?.programDayId
  );
  const attendanceDay = attendanceDayQuery?.data?.data;
  const [attendance, setAttendance] = useState();
  useEffect(() => {
    if (attendance == null && attendanceDay) {
      setAttendance(attendanceDay);
    }
  }, [attendance, attendanceDay]);

  const getPostObject = () => {
    let attendanceList = attendance?.map((att) => {
      if (att.programAttendance != null) return att.programAttendance;
      else
        return {
          programDayId: selectedDay.programDayId,
          participantId: att.participantId,
          programId: programId,
          scheduledDate: selectedDay.scheduledDate,
          attended: false,
        };
    });
    return attendanceList;
  };

  const postAttendance = useBatchMergeProgramAttendance(getPostObject());
  const clickSubmit = async () => {
    await postAttendance.mutateAsync().then(() => {
      setAttendance(null);
      //Update the status of the selected day
      let copySelectedDay = JSON.parse(JSON.stringify(selectedDay));
      copySelectedDay.hasAttendance = true;
      setSelectedDay(copySelectedDay);
    });
  };

  const getAttendanceStatus = (programDay) => {
    if (!programDay.hasParticipants) {
      return {
        status: "No Participants",
        styles: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        },
      };
    } else if (dayjs(programDay.scheduledDate).isAfter(dayjs())) {
      return {
        status: "Upcoming",
        styles: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        },
      };
    } else if (programDay.hasAttendance) {
      return {
        status: "Taken",
        styles: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        },
      };
    } else {
      return {
        status: "Missing",
        styles: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      };
    }
  };

  const getDayDisplay = (programDay) => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {dayjs(programDay.scheduledDate).format("MMM D, YYYY")}
        </Grid>
        <Grid item>
          <Typography
            sx={{
              ...chipStyles,
              ...getAttendanceStatus(programDay).styles,
            }}
          >
            {getAttendanceStatus(programDay).status}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getTimeWindow = () => {
    switch (user.role) {
      case "TPS Admin":
      case "TPS Owner":
        return 90;
      default:
        return 30;
    }
  };

  const hasExceededTimeWindow = useMemo(() => {
    if (details) {
      let daysBack = getTimeWindow();
      return dayjs().isAfter(dayjs(details.endDate).add(daysBack, "day"));
    }
  }, [details]);

  const formDisabled = () => {
    if (hasExceededTimeWindow) {
      return true;
    } else if (postAttendance.isLoading) return true;
    else if (dayjs(selectedDay?.scheduledDate).isAfter(dayjs())) return true;
    else if (!selectedDay?.hasParticipants) {
      return true;
    } else if (!user?.permissions?.includes(TakeAttendance)) {
      return true;
    }

    return false;
  };

  const clickAttendanceCheck = (participant, value) => {
    let copyAttendance = JSON.parse(JSON.stringify(attendance));
    let index = copyAttendance.findIndex(
      (p) => p.participantId === participant.participantId
    );

    //Edit existing record
    if (copyAttendance[index]?.programAttendance != null) {
      copyAttendance[index].programAttendance.attended = value;
    }
    //Append new record
    else {
      copyAttendance[index].programAttendance = {
        programDayId: selectedDay.programDayId,
        participantId: participant.participantId,
        programId: programId,
        scheduledDate: selectedDay.scheduledDate,
        attended: value,
      };
    }
    setAttendance(copyAttendance);
  };

  //Styles
  const centerContentStyles = {
    display: "flex",
    justifyContent: "center",
  };
  const chipStyles = {
    fontSize: "0.8rem",
    borderRadius: 1000,
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
  };
  const descriptionStyles = {
    color: theme.palette?.nav?.contrastTextSecondary,
    fontStyle: "italic",
  };
  const detailsContainerStyles = {
    backgroundColor: theme.palette.nav?.main,
    borderRadius: "16px",
    color: theme.palette.nav?.contrastText,
    my: theme.spacing(2),

    "* .MuiSkeleton-root": {
      backgroundColor: theme.palette?.nav?.contrastTextSecondary,
    },
  };
  const daySelectorStyles = {
    minWidth: 200,
  };

  return (
    <>
      <ScrollToTopOnMount />

      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1">Attendance</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(`/programs/${programId}`);
            }}
          >
            Details
          </Button>
        </Grid>
      </Grid>

      {/* Program details */}

      <Accordion sx={detailsContainerStyles}>
        <AccordionSummary
          expandIcon={
            <FontAwesomeIcon
              color={theme.palette?.nav?.contrastText}
              icon={faChevronDown}
            />
          }
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: theme.spacing(2) }}
          >
            <Grid item>
              <Typography variant="h3">
                {details?.title ?? details?.activityName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">Show Details</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <Container sx={{ pb: theme.spacing(2) }}>
          <Typography sx={descriptionStyles}>{details?.description}</Typography>
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <Stack spacing={4} sx={{ ml: theme.spacing(5) }}>
                {/* Location info */}
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faLocationDot}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="200px" />
                          <Skeleton width="180px" />
                          <Skeleton width="190px" />
                        </>
                      ) : (
                        <>
                          <Typography>{details?.locationName}</Typography>
                          <Typography>{details?.room}</Typography>
                          <Typography>
                            {details?.enableTransportation
                              ? "Transportation enabled"
                              : "Transportation disabled"}
                          </Typography>
                          {details?.url && (
                            <Typography variant="a">{details?.url}</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Instructor */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faPersonChalkboard}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="200px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {details?.instructors?.length > 0
                              ? details?.instructors?.join(", ")
                              : "No instructors"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Schedule info */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xs={2} item sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faCalendar}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="190px" />
                          <Skeleton width="210px" />
                          <Skeleton width="120px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {dayjs(details?.startDate).format("MMM D, YYYY")} -{" "}
                            {dayjs(details?.endDate).format("MMM D, YYYY")}
                          </Typography>
                          <Typography>{details?.cycleName}</Typography>
                          <Typography>
                            {getTimeRange(
                              details?.defaultStartTime,
                              details?.defaultDurationHours
                            )}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Compassinfo */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faFamily}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="80px" />
                          <Skeleton width="150px" />
                          <Skeleton width="200px" />
                          <Skeleton width="80px" />
                          <Skeleton width="180px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            <strong>Offered to:</strong>
                          </Typography>
                          <Typography>
                            {details?.grades
                              ?.map((g) => getGradeName(g))
                              .join(", ")}
                          </Typography>
                          <Overflow>
                            <Typography>
                              at {details?.schools?.join(", ")}
                            </Typography>
                          </Overflow>

                          <br />
                          <Typography variant="h5">Compass</Typography>
                          <Typography>
                            {details?.isHidden
                              ? "Hidden in Compass"
                              : "Visible"}
                          </Typography>
                          <Typography>
                            {details?.advertiseDate
                              ? `Advertise date: ${dayjs(
                                  details?.advertiseDate
                                ).format("MMM D, YYYY")}`
                              : "No advertise date set"}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            Registration:{" "}
                            {dayjs(details?.registrationStartDate).format(
                              "MMM D, YYYY"
                            )}{" "}
                            -{" "}
                            {dayjs(details?.registrationEndDate).format(
                              "MMM D, YYYY"
                            )}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            Cost:{" "}
                            {currency(details?.cost).value !== 0
                              ? currency(details?.cost).format()
                              : "FREE"}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            T-Shirt Required:{" "}
                            {details?.isTeeShirtRequired ? "Yes" : "No"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={[centerContentStyles, { flexDirection: "column" }]}
            >
              <ProgramDaysCalendar programId={programId} />
            </Grid>
          </Grid>
        </Container>
      </Accordion>

      <br />

      {/* Day selector */}
      {programDaysQuery.isLoading && <Skeleton width="300px" height="3rem" />}
      <Error
        message="There was a problem loading program days."
        query={programDaysQuery}
      />
      {programDays && (
        <FormControl variant="outlined">
          <InputLabel id="Day-label">Day</InputLabel>
          <Select
            sx={daySelectorStyles}
            autoWidth
            label="Day"
            labelId="Day-label"
            margin="dense"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
            value={selectedDay ?? ""}
            onChange={(e) => {
              setSelectedDay(e.target.value);
              setAttendance(null);
            }}
            renderValue={(selected) => {
              return <>{selected ? getDayDisplay(selected) : ""}</>;
            }}
          >
            {programDays?.map((programDay, index) => {
              return (
                <MenuItem key={index} value={programDay}>
                  <ListItemText>{getDayDisplay(programDay)}</ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <br />
      <br />

      {/* Attendance table */}
      <Error
        message="There was an error getting attendance"
        query={attendanceDayQuery}
      />
      {attendanceDayQuery.isLoading &&
        Array.from({ length: 5 }).map((a, i) => (
          <Skeleton height={50} variant="rect" sx={{ mb: 1 }} key={i} />
        ))}
      {attendance && (
        <>
          <Paper>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Attended</TableCell>
                    <TableCell>Entry</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendance?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="2">
                        <Typography
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          No participants enrolled on this day.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {attendance.map((participant, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={formDisabled()}
                                  checked={
                                    participant.programAttendance?.attended ??
                                    false
                                  }
                                  onChange={(e) => {
                                    clickAttendanceCheck(
                                      participant,
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label={participant.studentNameId}
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          {dayjs(participant.entryDate).format("M/D/YY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <br />
          <Grid container spacing={2}>
            {hasExceededTimeWindow && (
              <Grid item xs={12}>
                <Typography>
                  The time window to submit attendance has been exceeded. (
                  {getTimeWindow()} days)
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={() => {
                  clickSubmit();
                }}
                disabled={formDisabled()}
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faSave} />}
                endIcon={
                  postAttendance.isLoading && <CircularProgress size={20} />
                }
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  navigate(`/programs/${programId}`);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
