import { useMemo, useCallback } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentAssessments({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const generateChartOptions = useCallback(
    (filteredData, labels, isAP, levelOrder, legendOrient = "horizontal") => {
      const groupedData = {};
      filteredData.forEach((item) => {
        const key = item.subjectArea;
        if (!groupedData[key]) {
          groupedData[key] = {};
        }
        if (isAP) {
          const score = item.score;
          if (!groupedData[key][score]) {
            groupedData[key][score] = 0;
          }
          groupedData[key][score] += 1;
        } else {
          const level = item.level;
          if (!groupedData[key][level]) {
            groupedData[key][level] = 0;
          }
          groupedData[key][level] += 1;
        }
      });

      const colors = theme.palette.chart;
      const series = isAP
        ? [1, 2, 3, 4, 5].map((score, i) => ({
            name: score.toString(),
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: (params) => (params.value === 0 ? "" : params.value),
            },
            emphasis: {
              focus: "series",
            },
            data: labels.map((label) => groupedData[label][score] || 0),
            color: colors[i % colors.length],
          }))
        : levelOrder.map((level, i) => ({
            name: level,
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: (params) => (params.value === 0 ? "" : params.value),
            },
            emphasis: {
              focus: "series",
            },
            data: labels.map((label) => groupedData[label][level] || 0),
            color: colors[i % colors.length],
          }));

      const chartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: labels,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          bottom: "bottom",

          textStyle: {
            color: theme.palette.text.secondary,
            rich: {
              wrap: {
                width: 100,
                overflow: "break",
              },
            },
          },
        },
        series,
      };

      return chartOptions;
    },
    [theme]
  );

  const sbaOptions = useMemo(() => {
    const filteredData = data.filter((item) => item.testName === "SBA");
    const subjects = [
      ...new Set(filteredData.map((item) => item.subjectArea)),
    ].sort();
    const levelOrder = ["Level 1", "Level 2", "Level 3", "Level 4"];
    return generateChartOptions(
      filteredData,
      subjects,
      false,
      levelOrder,
      "horizontal"
    );
  }, [data, generateChartOptions]);

  const iReadyOptions = useMemo(() => {
    const filteredData = data.filter(
      (item) => item.testName.toLowerCase() === "iready"
    );

    const subjects = [
      ...new Set(filteredData.map((item) => item.subjectArea)),
    ].sort();
    const levelOrder = [
      "Three or More Grade Levels Below",
      "Two Grade Levels Below",
      "One Grade Level Below",
      "Early On Grade Level",
      "Mid/Above Grade Level",
    ];
    return generateChartOptions(
      filteredData,
      subjects,
      false,
      levelOrder,
      "vertical"
    );
  }, [data, generateChartOptions]);

  const apOptions = useMemo(() => {
    const filteredData = data.filter((item) => item.testName === "AP");
    const subjects = [
      ...new Set(filteredData.map((item) => item.subjectArea)),
    ].sort();
    return generateChartOptions(filteredData, subjects, true, "horizontal");
  }, [data, generateChartOptions]);

  const hasSBA = sbaOptions?.series?.some((series) =>
    series.data.some((value) => value > 0)
  );
  const hasIReady = iReadyOptions?.series?.some((series) =>
    series.data.some((value) => value > 0)
  );
  const hasAP = apOptions?.series?.some((series) =>
    series.data.some((value) => value > 0)
  );

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {hasSBA && (
            <Grid item xs={12} md={hasIReady ? 6 : 12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Students by SBA
              </Typography>
              <EChartsReact option={sbaOptions} />
            </Grid>
          )}
          {hasIReady && (
            <Grid item xs={12} md={hasSBA ? 6 : 12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Students by iReady
              </Typography>
              <EChartsReact option={iReadyOptions} />
            </Grid>
          )}
          {hasAP && (
            <Grid item xs={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Students by AP
              </Typography>
              <EChartsReact option={apOptions} />
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
}
