import { useQuery } from "react-query";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetGradesByLocationIds(locationIds) {
  const token = GetAccessToken();
  const url = `Grades/GetGradesByLocationIds?locationIdsList=${locationIds?.join(
    ","
  )}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: locationIds != null && locationIds.length > 0,
    }
  );
  return query;
}
