import EChartsReact from "echarts-for-react";
import { Paper, Typography, useTheme } from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useGetPartnerHomeCounts } from "../../services/hooks/partnerHomeHook";

export default function HomeChartCountsBySession() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const query = useGetPartnerHomeCounts();
  const data = query?.data?.data?.activitiesType;

  const chartOptions = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: "60%",
        color: theme.palette.chart,
        label: {
          color: theme.palette.text.secondary,
        },
        bottom: "-20px",
        data: data?.map((d, i) => {
          return {
            name: d.name,
            value: d.programs,
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        Current Programs by Activity&nbsp;Type
      </Typography>
      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
