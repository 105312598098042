import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import HomeTps from "../common/HomeTps";
import HomePartner from "../common/HomePartner";
import HomeSiteLead from "../common/HomeSiteLead";
import HomeProvider from "../common/HomeProvider";
import HomeInstructor from "../common/HomeInstructor";
import HomeDsa from "../common/HomeDsa";
import HomeHowWeGotStarted from "../common/HomeHowWeGotStarted";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Home() {
  const { user } = useUser();

  const GetUserHomePage = () => {
    switch (user.role) {
      case "TPS Owner":
      case "TPS Admin":
      case "TPS Viewer":
        return <HomeTps />;
      case "Partner Manager":
      case "Partner Viewer":
        return <HomePartner />;
      case "Site Lead":
        return <HomeSiteLead />;
      case "Provider Manager":
        return <HomeProvider />;
      case "DSA Viewer":
        return <HomeDsa />;
      default:
        return <HomeInstructor />;
    }
  };

  return (
    <>
      <ScrollToTopOnMount />

      <Typography variant="h1">Home</Typography>
      {GetUserHomePage()}
      <HomeHowWeGotStarted />
    </>
  );
}
