import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import { Paper, Typography, useTheme, useMediaQuery } from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useGetPartnerHomeCounts } from "../../services/hooks/partnerHomeHook";

export default function HomeChartCountsBySession() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const xsScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const sessions = ["1", "2", "3", "4", "5"];

  const query = useGetPartnerHomeCounts();
  const data = useMemo(() => {
    let dataCopy =
      query?.data?.data?.schoolYears &&
      JSON.parse(JSON.stringify(query?.data?.data?.schoolYears));
    return dataCopy;
  }, [query]);

  const chartOptions = {
    xAxis: {
      type: "category",
      data: sessions,
      name: "Sessions",
      nameLocation: "middle",
      nameGap: 30,
      nameTextStyle: {
        fontWeight: "bold",
      },
      axisLabel: {
        color: theme.palette.text.secondary,
      },
    },
    yAxis: {
      type: "value",
      name: "Students",
      nameLocation: "end",
      nameTextStyle: {
        align: "right",
        fontWeight: "bold",
      },
      label: "Students",
      axisLabel: {
        color: theme.palette.text.secondary,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltip: {
      trigger: "axis",
    },
    grid: {
      left: xsScreen ? 20 : 60,
      right: 0,
      height: xsScreen ? 271 : 170,
      containLabel: xsScreen ? true : false,
    },
    legend: {
      top: 25,
      right: 5,
      data: data?.map((d) => d.schoolYear.toString()),
      textStyle: {
        color: theme.palette.text.secondary,
      },
    },
    series: data?.map((d, i) => {
      return {
        name: d.schoolYear.toString(),
        color: theme.palette.chart && theme.palette.chart[i],
        data:
          sessions?.map(
            (s) => d.sessionCounts?.find((sc) => sc.session === s)?.count ?? 0
          ) ?? [],
        type: "line",
        smooth: true,
      };
    }),
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        Enrollment by Session
      </Typography>
      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
