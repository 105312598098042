import React, { useEffect, useState, useMemo } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  MobileStepper,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import DialogCloseButton from "./DialogCloseButton";
import TableTipsIntro from "./TableTipsIntro";
import TableTipsSort from "./TableTipsSort";
import TableTipsFilter from "./TableTipsFilter";
import TableTipsGroup from "./TableTipsGroup";
import TableTipsVisibility from "./TableTipsVisibility";
import TableTipsOutro from "./TableTipsOutro";

export default function TableTips(props) {
  const { content, buttonText, variant } = props;
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  //Since our DataGrids are not very useful on small screens, we only show the tips on large screens
  const [dialogOpen, setDialogOpen] = useState(false);

  const slideContent = useMemo(() => {
    const slideContentStyles = {
      height: "100%",
      p: 4,
      "& h1": {
        fontSize: "3em",
        mr: 4,
      },
      "& h2": {
        mb: 2,
      },
    };

    return !!content && content?.length > 0
      ? content
      : [
          <TableTipsIntro sx={slideContentStyles} />,
          <TableTipsSort sx={slideContentStyles} />,
          <TableTipsFilter sx={slideContentStyles} />,
          <TableTipsGroup sx={slideContentStyles} />,
          <TableTipsVisibility sx={slideContentStyles} />,
          <TableTipsOutro sx={slideContentStyles} />,
        ];
  }, [content]);

  const [activeStep, setActiveStep] = useState(0);
  const [numberOfSteps, setNumberOfSteps] = useState(slideContent?.length);

  useEffect(() => {
    setNumberOfSteps(slideContent?.length);
  }, [slideContent]);

  const handleMainButtonClick = () => {
    setActiveStep(0);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleNext = () => {
    if (activeStep === numberOfSteps - 1) {
      dialogOpen(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //#region STYLES
  const slideStyles = {
    width: 836, // 836 is 900 (md width) minus 64 for dialog margins
    height: 836,
  };
  //see slideContentStyles above in the useMemo
  const actionsStyles = {
    borderTop: `1px solid ${theme?.palette?.neutral?.main}`,
    padding: 0,
    justifyContent: "center",
  };
  const navButtonStyles = {
    height: "2em",
    width: "2em",
  };
  //#endregion

  //MARK: RENDER
  return (
    largeScreen && (
      <>
        <Button
          variant={variant ?? "text"}
          size="small"
          startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
          onClick={() => {
            handleMainButtonClick();
          }}
        >
          {buttonText ?? "Tips on using this Table"}
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-paper": {
              border: "none",
              width: 836,
              minWidth: 836,
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            <DialogCloseButton
              handleClose={handleClose}
              inverse={activeStep !== 3 ? true : false}
            />
            <Box sx={slideStyles}>{slideContent[activeStep]}</Box>
          </DialogContent>
          <DialogActions sx={actionsStyles}>
            <MobileStepper
              position={"static"}
              steps={numberOfSteps}
              activeStep={activeStep}
              nextButton={
                <IconButton
                  onClick={handleNext}
                  disabled={activeStep === numberOfSteps - 1}
                  aria-label="Next"
                  sx={navButtonStyles}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              }
              backButton={
                <IconButton
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  aria-label="Back"
                  sx={navButtonStyles}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
              }
              sx={{ background: "rgba(0, 0, 0, 0)" }}
            />
          </DialogActions>
        </Dialog>
      </>
    )
  );
}
