import React, { useState, useEffect } from "react";
import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Error from "../common/Error";
import ProgramStatusChip from "../common/ProgramStatusChip";
import {
  getComparator,
  stableSort,
  EnhancedTableHead,
} from "../../services/tableFunctions";
import { DraftPrograms, ManagePrograms } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";

export default function ProgramTable(props) {
  const { programs, query } = props;
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programMenuAnchor, setProgramMenuAnchor] = useState(null);

  //table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "ProgramsListRowsPerPage",
    10
  );

  //reset page when data changes
  useEffect(() => {
    setPage(0);
  }, [programs]);

  const columns = [
    {
      id: "title",
      numeric: false,
      label: "Name",
    },
    {
      id: "provider",
      numeric: false,
      label: "Provider",
    },
    {
      id: "locationName",
      numeric: false,
      label: "Location",
    },
    {
      id: "startDate",
      numeric: false,
      label: "Schedule",
    },
    {
      id: "registrationStartDate",
      numeric: false,
      label: "Registration",
    },
    {
      id: "enrolled",
      numeric: false,
      label: "Enrolled",
    },
    {
      id: "numAttendanceMissing",
      numeric: false,
      label: "Status",
    },
    {
      id: "",
      numeric: false,
      label: "",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setSelectedProgram(null);
    setProgramMenuAnchor(null);
  };

  //Styles
  const programTitleStyles = {
    fontSize: "1.2rem",
    fontWeight: "bold",
  };

  return (
    <div>
      <Menu
        id="program-options-menu"
        aria-labelledby="program-options-button"
        anchorEl={programMenuAnchor}
        open={selectedProgram !== null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          key={`0`}
          onClick={() => {
            handleMenuClose();
            navigate(`/programs/${selectedProgram.programId}`);
          }}
        >
          Details
        </MenuItem>
        <MenuItem
          key={`1`}
          onClick={() => {
            handleMenuClose();
            navigate(`/programs/attendance/${selectedProgram.programId}`);
          }}
        >
          Attendance
        </MenuItem>
        {(user?.permissions?.includes(ManagePrograms) ||
          user?.permissions?.includes(DraftPrograms)) && (
          <MenuItem
            key={`2`}
            onClick={() => {
              handleMenuClose();
              navigate(`/programs/edit/${selectedProgram.programId}`);
            }}
          >
            Edit
          </MenuItem>
        )}
      </Menu>
      <Error message={"There was a problem loading programs."} query={query} />
      <Paper>
        <>
          <TableContainer>
            <Table
              aria-label="program table"
              size="small"
              sx={commonStyles.tableStyles}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={programs?.length ?? 0}
                columns={columns}
              />

              <TableBody>
                {programs?.length === 0 && (
                  <TableRow key={`NoPrograms`}>
                    <TableCell colSpan="8">
                      <Typography
                        sx={{
                          m: theme.spacing(2),
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <strong>No programs found</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {query?.isLoading &&
                  Array(5)
                    ?.fill(0)
                    ?.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan="8">
                          <Skeleton variant="rect" height={60} />
                        </TableCell>
                      </TableRow>
                    ))}
                {programs &&
                  stableSort(programs, getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((program, index) => (
                      <TableRow
                        key={`programLink${index}`}
                        hover={true}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/programs/${program.programId}`);
                        }}
                      >
                        <TableCell>
                          <Typography sx={programTitleStyles}>
                            {program.title ?? "(No title)"}
                          </Typography>
                          <Typography>
                            {program.activityName} - {program.activityTypeName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {program.iconUrl && (
                            <img
                              style={{ maxWidth: "4rem" }}
                              src={program.iconUrl}
                              alt="Partner logo"
                              title={program.provider}
                            />
                          )}
                          <Typography variant="subtitle1">
                            {program.provider}
                          </Typography>
                          {user?.sharedPartners?.includes(
                            program?.partnerId
                          ) && (
                            <Chip
                              size="small"
                              color="secondary"
                              label="shared"
                              variant="outlined"
                            />
                          )}
                        </TableCell>

                        <TableCell>
                          <Typography>{program.locationName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {dayjs(program.startDate).format("M/D/YY")} -{" "}
                            {dayjs(program.endDate).format("M/D/YY")}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            {program.cycleName}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {program.registrationOpen}
                            {`${dayjs(program.registrationStartDate).format(
                              "M/D/YY"
                            )} - ${dayjs(program.registrationEndDate).format(
                              "M/D/YY"
                            )}`}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            {program.gradeLevels.join(", ")}
                          </Typography>
                          {program?.restrictions?.map((r, i) => (
                            <Chip
                              sx={{
                                color: theme.palette.nav?.contrastText,
                                backgroundColor: theme.palette.nav?.main,
                                m: theme.spacing(0.5),
                              }}
                              label={r}
                              key={i}
                            />
                          ))}
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xs={12}>
                              {`${program.enrolled} / ${program.maxEnrollment}`}
                            </Grid>
                            <Grid item xs={12}>
                              {!!program.hasWaitlist && (
                                <Chip
                                  sx={{
                                    backgroundColor: theme.palette.warning.main,
                                    color: theme.palette.warning.contrastText,
                                  }}
                                  label={"Active waitlist"}
                                  key={program?.programId}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <ProgramStatusChip
                            startDate={program.startDate}
                            endDate={program.endDate}
                            numParticipants={program.enrolled}
                            numAttendanceMissing={program.numAttendanceMissing}
                            isDraft={program.isDraft}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            id={`composition-button-${program.programId}`}
                            aria-controls={
                              selectedProgram === program
                                ? "composition-menu"
                                : undefined
                            }
                            aria-expanded={
                              selectedProgram === program ? "true" : undefined
                            }
                            aria-haspopup="true"
                            onClick={(e) => {
                              //prevent propagation
                              e.stopPropagation();
                              setSelectedProgram(program);
                              setProgramMenuAnchor(e.target);
                            }}
                            sx={{ width: "2.5rem" }}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={programs?.length ?? 0}
            rowsPerPage={rowsPerPage ?? 0}
            page={page ?? 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </div>
  );
}
