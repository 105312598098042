import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import {
  GetAccessToken,
  AppsGet,
  AppsPut,
  AppsPost,
  AppsPatch,
} from "../apiService";

export function useGetEligibleStudents(programId, registerOpen) {
  const token = GetAccessToken();
  const url = `participants/GetEligibleStudents/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: programId != null && registerOpen,
    }
  );
  return query;
}

export function useGetParticipant(participantId) {
  const token = GetAccessToken();
  const url = `participants/GetParticipant/${participantId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: participantId != null,
    }
  );
  return query;
}

export function useGetParticipantsByProgramId(programId) {
  const token = GetAccessToken();
  const url = `participants/GetParticipantsByProgramId/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (!Boolean(programId)) {
        return Promise.resolve();
      }
      return AppsGet(token, url, signal);
    },
    {
      enabled: programId != null && programId !== "",
    }
  );
  return query;
}

export function useGetParticipantsPriorityContact(programId) {
  const token = GetAccessToken();
  const url = `participants/GetParticipantsPriorityContact/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (!Boolean(programId)) {
        return Promise.resolve();
      }
      return AppsGet(token, url, signal);
    },
    {
      enabled: programId != null && programId !== "",
    }
  );
  return query;
}

export function useGetRoster(scheduledDate, locationIds) {
  const token = GetAccessToken();
  const url = `participants/GetRoster/${scheduledDate}${
    locationIds?.length > 0 ? `?locationIds=${locationIds?.join(",")}` : ""
  }`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: scheduledDate != null,
    }
  );
  return query;
}

export function useGetStudentParticipantDetails(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `participants/GetStudentParticipantDetails/${studentId}`;

  const query = useQuery(
    url,
    ({ signal }) => {
      if (!Boolean(studentId)) {
        return Promise.resolve();
      }
      return AppsGet(token, url, signal);
    },
    {
      enabled: studentId != null && studentId !== "",
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting participant details. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return query;
}

export function useGetParticipantDetails(participantId) {
  const token = GetAccessToken();
  const url = `participants/GetParticipantDetails/${participantId}`;

  const query = useQuery(
    url,
    ({ signal }) => {
      if (!Boolean(participantId)) {
        return Promise.resolve();
      }
      return AppsGet(token, url, signal);
    },
    {
      enabled: participantId != null && participantId !== "",
    }
  );

  return query;
}

export function useGetParticipantDetailsByStudentId(studentId) {
  const token = GetAccessToken();
  const url = `participants/GetParticipantDetailsByStudentId/${studentId}`;

  const query = useQuery(
    url,
    ({ signal }) => {
      if (!Boolean(studentId)) {
        return Promise.resolve();
      }
      return AppsGet(token, url, signal);
    },
    {
      enabled: studentId != null && studentId !== "",
    }
  );

  return query;
}

export function usePutParticipant(participant) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "participants/PutParticipant";
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      await AppsPut(token, url, participant);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("participants")
        );
        snackbar.enqueueSnackbar(`Participant saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving changes to the participant. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );

  return mutation;
}

export function usePostParticipant(participant) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "participants/PostParticipant";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, participant);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("participants")
        );
        snackbar.enqueueSnackbar(`Participant registered`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error registering the participant. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function useWithdrawParticipant() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `participants/WithdrawParticipant/`;
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async ({ participantId, date }) => {
      await AppsPatch(
        token,
        url + participantId + (date != null ? `?date=${date}` : ""),
        {}
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("participants")
        );
        snackbar.enqueueSnackbar(`Participant withdrawn`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error withdrawing the participant. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

//WAITLIST HOOKS

export function useGetWaitlistByProgramId(programId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `waitlist/GetWaitlistByProgramId/${programId}`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    onError: (error) => {
      snackbar.enqueueSnackbar(`Error getting waitlist: ${error}`, {
        variant: "error",
      });
    },
    enabled: !!programId,
  });
  return query;
}
export function useGetWaitlistByStudentId(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `waitlist/GetWaitlistByStudentId/${studentId}`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    onError: (error) => {
      snackbar.enqueueSnackbar(`Error getting waitlist: ${error}`, {
        variant: "error",
      });
    },
    enabled: !!studentId,
  });
  return query;
}

export function useGetWaitlistStatuses() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `waitlistStatus/GetWaitlistStatuses`;
  const query = useQuery(url, ({ signal }) => AppsGet(token, url, signal), {
    onError: (error) => {
      snackbar.enqueueSnackbar(`Error getting waitlist status: ${error}`, {
        variant: "error",
      });
    },
  });
  return query;
}

export function useUpsertWaitlist(waitlist) {
  const token = GetAccessToken();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const url = `Waitlist/UpsertWaitlist`;
  const mutation = useMutation(
    async () => {
      return await AppsPost(token, url, waitlist);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `waitlist/GetWaitlistByProgramId/${waitlist?.programId}`
        );
        queryClient.invalidateQueries(
          `Waitlist/GetWaitlistByStudentId/${waitlist?.studentId}`
        );
        queryClient.invalidateQueries(
          `participants/GetParticipantsByProgramId/${waitlist?.programId}`
        );
        snackbar.enqueueSnackbar("Waitlist request successfully sent", {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error sending the waitlist request. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function useGetGetWaitlistStatuses() {
  const token = GetAccessToken();

  const snackbar = useSnackbar();
  const url = `WaitlistStatus/GetWaitlistStatuses`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting waitlist information. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}
