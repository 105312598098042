import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetSchools() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "schools/GetPartnerSchools";
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting schools. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
