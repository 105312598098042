import React, { useState, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
  useTheme,
  Fade,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Error from "../common/Error";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faSave, faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  useGetPerson,
  useUpsertPerson,
  useGetPersonFromGraph,
  useInvitePerson,
} from "../../services/hooks/personHook";
import { useUser } from "../../services/contexts/userContext";
import { useGetRoles } from "../../services/hooks/rolesHook";
import { useGetPartners } from "../../services/hooks/partnersHook";
import { useGetProviders } from "../../services/hooks/providersHook";
import { useGetLocations } from "../../services/hooks/locationHook";
import {
  ScrollToTopOnMount,
  validateEmail,
  formatDateTime,
} from "../../services/utilities";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function UserCru() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { personId } = useParams();
  const isEdit = personId != null;
  const { user } = useUser();
  const snackbar = useSnackbar();

  //Fields
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [middleName, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [roleId, setRoleId] = useState();
  const [roleIdError, setRoleIdError] = useState("");

  const [partnerId, setPartnerId] = useState();
  const [partnerIdError, setPartnerIdError] = useState("");

  const [providerId, setProviderId] = useState();
  const [providerIdError, setProviderIdError] = useState("");

  const [locationIds, setlocationIds] = useState();
  const [locationIdsError, setlocationIdsError] = useState("");

  const [active, setActive] = useState(true);
  const [oid, setOid] = useState();

  // User Details
  const [resend, setResend] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [redeemUrl, setRedeemUrl] = useState("Redeem URL goes here");

  //Data
  const personQuery = useGetPerson(personId);
  const personData = personQuery?.data?.data;

  const personFromGraphQuery = useGetPersonFromGraph(personId);
  const personFromGraphData = personFromGraphQuery?.data?.data;

  const rolesQuery = useGetRoles();
  const roles = rolesQuery?.data?.data?.filter(
    (r) => r.available || r.roleId === roleId || user.role === "TPS Owner"
  );

  const partnersQuery = useGetPartners();
  const partners = partnersQuery?.data?.data?.filter(
    (p) => p.active || p.partnerId === partnerId
  );

  const providersQuery = useGetProviders();
  const providers = providersQuery?.data?.data?.filter(
    (p) => p.active || p.providerId === providerId
  );

  const locationsQuery = useGetLocations();
  const locations = locationsQuery?.data?.data?.filter((l) => l.active);

  useEffect(() => {
    if (personData) {
      setFirstName(personData.firstName);
      setMiddleName(personData.middleName);
      setLastName(personData.lastName);
      setEmail(personData.userLogon);
      setActive(personData.active);
      setOid(personData.oid);
      if (personData.personRoles?.length > 0) {
        setPartnerId(personData.personRoles[0]?.partnerId);
        setProviderId(personData.personRoles[0]?.providerId);
        setRoleId(personData.personRoles[0]?.roleId);
        setlocationIds(personData.personRoles?.map((r) => r.locationId));
      }
    }
  }, [personData]);

  const partnerRequired = useMemo(
    () => roles?.find((r) => r.roleId === roleId)?.partnerRequired,
    [roles, roleId]
  );
  const providerRequired = useMemo(
    () => roles?.find((r) => r.roleId === roleId)?.providerRequired,
    [roles, roleId]
  );
  const locationRequired = useMemo(
    () => roles?.find((r) => r.roleId === roleId)?.locationRequired,
    [roles, roleId]
  );

  const postBody = {
    personId: personId ?? 0,
    userLogon: email,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    active: active,
    oid: oid,
    personRoles:
      roleId != null && (locationIds?.length ?? 0) <= 1
        ? [
            {
              roleId: roleId,
              partnerId: partnerId,
              providerId: providerId,
              locationId: locationIds?.length > 0 ? locationIds[0] : null,
              personRoleId: personData?.personRoles?.find(
                (pr) =>
                  pr.roleId === roleId &&
                  pr.partnerId === partnerId &&
                  pr.providerId === providerId &&
                  pr.locationId ===
                    (locationIds?.length > 0 && locationIds[0])?.personRoleId
              )?.personRoleId,
            },
          ]
        : locationIds?.map((l) => {
            return {
              roleId: roleId,
              partnerId: partnerId,
              providerId: providerId,
              locationId: l,
              personRoleId: personData?.personRoles?.find(
                (pr) =>
                  pr.roleId === roleId &&
                  pr.partnerId === partnerId &&
                  pr.providerId === providerId &&
                  pr.locationId === (locationIds?.length > 0 && l)?.personRoleId
              ),
            };
          }),
    createdBy: personData?.createdBy,
    createdDate: personData?.createdDate,
    modifiedBy: personData?.modifiedBy,
    modifiedDate: personData?.modifiedDate,
  };

  const ValidateForm = () => {
    var valid = true;

    if (email === "" || email === null) {
      setEmailError("Email is required.");
      valid = false;
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email address.");
      valid = false;
    }
    if (
      roles?.find((r) => r.roleId === roleId)?.name?.startsWith("TPS") &&
      email?.split("@")[1]?.toLowerCase() !== "tacoma.k12.wa.us"
    ) {
      setRoleIdError("This role is restricted to TPS staff only.");
      valid = false;
    }
    if (partnerRequired && partnerId == null) {
      setPartnerIdError("A Partner is required for this role.");
      valid = false;
    }
    if (providerRequired && providerId == null) {
      setProviderIdError("A Provider is required for this role.");
      valid = false;
    }
    if (locationRequired && (locationIds?.length ?? 0) === 0) {
      setlocationIdsError("At least one location is required for this role.");
      valid = false;
    }

    return valid;
  };

  const postPerson = useUpsertPerson(postBody);

  const ClickSave = () => {
    if (ValidateForm()) {
      postPerson.mutateAsync()?.then((res) => {
        navigate("/users");
      });
    }
  };

  const postInvitePerson = useInvitePerson(postBody);

  const handleResend = () => {
    setResend(true);
    postInvitePerson.mutateAsync(postBody)?.then((response) => {
      setRedeemUrl(response?.data?.inviteRedeemUrl);
      setIsDialogOpen(true);
    });
  };

  const formDisabled =
    personQuery?.isLoading ||
    postPerson?.isLoading ||
    user.personId === personId;

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h1">
            {isEdit ? "Edit" : "Create"} User
          </Typography>
        </Grid>
        {personQuery?.isLoading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
      <br />
      {user.personId === personId && (
        <Chip
          variant="contained"
          color="error"
          label="You may not edit yourself."
          sx={{ mb: theme.spacing(2) }}
        />
      )}
      <Grid container spacing={2}>
        {/* Guest Invite */}
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Error
            message="There was an error getting details from Graph."
            query={personFromGraphQuery}
          />
          {personFromGraphQuery?.isLoading && (
            <>
              <Skeleton width="135px" />
              <Skeleton width="210px" />
              <Skeleton width="196px" />
              <Skeleton width="204px" />
              <Skeleton width="178px" />
              <Skeleton width="230px" />
              <Skeleton width="370px" />
            </>
          )}
          {personFromGraphQuery?.isSuccess && (
            <>
              <Typography component="p" variant="p">
                <strong>User Type: </strong>
                {personFromGraphData?.userType}
              </Typography>
              <Typography component="p" variant="p">
                <strong>Created: </strong>
                {formatDateTime(personFromGraphData?.createdDateTime)}
              </Typography>
              <Typography component="p" variant="p">
                <strong>Creation Type: </strong>
                {personFromGraphData?.creationType ?? "Auto"}
              </Typography>
              <Typography component="p" variant="p">
                <strong>Invitation State: </strong>
                {personFromGraphData?.externalUserState ?? "No Invite Sent"}
                <Chip
                  sx={{ px: "10px", marginLeft: "10px" }}
                  color="primary"
                  label={postInvitePerson?.isLoading ? "sending" : "resend"}
                  size="small"
                  icon={
                    postInvitePerson?.isLoading ? (
                      <CircularProgress size="0.8rem" />
                    ) : (
                      <FontAwesomeIcon icon={faEnvelope} />
                    )
                  }
                  clickable
                  onClick={() => {
                    handleResend();
                  }}
                  disabled={personFromGraphData.userType !== "Guest" || resend}
                />
              </Typography>
              <Typography component="p" variant="p">
                <strong>Account Enabled: </strong>
                {personFromGraphData?.accountEnabled}
              </Typography>
              <Typography component="p" variant="p">
                <strong>Last Signin: </strong>
                {formatDateTime(
                  personFromGraphData?.signInActivity?.lastSignInDateTime
                )}
              </Typography>
              <Typography component="p" variant="p">
                <strong>Last Non-Interactive Signin: </strong>
                {formatDateTime(
                  personFromGraphData?.signInActivity
                    ?.lastNonInteractiveSignInDateTime
                )}
              </Typography>
            </>
          )}
        </Grid>

        {/* First Name */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              label="First Name"
              value={firstName ?? ""}
              onChange={(e) => {
                setFirstName(e.target.value.trim());
                setFirstNameError("");
              }}
              error={!!firstNameError}
              inputProps={{
                maxLength: 32,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <FormHelperText error={!!firstNameError}>
              {firstNameError}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Middle Name */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              label="Middle Name"
              value={middleName ?? ""}
              onChange={(e) => {
                setMiddleName(e.target.value);
                setMiddleNameError("");
              }}
              error={!!middleNameError}
              inputProps={{
                maxLength: 32,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <FormHelperText error={!!middleNameError}>
              {middleNameError}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Last Name */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              label="Last Name"
              value={lastName ?? ""}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError("");
              }}
              error={!!lastNameError}
              inputProps={{
                maxLength: 64,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <FormHelperText error={!!lastNameError}>
              {lastNameError}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Email */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled || !!personId}
              fullWidth
              label="Email"
              type="email"
              value={email ?? ""}
              helperText="Used to create a unique account and cannot be edited. (Wrong? Make this user inactive and create a new user with the right email address.)"
              onChange={(e) => {
                setEmail(e.target.value.trim());
                validateEmail(e.target.value.trim()) && setEmailError("");
              }}
              error={!!emailError}
              inputProps={{
                maxLength: 256,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <FormHelperText error={!!emailError}>{emailError}</FormHelperText>
          </FormControl>
        </Grid>

        {/* Role */}
        <Grid item xs={12}>
          <Error
            message="There was an error getting roles."
            query={rolesQuery}
          />
          <FormControl fullWidth variant="outlined" disabled={formDisabled}>
            <InputLabel id="Role-label">Role</InputLabel>
            <Select
              label="Role"
              labelId="Role-label"
              margin="dense"
              error={!!roleIdError}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={roleId ?? ""}
              onChange={(e) => {
                setPartnerId(null);
                setPartnerIdError("");

                setProviderId(null);
                setProviderIdError("");

                setlocationIds(null);
                setlocationIdsError("");

                setRoleId(e.target.value);
                setRoleIdError("");
              }}
              renderValue={() =>
                roles?.find((r) => r.roleId === roleId)?.name ?? "None"
              }
              defaultValue={null}
            >
              <MenuItem value={null} defaultValue key={-1}>
                <span style={{ color: theme.palette.text.secondary }}>
                  None
                </span>
              </MenuItem>
              {roles?.map((r, index) => (
                <MenuItem value={r.roleId} key={index} disabled={!r.available}>
                  <ListItemText>{r.name}</ListItemText>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!roleIdError}>{roleIdError}</FormHelperText>
          </FormControl>
          <div
            style={{
              color: theme.palette.text.secondary,
              margin: theme.spacing(1),
            }}
          >
            {roles
              ?.find((r) => r.roleId === roleId)
              ?.permissions?.map((p, i) => (
                <Typography key={i} fontSize="0.75rem">
                  {p.name}
                </Typography>
              ))}
          </div>
        </Grid>

        {/* Partner */}
        {partnerRequired && (
          <Grid item xs={12}>
            <Error
              message="There was an issue loading partners."
              query={partnersQuery}
            />
            <FormControl fullWidth>
              <Autocomplete
                required
                disabled={partnersQuery.isLoading || formDisabled}
                options={partners ?? []}
                value={partners?.find((p) => p.partnerId === partnerId) ?? {}}
                onChange={(event, newValue) => {
                  setPartnerIdError("");
                  setPartnerId(newValue.partnerId);
                }}
                getOptionLabel={(option) => option?.name ?? ""}
                renderInput={(params) => (
                  <TextField
                    required
                    error={!!partnerIdError}
                    helperText={partnerIdError}
                    {...params}
                    label="Partner"
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}

        {/* Provider */}
        {providerRequired && (
          <Grid item xs={12}>
            <Error
              message="There was an issue loading providers."
              query={providersQuery}
            />
            <Autocomplete
              required
              disabled={providersQuery.isLoading || formDisabled}
              options={providers ?? []}
              value={providers?.find((p) => p.providerId === providerId) ?? {}}
              onChange={(event, newValue) => {
                setProviderIdError("");
                setProviderId(newValue.providerId);
              }}
              getOptionLabel={(option) => option?.name ?? ""}
              renderInput={(params) => (
                <TextField
                  required
                  error={!!providerIdError}
                  helperText={providerIdError}
                  {...params}
                  label="Provider"
                />
              )}
            />
          </Grid>
        )}

        {/* Location */}
        {locationRequired && (
          <Grid item xs={12}>
            <Error
              message="There was an issue loading locations."
              query={locationsQuery}
            />
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                disabled={
                  locationsQuery?.isLoading ||
                  locationsQuery?.isError ||
                  formDisabled
                }
                value={
                  locations?.filter((l) =>
                    locationIds?.includes(l.locationId)
                  ) ?? []
                }
                onChange={(event, value) => {
                  setlocationIdsError("");
                  setlocationIds(value.map((v) => v.locationId ?? v));
                }}
                options={locations ?? []}
                getOptionLabel={(option) => option.locationName}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.locationId}>
                      {option.locationName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Locations"
                    error={!!locationIdsError}
                    helperText={locationIdsError}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      color="primary"
                      variant="contained"
                      label={
                        locations?.find(
                          (l) => l.locationId === option.locationId
                        )?.locationName
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Grid>
        )}

        {/* Active */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                }}
                disabled={formDisabled}
              />
            }
            label="Active"
          />
        </Grid>

        <Grid item>
          <Button
            disabled={formDisabled}
            variant="contained"
            startIcon={<FontAwesomeIcon color="primary" icon={faSave} />}
            onClick={ClickSave}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              navigate("/users");
            }}
            disabled={postPerson?.isLoading}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Error
            message="There was a problem saving the user."
            query={postPerson}
          />
        </Grid>
      </Grid>
      <Dialog
        open={isDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Fade}
      >
        <DialogTitle>Copy Redeem URL</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsDialogOpen(false);
            setResend(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: "40px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "20px" }}>
            <TextField
              id="redeemUrl"
              label="Redeem URL"
              defaultValue={redeemUrl && redeemUrl}
              InputProps={{
                readOnly: true,
              }}
              autoFocus={true}
              onFocus={(event) => {
                event.target.select();
              }}
              fullWidth={true}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faClipboard} />}
            onClick={() => {
              navigator.clipboard.writeText(redeemUrl);
              snackbar.enqueueSnackbar(`Redeem URL copied`, {
                variant: "success",
                autoHideDuration: 2500,
              });
              setIsDialogOpen(false);
              setResend(false);
            }}
          >
            Copy to clipboard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
