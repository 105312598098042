import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../services/contexts/userContext";
import { themes } from "../../themes";
import { useSelectedTheme } from "../../services/contexts/themeContext";
import { GetAccessToken } from "../../services/apiService";
import { useSnackbar } from "notistack";
import { ScrollToTopOnMount } from "../../services/utilities";
import ThemePickerExample from "../images/ThemePickerExample";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function Personalization() {
  const theme = useTheme();
  const { user } = useUser();
  const { selectedTheme, setTheme } = useSelectedTheme();
  const snackbar = useSnackbar();
  const commonStyles = useCommonStyles();

  const [accessToken, setAccessToken] = useState();

  const token = GetAccessToken();
  useEffect(() => {
    if (token) {
      token.then((res) => {
        setAccessToken("Bearer " + res.accessToken);
      });
    }
  }, [token]);

  // Styles
  const accordionStyles = {
    "&.MuiPaper-root": {
      borderRadius: "16px",
    },
  };
  const settingStyles = {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      "& h3": {
        textAlign: "right",
      },
      "& .MuiFormControl-root": {
        width: 200,
      },
    },
  };
  const profileStyles = {
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  };
  const avatarStyles = {
    bgcolor: theme.palette.neutral?.lightest,
    color: theme.palette.nav?.appBar,
    width: 200,
    height: 200,
    "& svg": {
      width: 190,
      height: 190,
    },
    marginBottom: "-2rem",
    zIndex: 999,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)", // like Paper elevation(4)
  };
  const paperStyles = {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    marginBottom: theme.spacing(6),
  };
  const emailStyles = {
    maxWidth: "100%",
    fontSize: "2rem",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2" gutterBottom>
        Personalization
      </Typography>

      {/* Profile */}
      <Grid container sx={profileStyles}>
        <Grid item>
          <Avatar alt="My avatar" sx={avatarStyles}>
            <FontAwesomeIcon icon={faUserCircle} />
          </Avatar>
        </Grid>
        {!!user && (
          <>
            <Paper sx={paperStyles} elevation={2}>
              <Typography component="h3" variant="h2">
                {user.name}
              </Typography>
              <Typography component="p" variant="body1" sx={emailStyles}>
                {user.email}
              </Typography>
            </Paper>
            {user.isEmployee && (
              <Grid item>
                <Typography component="p" variant="body1">
                  Edit your profile and see more details...
                </Typography>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  href="https://delve.office.com"
                  target="_blank"
                >
                  Office 365
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Container maxWidth={"xs"}>
        <Accordion sx={accordionStyles}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            <Typography fontSize="1.5rem">{user?.role}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {user?.permissions?.map((permission, i) => (
              <Typography key={i}>{permission}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </Container>

      {/* Theme */}
      <Container maxWidth="md">
        <Typography variant="h4" component="h3">
          Choose a Theme:
        </Typography>
        <Paper sx={commonStyles?.paperPadding}>
          <Grid container spacing={1} justifyContent="space-around">
            {themes.map((tn, i) => {
              return (
                <Grid item key={i}>
                  <Box>
                    <Button
                      variant={
                        selectedTheme?.name == tn.name ? "contained" : ""
                      }
                      onClick={() => {
                        setTheme(tn?.name);
                      }}
                      sx={{
                        borderRadius: 6,
                        color: theme?.palette?.text?.primary,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: tn.typography?.h1?.fontFamily }}
                        color={
                          selectedTheme?.name == tn.name
                            ? theme?.palette?.primary?.contrastText
                            : theme?.palette?.text?.primary
                        }
                      >
                        {tn.name}
                      </Typography>
                      <ThemePickerExample theme={tn} />
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Container>

      {(process.env.REACT_APP_ENVIRONMENT === "local" ||
        process.env.REACT_APP_ENVIRONMENT === "development") && (
        <Container maxWidth={"md"}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Token</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(accessToken);
                  snackbar.enqueueSnackbar(`Copied`, {
                    variant: "success",
                    autoHideDuration: 1000,
                  });
                }}
              >
                Copy
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ wordWrap: "break-word" }}>
                <Typography variant="body2">{accessToken}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
