import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlaskRoundPotion } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";
import TableSample from "./TableSample";

export default function TableTipsOutro(props) {
  const { sx } = props;
  const theme = useTheme();
  const mode = theme?.palette?.mode;
  const flaskColor = theme?.palette?.primary?.[mode];

  const slideStyles = {
    ...sx,
    background: theme?.palette?.text?.primary,
    color: theme?.palette?.background?.default,
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography component="h1" variant="h1">
            <FontAwesomeIcon icon={faFlaskRoundPotion} color={flaskColor} />
            &nbsp;Experiment!
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            <strong>Try out what you've just learned</strong> right here or on
            the table&nbsp;underneath.
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TableSample density="compact" />
          </Box>
          <Typography variant="body2" sx={{ my: 2, textAlign: "center" }}>
            <em>
              Can you <strong>group</strong> and <strong>sort</strong> this
              table by grade, <strong>hide</strong> the ID column and{" "}
              <strong>filter</strong> for students whose favorite color
              is&nbsp;yellow?
            </em>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
