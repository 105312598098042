import EChartsReact from "echarts-for-react";
import { useTheme, useMediaQuery, Typography, Paper } from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useGetPartnerHomeCurrentProgramCounts } from "../../services/hooks/partnerHomeHook";

export default function HomeChartCurrentProgramsEnrolledWithdrawn() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const xsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const query = useGetPartnerHomeCurrentProgramCounts();
  const data = query?.data?.data;

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      orient: "horizontal",
      right: 5,
      textStyle: {
        color: theme.palette.text.secondary,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "13%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      name: "Total",
      nameLocation: "middle",
      nameGap: xsScreen ? 120 : 40,
      nameTextStyle: {
        fontWeight: "bold",
      },
    },
    yAxis: {
      type: "category",
      data: ["Students", "Participants"],
    },
    series: [
      {
        name: "Enrolled",
        type: "bar",
        stack: "total",
        color: theme.palette.chart[0],
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [data?.distinctStudentCount, data?.enrolledTotal], //distinct students, enrolled participants
      },
      {
        name: "Withdrawn",
        type: "bar",
        stack: "total",
        color: theme.palette.chart[4],
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [, data?.withdrawnTotal], // , withdrawn participants
      },
    ],
  };

  return (
    <Paper
      sx={{ ...commonStyles.paperPadding, minHeight: xsScreen ? 365 : 300 }}
    >
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        <span>Current Program Participation&nbsp;Totals</span>
      </Typography>
      <EChartsReact option={chartOptions} style={xsScreen && { height: 365 }} />
    </Paper>
  );
}
