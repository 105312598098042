import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetUsers() {
  const token = GetAccessToken();
  const url = "person/GetUsers";
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });
  return query;
}

export function useGetPerson(personId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `person/GetPerson/${personId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: personId != null,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting person details. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );

  return query;
}

export function useGetPersonFromGraph(personId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `person/GetPersonFromGraph/${personId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: personId != null,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting person details. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );

  return query;
}

export function useGetUserLocations() {
  const token = GetAccessToken();
  const url = `person/GetUserLocations`;
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });
  return query;
}

export function useGetEligibleInstructors(providerId, programId) {
  const token = GetAccessToken();
  const url = `person/GetEligibleInstructors/${providerId}${
    programId !== undefined ? `?programId=${programId}` : ""
  }`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    {
      enabled: providerId != null,
    }
  );
  return query;
}

export function useUpsertPerson(person) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "person/UpsertPerson";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, person);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) => key.startsWith("person"));
        snackbar.enqueueSnackbar(`User saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving the user. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function useInvitePerson(person) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "invite/SendInvite";
  const queryClient = useQueryClient();
  return useMutation(
    ({ signal }) => {
      return AppsPost(token, url, person, signal);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(data);
        queryClient.invalidateQueries((key) => key.startsWith("person"));
        snackbar.enqueueSnackbar(`User invited`, {
          variant: "success",
        });
        return data;
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error inviting the user. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
}

export function useUpsertUserSettings() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "person/UpsertUserSettings";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      const response = await AppsPost(token, url);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) => key.startsWith("PartnerSignIn/GetPartnerUser"));
        snackbar.enqueueSnackbar(`User settings saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving the user settings. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
