import { Box, Typography } from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function InvalidUser() {
  const { user } = useUser();
  //Styles
  const centerContentStyles = {
    alignItems: "center",
    display: "flex",
    minHeight: "63vh",
    justifyContent: "center",
  };

  return (
    <>
      <ScrollToTopOnMount />

      <Box sx={centerContentStyles}>
        <div style={{ textAlign: "center" }}>
          <Typography fontSize="1.3rem">
            This account has not been configured with use in the Partner Portal.
          </Typography>
          <Typography>You are logged in with {user.email}</Typography>
        </div>
      </Box>
    </>
  );
}
