import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation, QueryCache } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

export function useGetProviders() {
  const token = GetAccessToken();
  const url = `providers/GetProviders`;
  const query = useQuery(url, async ({ signal }) => {
    return await AppsGet(token, url, signal);
  });
  return query;
}

export function useGetProvider(providerId) {
  const token = GetAccessToken();
  const url = `providers/GetProvider/${providerId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: providerId != null,
    }
  );
  return query;
}

export function useGetProviderLocationOffereds(providerId) {
  const token = GetAccessToken();
  const url = `providers/GetProviderLocationOffereds/${providerId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: providerId != null,
    }
  );
  return query;
}

export function useGetProviderActivityOffereds(providerId) {
  const token = GetAccessToken();
  const url = `providers/GetProviderActivityOffereds/${providerId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: providerId != null,
    }
  );
  return query;
}

export function useUpsertProvider(provider) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "providers/UpsertProvider";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, provider);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("providers")
        );
        snackbar.enqueueSnackbar(`Provider saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving the provider. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
