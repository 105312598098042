import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";

export default function About(props) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>What is the Partner Portal?</DialogTitle>
      <DialogContent>
        <Typography>
          Tacoma Public Schools is fortunate to have great community partners
          helping provide expanded learning opportunities for students.{" "}
          <strong>
            The Partner Portal is a web app that allows these partners to manage
            their "Beyond the Bell" programs, propose new ones, track student
            attendance, and more.
          </strong>
        </Typography>
        <br />
        <Typography>
          Access is invite-only. Partners needing access should receive an email
          from Microsoft with instructions on creating an account. The small
          number of TPS employees who need access will be able to login with
          their district accounts.
        </Typography>
        <br />
        <Typography>
          For help accessing the Partner Portal, please email{" "}
          <Link href="mailto:help@tacoma.k12.wa.us">help@tacoma.k12.wa.us</Link>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
