import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { maxWidth } from "../utilities";

export default function useCommonStyles() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const drawerWidth = 300;

  const contentBox = {
    mx: "auto",
    maxWidth: maxWidth,
    px: lgScreen ? theme.spacing(2) : theme.spacing(1),
    py: smallScreen ? theme.spacing(10) : theme.spacing(3),
    width: "100%",
  };
  const deemphasizedText = {
    fontSize: "0.75rem",
  };

  const paperPadding = {
    p: theme.spacing(2),
  };
  const tableStyles = {
    "& .MuiTableCell-root": { p: smallScreen ? "0.5rem" : "1rem" },
  };
  const videoStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  const videoWrapperStyles = {
    position: "relative",
    pb: "56.25%" /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */,
    height: 0,
    overflow: "hidden",
  };

  return {
    drawerWidth,
    contentBox,
    deemphasizedText,
    paperPadding,
    tableStyles,
    videoStyles,
    videoWrapperStyles,
  };
}
