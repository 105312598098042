import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Error from "../common/Error";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { ScrollToTopOnMount } from "../../services/utilities";
import {
  useGetProvider,
  useUpsertProvider,
} from "../../services/hooks/providersHook";
import { useGetPartners } from "../../services/hooks/partnersHook";

export default function PartnerCru() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const isEdit = providerId != null;

  //Fields
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [partnerId, setPartnerId] = useState();
  const [partnerIdError, setPartnerIdError] = useState();

  const [webSiteUrl, setWebsiteUrl] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [active, setActive] = useState(true);

  //Data
  const providerQuery = useGetProvider(providerId);
  const providerData = providerQuery?.data?.data;

  const partnersQuery = useGetPartners();
  const partners = partnersQuery?.data?.data?.filter(
    (p) => p.active || p.partnerId == partnerId
  );

  useEffect(() => {
    if (providerData) {
      setName(providerData.name);
      setWebsiteUrl(providerData.webSiteUrl);
      setIconUrl(providerData.iconUrl);
      setLogoUrl(providerData.logoUrl);
      setPaymentUrl(providerData.paymentUrl);
      setActive(providerData.active);
      setPartnerId(providerData.partnerId);
    }
  }, [providerData]);

  const postBody = {
    providerId: providerId ?? 0,
    name: name,
    webSiteUrl: webSiteUrl,
    iconUrl: iconUrl,
    logoUrl: logoUrl,
    paymentUrl: paymentUrl,
    active: active,
    partnerId: partnerId,
    createdBy: providerData?.createdBy,
    createdDate: providerData?.createdDate,
    modifiedBy: providerData?.modifiedBy,
    modifiedDate: providerData?.modifiedDate,
  };

  const postProvider = useUpsertProvider(postBody);

  const formDisabled = providerQuery.isLoading || postProvider.isLoading;

  const clickSave = () => {
    if (ValidateForm()) {
      postProvider.mutateAsync().then(() => {
        navigate("/providers");
      });
    }
  };

  const ValidateForm = () => {
    let valid = true;

    if (!name) {
      setNameError("Provider name is required.");
      valid = false;
    }
    if (!partnerId) {
      setPartnerIdError("Assigned Partner is required.");
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">
        {isEdit ? "Edit" : "Create"} Provider
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              required
              fullWidth
              value={name}
              label="Name"
              variant="outlined"
              error={!!nameError}
              helperText={nameError}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              inputProps={{
                maxLength: 50,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={webSiteUrl}
              label="Website URL"
              variant="outlined"
              helperText={""}
              onChange={(e) => {
                setWebsiteUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={iconUrl}
              label="Icon URL"
              variant="outlined"
              helperText={
                "Enter the address to the provider's smaller icon online. (Example: https://tacomaschools.org/images/tps-logo-icon.png)"
              }
              onChange={(e) => {
                setIconUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
          {iconUrl && (
            <img style={{ maxWidth: 300 }} src={iconUrl} alt="Provider icon" />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={logoUrl}
              label="Logo URL"
              variant="outlined"
              helperText={
                "Enter the address to the provider's full logo online. (Example: https://tacomaschools.org/images/tps-logo.png)"
              }
              onChange={(e) => {
                setLogoUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
          {logoUrl && (
            <img style={{ maxWidth: 300 }} src={logoUrl} alt="Partner logo" />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={paymentUrl}
              label="Payment Site"
              variant="outlined"
              helperText={
                "If the provider accepts online payments, enter the URL for the payment website."
              }
              onChange={(e) => {
                setPaymentUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </Grid>

        {/* Partner */}
        <Grid item xs={12}>
          <Error
            message="There was an issue loading partners."
            query={partnersQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              disabled={partnersQuery.isLoading || formDisabled}
              options={partners ?? []}
              value={partners?.find((p) => p.partnerId == partnerId) ?? {}}
              onChange={(event, newValue) => {
                setPartnerId(newValue.partnerId);
              }}
              getOptionLabel={(option) => option?.name ?? ""}
              renderInput={(params) => (
                <TextField
                  required
                  error={!!partnerIdError}
                  helperText={partnerIdError}
                  {...params}
                  label="Partner"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                }}
                disabled={formDisabled}
              />
            }
            label="Active"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={clickSave}
            disabled={formDisabled}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              navigate("/providers");
            }}
            disabled={formDisabled}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Error
            message="There was an error saving the provider."
            query={postProvider}
          />
        </Grid>
      </Grid>
    </>
  );
}
