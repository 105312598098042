//#region Imports
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faMemoCircleCheck as faMemoCircleCheckSolid,
  faTrash,
  faSquare as faSquareSolid,
  faSquareCheck,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faMemoCircleCheck as faMemoCircleCheckRegular,
  faSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { ScrollToTopOnMount } from "../../services/utilities";
import {
  useGetPartner,
  useUpsertPartner,
} from "../../services/hooks/partnersHook";
import {
  useGetPartners,
  useGetPartnerTypes,
} from "../../services/hooks/partnersHook";
import { useGetCriteriaChoices } from "../../services/hooks/dsaHook";
import Error from "../common/Error";
//#endregion

export default function PartnerCru() {
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const isEdit = partnerId != null;

  const [type, setType] = useState(null);
  const [typeError, setTypeError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [webSiteUrl, setWebsiteUrl] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [active, setActive] = useState(true);
  const [sharedPartners, setSharedPartners] = useState([]);
  const [partners, setPartners] = useState([]);

  const [dsaStartDate, setDsaStartDate] = useState(null);
  const [dsaStartDateError, setDsaStartDateError] = useState("");
  const [dsaEndDate, setDsaEndDate] = useState(null);
  const [dsaEndDateError, setDsaEndDateError] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [dateRangeError, setDateRangeError] = useState("");

  const [registrationDateRange, setRegistrationDateRange] = useState([
    null,
    null,
  ]);
  const [registrationDateRangeError, setRegistrationDateRangeError] =
    useState("");

  const [showInCompass, setShowInCompass] = useState(false);

  const [dsaTitle, setDsaTitle] = useState("");
  const [dsaDescription, setDsaDescription] = useState("");
  const [dsaTitleError, setDsaTitleError] = useState("");
  const [dsaDescriptionError, setDsaDescriptionError] = useState("");

  const [dsaRegistrationStartDate, setDsaRegistrationStartDate] =
    useState(null);
  const [dsaRegistrationStartDateError, setDsaRegistrationStartDateError] =
    useState("");
  const [dsaRegistrationEndDate, setDsaRegistrationEndDate] = useState(null);
  const [dsaRegistrationEndDateError, setDsaRegistrationEndDateError] =
    useState("");

  const [selectedCriteria, setSelectedCriteria] = useState([
    { criteriaSchools: [], criteriaGrades: [], criteriaPrograms: [] },
  ]);
  const [selectedCriteriaError, setSelectedCriteriaError] = useState([
    {
      criteriaSchoolsError: false,
      criteriaGradesError: false,
      criteriaProgramsError: false,
    },
  ]);

  const partnerQuery = useGetPartner(partnerId);
  const partnerData = partnerQuery?.data?.data;

  const partnersQuery = useGetPartners();
  const partnersData = partnersQuery?.data?.data;
  // ?.filter(
  //   (p) => p.active && p.partnerId.toString() !== partnerId
  // )

  const partnerTypesQuery = useGetPartnerTypes();
  const partnerTypesData = partnerTypesQuery?.data?.data;

  const criteriaChoicesQuery = useGetCriteriaChoices();
  const criteriaChoicesData = criteriaChoicesQuery?.data?.data;

  useEffect(() => {
    if (partnerData) {
      const type = partnerTypesData?.find(
        (t) => t.typeId === partnerData.type.typeId
      );
      setType(type);
      setName(partnerData.name);
      setWebsiteUrl(partnerData.webSiteUrl);
      setIconUrl(partnerData.iconUrl);
      setLogoUrl(partnerData.logoUrl);
      setPaymentUrl(partnerData.paymentUrl);
      setActive(partnerData.active);
      setSharedPartners(
        partnerData?.sharedPartners?.map((sp) => sp.sharedPartnerId)
      );
      if (partnerData?.dsaStartDate && partnerData?.dsaEndDate) {
        setDateRange([
          dayjs(partnerData?.dsaStartDate),
          dayjs(partnerData?.dsaEndDate),
        ]);
      }
      setShowInCompass(partnerData?.showInCompass);
      setDsaTitle(partnerData?.dsaTitle ?? "");
      setDsaDescription(partnerData?.dsaDescription ?? "");
      if (
        partnerData?.dsaRegistrationStartDate &&
        partnerData?.dsaRegistrationEndDate
      ) {
        setRegistrationDateRange([
          dayjs(partnerData?.dsaRegistrationStartDate),
          dayjs(partnerData?.dsaRegistrationEndDate),
        ]);
      }
      setSelectedCriteria(
        partnerData?.partnerCriteria.map((criterion) => {
          return {
            criteriaSchools:
              criterion.criteriaSchools?.length ===
                criteriaChoicesData?.locations?.length ||
              criterion.criteriaSchools?.length === 0
                ? criteriaChoicesData?.locations
                : criteriaChoicesData?.locations.filter((location) =>
                    criterion.criteriaSchools.some(
                      (s) => s.locationId === location.locationId
                    )
                  ),
            criteriaGrades:
              criterion.criteriaGrades?.length ===
                criteriaChoicesData?.grades?.length ||
              criterion.criteriaGrades?.length === 0
                ? criteriaChoicesData?.grades
                : criteriaChoicesData?.grades.filter((grade) =>
                    criterion.criteriaGrades.some(
                      (g) => g.gradeId === grade.gradeId
                    )
                  ),
            criteriaPrograms:
              criterion.criteriaPrograms?.length ===
                criteriaChoicesData?.programs?.length ||
              criterion.criteriaPrograms?.length === 0
                ? criteriaChoicesData?.programs
                : criteriaChoicesData?.programs.filter((program) =>
                    criterion.criteriaPrograms.some(
                      (p) => p.programId === program.programId
                    )
                  ),
          };
        })
      );
      setSelectedCriteriaError(
        partnerData?.partnerCriteria.map((criterion) => {
          return {
            criteriaSchoolsError: false,
            criteriaGradesError: false,
            criteriaProgramsError: false,
          };
        })
      );
    }
  }, [partnerData, partnerTypesData]);

  useEffect(() => {
    if (partnersData) {
      setPartners(partnersData);
    }
  }, [partnersData]);

  useEffect(() => {
    if (dateRange) {
      if (dateRange[0]) {
        setDsaStartDate(dayjs(dateRange[0]).format("YYYY-MM-DD"));
      } else {
        setDsaStartDate(null);
      }

      if (dateRange[1]) {
        setDsaEndDate(dayjs(dateRange[1]).format("YYYY-MM-DD"));
      } else {
        setDsaEndDate(null);
      }
    }
  }, [dateRange]);

  useEffect(() => {
    if (registrationDateRange) {
      if (registrationDateRange[0]) {
        setDsaRegistrationStartDate(
          dayjs(registrationDateRange[0]).format("YYYY-MM-DD")
        );
      } else {
        setDsaRegistrationStartDate(null);
      }

      if (registrationDateRange[1]) {
        setDsaRegistrationEndDate(
          dayjs(registrationDateRange[1]).format("YYYY-MM-DD")
        );
      } else {
        setDsaRegistrationEndDate(null);
      }
    }
  }, [registrationDateRange]);

  //#region Post Body
  const postBody = () => {
    if (partnerId) {
      return {
        partnerId: partnerId,
        typeId: type?.typeId,
        name: name,
        webSiteUrl: webSiteUrl,
        iconUrl: iconUrl,
        logoUrl: logoUrl,
        paymentUrl: paymentUrl,
        active: active,
        sharedPartnerPartners: sharedPartners?.map((sp) => {
          return { partnerId: partnerId, sharedPartnerId: sp };
        }),
        dsaStartDate: dsaStartDate,
        dsaEndDate: dsaEndDate,
        showInCompass: showInCompass,
        dsaTitle: dsaTitle,
        dsaDescription: dsaDescription,
        dsaRegistrationStartDate: dsaRegistrationStartDate,
        dsaRegistrationEndDate: dsaRegistrationEndDate,
        partnerCriteria: selectedCriteria?.map((criterion) => {
          let tempPartnerCriteriaId = 0;
          if (criterion.criteriaSchools?.length > 0)
            tempPartnerCriteriaId =
              criterion.criteriaSchools[0].partnerCriteriaId;
          else if (criterion.criteriaGrades?.length > 0)
            tempPartnerCriteriaId =
              criterion.criteriaGrades[0].partnerCriteriaId;
          else if (criterion.criteriaPrograms?.length > 0)
            tempPartnerCriteriaId =
              criterion.criteriaPrograms[0].partnerCriteriaId;
          return {
            partnerCriteriaId: tempPartnerCriteriaId,
            partnerId: parseInt(partnerId),
            criteriaSchools:
              criterion.criteriaSchools?.length ===
              criteriaChoicesData?.locations?.length
                ? []
                : criterion.criteriaSchools?.map((s) => {
                    return {
                      partnerCriteriaSchoolId: s.partnerCriteriaSchoolId,
                      partnerCriteriaId: s.partnerCriteriaId,
                      locationId: s.locationId,
                    };
                  }),
            criteriaGrades:
              criterion.criteriaGrades?.length ===
              criteriaChoicesData?.grades?.length
                ? []
                : criterion.criteriaGrades?.map((g) => {
                    return {
                      partnerCriteriaGradeId: g.partnerCriteriaGradeId,
                      partnerCriteriaId: g.partnerCriteriaId,
                      gradeId: g.gradeId,
                    };
                  }),
            criteriaPrograms:
              criterion.criteriaPrograms?.length ===
              criteriaChoicesData?.programs?.length
                ? []
                : criterion.criteriaPrograms?.map((p) => {
                    return {
                      partnerCriteriaProgramId: p.partnerCriteriaProgramId,
                      partnerCriteriaId: p.partnerCriteriaId,
                      programId: p.programId,
                    };
                  }),
            hasAllSchools:
              criterion.criteriaSchools?.length ===
              criteriaChoicesData?.locations?.length,
            hasAllGrades:
              criterion.criteriaGrades?.length ===
              criteriaChoicesData?.grades?.length,
            hasAllPrograms:
              criterion.criteriaPrograms?.length ===
              criteriaChoicesData?.programs?.length,
          };
        }),
        createdBy: partnerData?.createdBy,
        createdDate: partnerData?.createdDate,
        modifiedBy: partnerData?.modifiedBy,
        modifiedDate: partnerData?.modifiedDate,
      };
    }
    //new
    return {
      typeId: type?.typeId,
      name: name,
      webSiteUrl: webSiteUrl,
      iconUrl: iconUrl,
      logoUrl: logoUrl,
      paymentUrl: paymentUrl,
      active: active,
      sharedPartnerPartners: sharedPartners?.map((sp) => {
        return { PartnerId: partnerId, SharedPartnerId: sp };
      }),
      dsaStartDate: dsaStartDate,
      dsaEndDate: dsaEndDate,
      showInCompass: showInCompass,
      dsaTitle: dsaTitle,
      dsaDescription: dsaDescription,
      dsaRegistrationStartDate: dsaRegistrationStartDate,
      dsaRegistrationEndDate: dsaRegistrationEndDate,
      partnerCriteria: selectedCriteria?.map((criterion) => {
        return {
          partnerCriteriaId: 0,
          partnerId: 0,
          criteriaSchools:
            criterion.criteriaSchools?.length ===
            criteriaChoicesData?.locations?.length
              ? []
              : criterion.criteriaSchools?.map((s) => {
                  return {
                    partnerCriteriaSchoolId: 0,
                    partnerCriteriaId: 0,
                    locationId: s.locationId,
                  };
                }),
          criteriaGrades:
            criterion.criteriaGrades?.length ===
            criteriaChoicesData?.grades?.length
              ? []
              : criterion.criteriaGrades?.map((g) => {
                  return {
                    partnerCriteriaGradeId: 0,
                    partnerCriteriaId: 0,
                    gradeId: g.gradeId,
                  };
                }),
          criteriaPrograms:
            criterion.criteriaPrograms?.length ===
            criteriaChoicesData?.programs?.length
              ? []
              : criterion.criteriaPrograms?.map((p) => {
                  return {
                    partnerCriteriaProgramId: 0,
                    partnerCriteriaId: 0,
                    programId: p.programId,
                  };
                }),
          hasAllSchools:
            criterion.criteriaSchools?.length ===
            criteriaChoicesData?.locations?.length,
          hasAllGrades:
            criterion.criteriaGrades?.length ===
            criteriaChoicesData?.grades?.length,
          hasAllPrograms:
            criterion.criteriaPrograms?.length ===
            criteriaChoicesData?.programs?.length,
        };
      }),
    };
  };
  //#endregion

  const postPartner = useUpsertPartner(postBody());

  const formDisabled = partnerQuery.isLoading || postPartner.isLoading;

  const clickSave = () => {
    if (ValidateForm()) {
      postPartner.mutateAsync().then(() => {
        navigate("/partners");
      });
    }
  };

  //#region Validation
  const ValidateForm = () => {
    let valid = true;

    if (!type) {
      setTypeError("Partner type is required.");
      valid = false;
    }
    if (!name) {
      setNameError("Partner name is required.");
      valid = false;
    }
    if (type && type?.name.toUpperCase() === "DSA" && !dsaStartDate) {
      setDsaStartDateError("DSA Start is required.");
      valid = false;
    }
    if (type && type?.name.toUpperCase() === "DSA" && !dsaEndDate) {
      setDsaEndDateError("DSA End is required.");
      valid = false;
    }
    if (
      type &&
      type?.name.toUpperCase() === "DSA" &&
      dsaEndDate &&
      dsaEndDate <= dsaStartDate
    ) {
      setDsaEndDateError("DSA End must be greater than DSA Start.");
      valid = false;
    }

    if (
      type?.name.toUpperCase() === "DSA" &&
      (dsaStartDate === null ||
        dsaEndDate === null ||
        dayjs(dsaStartDate, "YYYY-MM-DD", true).isValid() === false ||
        dayjs(dsaEndDate, "YYYY-MM-DD", true).isValid() === false)
    ) {
      setDateRangeError("Invalid dates");
      valid = false;
    }

    if (dateRangeError !== "") {
      valid = false;
    }

    if (
      type?.name.toUpperCase() === "DSA" &&
      showInCompass &&
      dsaTitle.trim().length === 0
    ) {
      setDsaTitleError("Title is required.");
      valid = false;
    }

    if (
      type?.name.toUpperCase() === "DSA" &&
      showInCompass &&
      dsaDescription.trim().length === 0
    ) {
      setDsaDescriptionError("Description is required.");
      valid = false;
    }

    if (
      showInCompass &&
      type?.name.toUpperCase() === "DSA" &&
      (dsaRegistrationStartDate === null ||
        dsaRegistrationEndDate === null ||
        dayjs(dsaRegistrationStartDate, "YYYY-MM-DD", true).isValid() ===
          false ||
        dayjs(dsaRegistrationEndDate, "YYYY-MM-DD", true).isValid() === false)
    ) {
      setRegistrationDateRangeError("Invalid dates");
      valid = false;
    }

    if (registrationDateRangeError !== "") {
      valid = false;
    }

    type?.name.toUpperCase() === "DSA" &&
      selectedCriteria.forEach((criterion, i) => {
        if (criterion?.criteriaSchools?.length === 0) {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaSchoolsError = true;
          setSelectedCriteriaError(copySelectedCriteriaError);
          valid = false; //showInCompass ? false : true; // only invalidate if useCriteria is true
        } else {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaSchoolsError = false;
          setSelectedCriteriaError(copySelectedCriteriaError);
        }

        if (criterion?.criteriaGrades?.length === 0) {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaGradesError = true;
          setSelectedCriteriaError(copySelectedCriteriaError);
          valid = false; //showInCompass ? false : true; // only invalidate if useCriteria is true
        } else {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaGradesError = false;
          setSelectedCriteriaError(copySelectedCriteriaError);
        }

        if (criterion?.criteriaPrograms?.length === 0) {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaProgramsError = true;
          setSelectedCriteriaError(copySelectedCriteriaError);
          valid = false; //showInCompass ? false : true; // only invalidate if useCriteria is true
        } else {
          let copySelectedCriteriaError = [...selectedCriteriaError];
          copySelectedCriteriaError[i].criteriaProgramsError = false;
          setSelectedCriteriaError(copySelectedCriteriaError);
        }
      });

    return valid;
  };
  //#endregion

  //#region Render
  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">{isEdit ? "Edit" : "Create"} Partner</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Error
            message="There was an error getting roles."
            query={partnerTypesQuery}
          />
          <FormControl fullWidth>
            <InputLabel id="partner-type-label">Type</InputLabel>
            <Select
              autoFocus
              required
              labelId="partner-type-label"
              id="partner-type"
              value={type ?? ""}
              label="Type"
              onChange={(e) => {
                setType(e.target.value);
                setTypeError("");
              }}
              error={!!typeError}
              helperText={typeError}
            >
              {partnerTypesData?.map((t) => {
                if (t.active) {
                  return (
                    <MenuItem key={t.typeId} value={t}>
                      {t.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            <FormHelperText error={!!typeError}>{typeError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={name}
              id="partner-name"
              label="Name"
              variant="outlined"
              error={!!nameError}
              helperText={nameError}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              inputProps={{
                maxLength: 50,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={webSiteUrl}
              label="Website URL"
              variant="outlined"
              helperText={""}
              onChange={(e) => {
                setWebsiteUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={iconUrl}
              label="Icon URL"
              variant="outlined"
              helperText={
                "Enter the address to the partner's smaller icon online. (Example: https://tacomaschools.org/images/tps-logo-icon.png)"
              }
              onChange={(e) => {
                setIconUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
          {iconUrl && (
            <img style={{ maxWidth: 300 }} src={iconUrl} alt="Partner icon" />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={formDisabled}
              fullWidth
              value={logoUrl}
              label="Logo URL"
              variant="outlined"
              helperText={
                "Enter the address to the partner's full logo online. (Example: https://tacomaschools.org/images/tps-logo.png)"
              }
              onChange={(e) => {
                setLogoUrl(e.target.value);
              }}
              inputProps={{
                maxLength: 512,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
          {logoUrl && (
            <img style={{ maxWidth: 300 }} src={logoUrl} alt="Partner logo" />
          )}
        </Grid>
        {!!type && type?.name.toUpperCase() !== "DSA" && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                disabled={formDisabled}
                fullWidth
                value={paymentUrl}
                label="Payment Site"
                variant="outlined"
                helperText={
                  "If the partner accepts online payments, enter the URL for the payment website."
                }
                onChange={(e) => {
                  setPaymentUrl(e.target.value);
                }}
                inputProps={{
                  maxLength: 512,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}
        {!!type && type?.name.toUpperCase() !== "DSA" && (
          <Grid item xs={12}>
            <Error
              message="There was an issue loading partners."
              query={partnersQuery}
            />
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                disableCloseOnSelect
                disabled={
                  partnersQuery?.isLoading ||
                  partnersQuery?.isError ||
                  formDisabled
                }
                value={
                  partners?.filter((p) =>
                    sharedPartners?.includes(p.partnerId)
                  ) ?? []
                  // partners ?? []
                }
                options={
                  partners?.filter(
                    (p) => p.active && p.partnerId.toString() !== partnerId
                  ) ?? []
                }
                getOptionLabel={(option) => option.name ?? ""}
                multiple
                onChange={(event, value) => {
                  setSharedPartners(value?.map((p) => p.partnerId ?? p));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Shared Partners" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      color="primary"
                      variant="contained"
                      label={
                        partners?.find((p) => p.partnerId === option.partnerId)
                          ?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Grid>
        )}
        {!!type && type?.name.toUpperCase() === "DSA" && (
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                disabled={formDisabled}
                localeText={{ start: "DSA Start", end: "DSA End" }}
                value={dateRange}
                onChange={(selectedDates) => {
                  setDateRange(selectedDates);
                  setDateRangeError("");
                }}
                //onError={(error) => setDateRangeError(error)}
                onError={(error, value) => {
                  error?.every((e) => e === null) &&
                  value?.every((e) => e !== null)
                    ? setDateRangeError("")
                    : setDateRangeError("Invalid dates");
                }}
                slotProps={{
                  textField: {
                    helperText: (
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ color: "red" }}
                        gutterBottom
                      >
                        {dateRangeError}
                      </Typography>
                    ),
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {!!type && type?.name.toUpperCase() === "DSA" && (
          <Grid item xs={12}>
            <FormGroup>
              <Grid container spacing={0} item sx={{ mb: 1 }}>
                <Button
                  variant={showInCompass ? "contained" : "outlined"}
                  startIcon={
                    <FontAwesomeIcon
                      icon={showInCompass ? faSquareCheck : faSquare}
                    />
                  }
                  endIcon={
                    <FontAwesomeIcon
                      icon={
                        showInCompass
                          ? faMemoCircleCheckSolid
                          : faMemoCircleCheckRegular
                      }
                    />
                  }
                  onClick={() => setShowInCompass(!showInCompass)}
                  sx={{ marginBottom: 1 }}
                >
                  Restrict shared data to students registered in compass
                </Button>
              </Grid>
            </FormGroup>
            {showInCompass ? (
              <>
                <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                  Parents will be able to register their students to share data
                  in the Compass app. For a better presentation, please give
                  this partnership a friendly title and description. Then,
                  choose the registration start and end dates.
                </Typography>
                <Box>
                  <FormControl sx={{ width: "30%" }}>
                    <TextField
                      disabled={formDisabled}
                      value={dsaTitle ?? ""}
                      fullWidth
                      label="Title"
                      variant="outlined"
                      multiline={false}
                      sx={{ marginBottom: 2 }}
                      error={!!dsaTitleError}
                      helperText={dsaTitleError}
                      onChange={(e) => {
                        setDsaTitle(e.target.value);
                        setDsaTitleError("");
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl sx={{ width: "50%" }}>
                    <TextField
                      disabled={formDisabled}
                      value={dsaDescription ?? ""}
                      fullWidth
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={5}
                      sx={{ marginBottom: 3 }}
                      error={!!dsaDescriptionError}
                      helperText={dsaDescriptionError}
                      onChange={(e) => {
                        setDsaDescription(e.target.value);
                        setDsaDescriptionError("");
                      }}
                    />
                  </FormControl>
                </Box>

                <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      disabled={formDisabled}
                      localeText={{
                        start: "Start Registration",
                        end: "End Registration",
                      }}
                      value={registrationDateRange}
                      onChange={(selectedRegistrationDates) => {
                        setRegistrationDateRange(selectedRegistrationDates);
                        setRegistrationDateRangeError("");
                      }}
                      //onError={(error) => setDateRangeError(error)}
                      onError={(error, value) => {
                        error?.every((e) => e === null) &&
                        value?.every((e) => e !== null)
                          ? setRegistrationDateRangeError("")
                          : setRegistrationDateRangeError("Invalid dates");
                      }}
                      slotProps={{
                        textField: {
                          helperText: (
                            <Typography
                              variant="caption"
                              display="block"
                              sx={{ color: "red" }}
                              gutterBottom
                            >
                              {registrationDateRangeError}
                            </Typography>
                          ),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Typography variant="body2" component="p">
                  <em>
                    Selected criteria will determine the students who will be
                    able to be registered to share data with this partner in the
                    Compass app. The partner will be able to see identifiable
                    student data (name, ID).
                  </em>
                </Typography>
              </>
            ) : (
              <Typography
                variant="body2"
                component="p"
                sx={{ marginBottom: 1 }}
              >
                <em>
                  Add criteria to determine which students will be returned for
                  this partner. This partner will not be able to see
                  identifiable student data (name, ID).
                </em>
              </Typography>
            )}
            <Stack spacing={1}>
              {selectedCriteria?.map((criterion, i) => {
                return (
                  <Paper
                    key={i}
                    elevation={1}
                    sx={{ padding: 2, borderRadius: "10px" }}
                  >
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={11} xs={12}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Grid item md={4} xs={12}>
                            <Autocomplete
                              /* reference: https://stackoverflow.com/questions/74452434/how-to-select-all-options-with-autocomplete-component-from-material-ui */
                              multiple
                              value={criterion.criteriaSchools ?? []}
                              filterOptions={(options, params) => {
                                // <<<--- inject the Select All option
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [
                                  {
                                    locationNameShort: "Select All Schools...",
                                    all: true,
                                  },
                                  ...filtered,
                                ];
                              }}
                              onChange={(event, newValue) => {
                                let copySelectedCriteriaError = [
                                  ...selectedCriteriaError,
                                ];
                                copySelectedCriteriaError[
                                  i
                                ].criteriaSchoolsError = false;
                                setSelectedCriteriaError(
                                  copySelectedCriteriaError
                                );

                                let newValueArray = [...selectedCriteria];

                                if (newValue.find((option) => option.all)) {
                                  if (
                                    criterion?.criteriaSchools?.length ===
                                    criteriaChoicesData?.locations?.length
                                  ) {
                                    newValueArray[i].criteriaSchools = [];
                                  } else {
                                    newValueArray[i].criteriaSchools =
                                      criteriaChoicesData?.locations;
                                  }
                                  return setSelectedCriteria(newValueArray);
                                }

                                newValueArray[i].criteriaSchools = newValue;

                                setSelectedCriteria(newValueArray);
                              }}
                              options={criteriaChoicesData?.locations}
                              disableCloseOnSelect
                              getOptionLabel={(option) =>
                                option.locationNameShort
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.locationId}>
                                  <Checkbox
                                    key={option.locationId}
                                    sx={{ marginRight: 8 }}
                                    checked={
                                      option.all
                                        ? !!(
                                            criterion?.criteriaSchools
                                              ?.length ===
                                            criteriaChoicesData?.locations
                                              ?.length
                                          )
                                        : selected
                                    }
                                  />
                                  {option.locationNameShort}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Schools"
                                  placeholder="Schools"
                                  required
                                  error={
                                    selectedCriteriaError[i]
                                      ?.criteriaSchoolsError
                                  }
                                  helperText={
                                    selectedCriteriaError[i]
                                      ?.criteriaSchoolsError
                                      ? "Schools is required."
                                      : ""
                                  }
                                />
                              )}
                              renderTags={(value, getTagProps) => {
                                return value.length ===
                                  criteriaChoicesData?.locations?.length ||
                                  value.length === 0 ? (
                                  <Chip
                                    color="primary"
                                    variant="contained"
                                    label={"ALL SCHOOLS"}
                                    onDelete={() => {
                                      let newValueArray = [...selectedCriteria];
                                      newValueArray[i].criteriaSchools = [];
                                      setSelectedCriteria(newValueArray);
                                    }}
                                  />
                                ) : (
                                  value.map((option, index) => (
                                    <Chip
                                      color="primary"
                                      variant="contained"
                                      label={option.locationNameShort}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                );
                              }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Autocomplete
                              /* reference: https://stackoverflow.com/questions/74452434/how-to-select-all-options-with-autocomplete-component-from-material-ui */
                              multiple
                              value={criterion.criteriaGrades ?? []}
                              filterOptions={(options, params) => {
                                // <<<--- inject the Select All option
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [
                                  {
                                    description: "Select All Grades...",
                                    all: true,
                                  },
                                  ...filtered,
                                ];
                              }}
                              onChange={(event, newValue) => {
                                let copySelectedCriteriaError = [
                                  ...selectedCriteriaError,
                                ];
                                copySelectedCriteriaError[
                                  i
                                ].criteriaGradesError = false;
                                setSelectedCriteriaError(
                                  copySelectedCriteriaError
                                );

                                let newValueArray = [...selectedCriteria];

                                if (newValue.find((option) => option.all)) {
                                  if (
                                    criterion?.criteriaGrades?.length ===
                                    criteriaChoicesData?.grades?.length
                                  ) {
                                    newValueArray[i].criteriaGrades = [];
                                  } else {
                                    newValueArray[i].criteriaGrades =
                                      criteriaChoicesData?.grades;
                                  }
                                  return setSelectedCriteria(newValueArray);
                                }

                                newValueArray[i].criteriaGrades = newValue;

                                setSelectedCriteria(newValueArray);
                              }}
                              options={criteriaChoicesData?.grades}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.description}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.gradeId}>
                                  <Checkbox
                                    key={option.gradeId}
                                    sx={{ marginRight: 8 }}
                                    checked={
                                      option.all
                                        ? !!(
                                            criterion?.criteriaGrades
                                              ?.length ===
                                            criteriaChoicesData?.grades?.length
                                          )
                                        : selected
                                    }
                                  />
                                  {option.description}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Grades"
                                  placeholder="Grades"
                                  required
                                  error={
                                    selectedCriteriaError[i]
                                      ?.criteriaGradesError
                                  }
                                  helperText={
                                    selectedCriteriaError[i]
                                      ?.criteriaGradesError
                                      ? "Grades is required."
                                      : ""
                                  }
                                />
                              )}
                              renderTags={(value, getTagProps) => {
                                return value.length ===
                                  criteriaChoicesData?.grades?.length ||
                                  value.length === 0 ? (
                                  <Chip
                                    color="primary"
                                    variant="contained"
                                    label={"ALL GRADES"}
                                    onDelete={() => {
                                      let newValueArray = [...selectedCriteria];
                                      newValueArray[i].criteriaGrades = [];
                                      setSelectedCriteria(newValueArray);
                                    }}
                                  />
                                ) : (
                                  value.map((option, index) => (
                                    <Chip
                                      color="primary"
                                      variant="contained"
                                      label={option.description}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                );
                              }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Autocomplete
                              /* reference: https://stackoverflow.com/questions/74452434/how-to-select-all-options-with-autocomplete-component-from-material-ui */
                              multiple
                              value={criterion.criteriaPrograms ?? []}
                              filterOptions={(options, params) => {
                                // <<<--- inject the Select All option
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [
                                  {
                                    programDescription:
                                      "Select All Programs...",
                                    all: true,
                                  },
                                  ...filtered,
                                ];
                              }}
                              onChange={(event, newValue) => {
                                let copySelectedCriteriaError = [
                                  ...selectedCriteriaError,
                                ];
                                copySelectedCriteriaError[
                                  i
                                ].criteriaProgramsError = false;
                                setSelectedCriteriaError(
                                  copySelectedCriteriaError
                                );

                                let newValueArray = [...selectedCriteria];

                                if (newValue.find((option) => option.all)) {
                                  if (
                                    criterion?.criteriaPrograms?.length ===
                                    criteriaChoicesData?.programs?.length
                                  ) {
                                    newValueArray[i].criteriaPrograms = [];
                                  } else {
                                    newValueArray[i].criteriaPrograms =
                                      criteriaChoicesData?.programs;
                                  }
                                  return setSelectedCriteria(newValueArray);
                                }

                                newValueArray[i].criteriaPrograms = newValue;

                                setSelectedCriteria(newValueArray);
                              }}
                              options={criteriaChoicesData?.programs}
                              disableCloseOnSelect
                              getOptionLabel={(option) =>
                                option.programDescription
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.programId}>
                                  <Checkbox
                                    key={option.programId}
                                    sx={{ marginRight: 8 }}
                                    checked={
                                      option.all
                                        ? !!(
                                            criterion?.criteriaPrograms
                                              ?.length ===
                                            criteriaChoicesData?.programs
                                              ?.length
                                          )
                                        : selected
                                    }
                                  />
                                  {option.programDescription}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Programs"
                                  placeholder="Programs"
                                  required
                                  error={
                                    selectedCriteriaError[i]
                                      ?.criteriaProgramsError
                                  }
                                  helperText={
                                    selectedCriteriaError[i]
                                      ?.criteriaProgramsError
                                      ? "Programs is required."
                                      : ""
                                  }
                                />
                              )}
                              renderTags={(value, getTagProps) => {
                                return value.length ===
                                  criteriaChoicesData?.programs?.length ||
                                  value.length === 0 ? (
                                  <Chip
                                    color="primary"
                                    variant="contained"
                                    label={"ALL PROGRAMS"}
                                    onDelete={() => {
                                      let newValueArray = [...selectedCriteria];
                                      newValueArray[i].criteriaPrograms = [];
                                      setSelectedCriteria(newValueArray);
                                    }}
                                  />
                                ) : (
                                  value.map((option, index) => (
                                    <Chip
                                      color="primary"
                                      variant="contained"
                                      label={option.programDescription}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        xs={12}
                        sx={{
                          marginTop: 1,
                          paddingLeft: 1,
                          textAlign: "center",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            if (selectedCriteria.length > 1) {
                              setSelectedCriteria(
                                selectedCriteria.filter(
                                  (criterion, index) => index !== i
                                )
                              );
                              setSelectedCriteriaError(
                                selectedCriteriaError.filter(
                                  (criterionError, index) => index !== i
                                )
                              );
                            } else {
                              setSelectedCriteria([
                                {
                                  criteriaSchools: [],
                                  criteriaGrades: [],
                                  criteriaPrograms: [],
                                },
                              ]);
                              setSelectedCriteriaError([
                                {
                                  criteriaSchoolsError: false,
                                  criteriaGradesError: false,
                                  criteriaProgramsError: false,
                                },
                              ]);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
              <Grid
                container
                item
                spacing={0}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Button
                  onClick={() => {
                    setSelectedCriteria([
                      ...selectedCriteria,
                      {
                        criteriaSchools: [],
                        criteriaGrades: [],
                        criteriaPrograms: [],
                      },
                    ]);
                    setSelectedCriteriaError([
                      ...selectedCriteriaError,
                      {
                        criteriaSchoolsError: false,
                        criteriaGradesError: false,
                        criteriaProgramsError: false,
                      },
                    ]);
                  }}
                  sx={{ px: 4 }}
                >
                  Add Criteria
                </Button>
              </Grid>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                disabled={formDisabled}
                checked={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                }}
              />
            }
            label="Active"
          />
        </Grid>
        <Grid item>
          <Button
            disabled={formDisabled}
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={clickSave}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={formDisabled}
            onClick={() => {
              navigate("/partners");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
  //#endregion
}
