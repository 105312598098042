import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetPartnerHomeCounts } from "../../services/hooks/partnerHomeHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function HomeTablePartnerProgramCounts() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const query = useGetPartnerHomeCounts();
  const data = query?.data?.data?.providerPrograms;

  return (
    <Paper sx={{ height: "100%", maxHeight: 400, overflowY: "auto" }}>
      <Box sx={commonStyles.paperPadding}>
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Provider Programs
        </Typography>
      </Box>

      <TableContainer>
        <Table size="small">
          <TableBody>
            {data?.map((d, i) => (
              <React.Fragment key={i}>
                <TableRow key={i * 2 + 0}>
                  <TableCell colSpan={3}>
                    <Typography>
                      <strong>{d.provider}</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={i * 2 + 1}>
                  <TableCell>
                    Active <strong>{d.activeCount}</strong>
                  </TableCell>
                  <TableCell>
                    Upcoming <strong>{d.upcomingCount}</strong>
                  </TableCell>
                  <TableCell>
                    Completed <strong>{d.completedCount}</strong>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
