import React, { useState } from "react";
import { Box, Grid2, Button, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faPlay,
  faEye,
  faThumbtack,
  faLineColumns,
  faArrowsFromLine,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";

export default function TableTipsVisibility(props) {
  const { sx } = props;
  const theme = useTheme();
  const mode = theme?.palette?.mode;

  const [playMove, setPlayMove] = useState(true);
  const [playPin, setPlayPin] = useState(true);
  const [playManage, setPlayManage] = useState(true);
  const handleTogglePlayMove = () => setPlayMove(!playMove);
  const handleTogglePlayPin = () => setPlayPin(!playPin);
  const handleTogglePlayManage = () => setPlayManage(!playManage);

  const slideStyles = {
    ...sx,
    background: theme?.palette?.secondary?.[mode === "dark" ? "light" : "dark"], //use light background for dark mode, vice versa
    color: theme?.palette?.background?.default,
  };
  const gifStyles = {
    width: "auto",
    mx: "auto",
    my: 1,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faEye} />
            &nbsp;Visibility
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            <strong>A table with a view. </strong> Customize how you see
            the&nbsp;data...
          </Typography>
        </Box>

        <Box>
          <Paper sx={gifStyles}>
            <img
              src={`https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-move.${
                !!playMove ? "gif" : "png"
              }`}
              alt="screenshot for moving"
              style={{ width: "100%" }}
            />
            <Button
              size="small"
              startIcon={
                <FontAwesomeIcon icon={!!playMove ? faSquare : faPlay} />
              }
              onClick={handleTogglePlayMove}
            >
              {!!playMove ? "Stop" : "Play"}
            </Button>
          </Paper>
          <Typography variant="h5" component="h2">
            <FontAwesomeIcon icon={faArrowsFromLine} rotation={90} /> Move
            <Typography variant="body2" component="span">
              : Click and drag headers to rearrange&nbsp;columns.
            </Typography>
          </Typography>
        </Box>

        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Paper sx={gifStyles}>
              <img
                src={`https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-pin.${
                  !!playPin ? "gif" : "png"
                }`}
                alt="screenshot for moving"
                style={{ width: "100%" }}
              />
              <Button
                size="small"
                startIcon={
                  <FontAwesomeIcon icon={!!playPin ? faSquare : faPlay} />
                }
                onClick={handleTogglePlayPin}
              >
                {!!playPin ? "Stop" : "Play"}
              </Button>
            </Paper>
            <Typography variant="h5" component="h2">
              <FontAwesomeIcon icon={faThumbtack} transform={{ rotate: 30 }} />{" "}
              Pin
              <Typography variant="body2" component="span">
                : Use the menu to stick a column to the left or&nbsp;right.
              </Typography>
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Paper sx={gifStyles}>
              <img
                src={`https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-manage.${
                  !!playManage ? "gif" : "png"
                }`}
                alt="screenshot for moving"
                style={{ width: "100%" }}
              />
              <Button
                size="small"
                startIcon={
                  <FontAwesomeIcon icon={!!playManage ? faSquare : faPlay} />
                }
                onClick={handleTogglePlayManage}
              >
                {!!playManage ? "Stop" : "Play"}
              </Button>
            </Paper>
            <Typography variant="h5" component="h2">
              <FontAwesomeIcon icon={faLineColumns} /> Manage
              <Typography variant="body2" component="span">
                : Hide and reveal columns with the menu's last&nbsp;option.
              </Typography>
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}
