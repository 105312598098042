import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetActivity,
  usePostActivity,
  usePutActivity,
} from "../../services/hooks/activitiesHook";
import { useGetActivityTypes } from "../../services/hooks/activitiesHook";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import BouncingO from "../common/BouncingO";
import Error from "../common/Error";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function ActivityCru() {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const isEdit = !!activityId;

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");
  const [activityTypeId, setActivityTypeId] = useState();
  const [active, setActive] = useState(true);

  const activityTypesQuery = useGetActivityTypes();
  const activityTypes = activityTypesQuery.data?.data;

  // populate form with existing activity data
  const existingActivity = useGetActivity(activityId);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (existingActivity?.data?.data && !loaded) {
      const data = existingActivity?.data?.data;
      setName(data.activityName ?? "");
      setDescription(data.activityDescription ?? "");
      setActivityTypeId(data.activityTypeId ?? 1);
      setActive(data.active);
      setLoaded(true);
    }
  }, [existingActivity]);

  const createActivity = usePostActivity({
    activityName: name,
    activityDescription: description,
    activityTypeId: activityTypeId,
    active: active,
  });

  const editActivity = usePutActivity(activityId, {
    activityId: activityId,
    activityName: name,
    activityDescription: description,
    activityTypeId: activityTypeId,
    active: active,
  });

  const clickSubmit = () => {
    if (Validate()) {
      if (isEdit) {
        editActivity.mutate();
      } else {
        createActivity.mutate();
      }
    }
  };
  const Validate = () => {
    let isValid = true;
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    }
    return isValid;
  };

  const formDisabled =
    existingActivity?.isLoading ||
    existingActivity?.isError ||
    editActivity.loading ||
    createActivity.loading;

  // Listen for create/edit activity mutation
  useEffect(() => {
    if (createActivity.isSuccess) {
      navigate("/activities");
    } else if (editActivity.isSuccess) {
      navigate("/activities");
    }
  }, [createActivity, editActivity]);

  const isLoading = () => {
    return (
      (isEdit && existingActivity.isLoading) || activityTypesQuery.isLoading
    );
  };

  return (
    <>
      <ScrollToTopOnMount />
      {isLoading() ? (
        <BouncingO />
      ) : (
        <>
          <Typography variant="h1">
            {isEdit ? "Edit" : "Create"} Activity
          </Typography>
          <br />
          <Error
            message="There was a problem getting activity information."
            query={existingActivity}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={formDisabled}
                      fullWidth
                      value={name}
                      id="activity-name"
                      label="Name"
                      variant="outlined"
                      error={!!nameError}
                      helperText={nameError}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError("");
                      }}
                      inputProps={{
                        maxLength: 64,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {" "}
                  <TextField
                    disabled={formDisabled}
                    fullWidth
                    multiline
                    rows={5}
                    value={description}
                    id="activity-description"
                    label="Description"
                    variant="outlined"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    inputProps={{
                      maxLength: 512,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Error
                    message="There was a problem getting activity types."
                    query={activityTypesQuery}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="Activity Type-label">
                      Activity Type
                    </InputLabel>
                    <Select
                      disabled={formDisabled}
                      label="Activity Type"
                      labelId="Activity Type-label"
                      margin="dense"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={[activityTypeId]}
                      onChange={(e) => {
                        setActivityTypeId(e.target.value);
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Activity Type</em>;
                        }

                        const selectedActivityType = activityTypes?.find(
                          (at) => {
                            return at.activityTypeId === selected[0];
                          }
                        );

                        return selectedActivityType?.activityTypeName ?? "";
                      }}
                    >
                      {activityTypes?.map((activityType, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={activityType.activityTypeId}
                          >
                            <ListItemText>
                              {activityType.activityTypeName}
                            </ListItemText>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={formDisabled}
                          value={active}
                          checked={active}
                          onChange={() => {
                            setActive(!active);
                          }}
                        />
                      }
                      label="Active"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        disabled={formDisabled}
                        variant="contained"
                        onClick={() => {
                          clickSubmit();
                        }}
                        endIcon={formDisabled && <CircularProgress size={20} />}
                      >
                        {isEdit ? "Update" : "Create"} Activity
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          navigate("/activities");
                        }}
                        disabled={formDisabled}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h3">What is an Activity?</Typography>
              <Typography>
                An Activity is the “Basketball” in “Girls Varsity Basketball”.
                While there may be hundreds of programs, there may only be a few
                dozen activities. They are used for the purpose of tracking
                things like how many Tennis programs there are, or how many
                programs with an activity type of “Athletic”. Avoid replicating
                existing activities to ensure program data is reported
                accurately.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
