import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  useGetParticipantDetails,
  useGetParticipantDetailsByStudentId,
} from "../../services/hooks/participantsHook";
import Error from "./Error";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileMedical,
  faMessageMedical,
  faCameraSlash,
} from "@fortawesome/pro-solid-svg-icons";
import StudentPhoto from "./StudentPhoto";
import { formatPhoneNumber, getGradeName } from "../../services/utilities";
import { useUser } from "../../services/contexts/userContext";

export default function ParticipantDetails(props) {
  const { participantId, onClose, studentId } = props;
  const participantQuery = useGetParticipantDetails(participantId);
  const studentQuery = useGetParticipantDetailsByStudentId(studentId);

  const participant =
    !!studentId && studentId?.length > 0
      ? studentQuery?.data?.data
      : participantQuery?.data?.data;
  const { user } = useUser();

  const navigate = useNavigate();
  const theme = useTheme();

  const getContactType = (contactType, livingWith) => {
    if (contactType === "G")
      return (
        <span>
          Guardian
          {!!livingWith && (
            <Typography component="span" variant="caption">
              &nbsp;(living&nbsp;with)
            </Typography>
          )}
        </span>
      );
    if (contactType === "C") return "Emergency";
    if (contactType === "O") return "Emergency";
    else return contactType;
  };

  const GetProgramChipStyles = (program) => {
    //switch statement for 4 different programs
    switch (program) {
      case "504":
        return {
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          fontWeight: "bold",
        };
      case "ELL":
        return {
          background: "transparent",
          border: `2px solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
          fontWeight: "bold",
        };
      case "FRL":
        return {
          background: "transparent",
          border: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          fontWeight: "bold",
        };
      case "Spec Ed":
        return {
          background: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };

  const formatCycleDays = (cycleDays) => {
    const days = cycleDays.split(", ");
    const formattedDays = days.map((day) => {
      if (day === "Monday") {
        return "Mo";
      } else if (day === "Tuesday") {
        return "Tu";
      } else if (day === "Wednesday") {
        return "Wed";
      } else if (day === "Thursday") {
        return "Th";
      } else if (day === "Friday") {
        return "Fr";
      } else {
        return day;
      }
    });
    return formattedDays.join(", ");
  };

  //styles
  const lesserTextStyles = {
    fontSize: "0.75rem",
  };
  const restrainingNoticeStyles = {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    p: theme.spacing(2),
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(participantQuery)}
      onClose={onClose}
    >
      <DialogTitle>Participant Details</DialogTitle>

      {participantQuery?.isLoading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <Skeleton width="8rem" height="8rem" variant="circular" />{" "}
            </Grid>
            <Grid item>
              <Skeleton height="3rem" width="300px" />
              <Skeleton height="2rem" width="100px" />
              <Skeleton width="350px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height="2rem" width="200px" />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <Error
        message="There was an error getting this participant's details"
        query={participantQuery}
      />
      {(participantQuery.isSuccess || studentQuery.isSuccess) && (
        <>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={12} sm={3} md={2}>
                  <StudentPhoto studentId={participant?.studentId} />
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                  <Typography variant="h3" component="h2">
                    {participant?.lastName}, {participant?.firstName}
                  </Typography>
                  <Typography variant="h5" component="h3">
                    {(user?.role === "TPS Admin" ||
                      user?.role === "TPS Owner" ||
                      user?.role === "TPS Viewer") &&
                      participant?.studentId}
                    {participant?.status !== "Active" && (
                      <Typography component="span" variant="h6" color="error">
                        &nbsp;|&nbsp;Inactive
                      </Typography>
                    )}
                  </Typography>
                  <Typography>
                    {!!participant?.grade
                      ? getGradeName(participant?.grade)
                      : "Unknown Grade"}
                    {" | "}
                    {!!participant?.school
                      ? participant?.school
                      : "Unknown School"}
                    {" | "}
                    {" Race: "}
                    {participant?.race ?? "Unknown"}
                    {!!participant?.teeShirtSize &&
                      ` | T-Shirt Size: ${participant?.teeShirtSize}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2} sx={{ textAlign: "right" }}>
                  {/* Hiding More button untill the details page has more content 
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(`/reports/student/${participantId}`);
                    }}
                    endIcon={<FontAwesomeIcon icon={faCircleArrowRight} />}
                  >
                    More
                  </Button> */}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {(participant?.studentPrograms?.length > 0 ||
                  participant?.photoOptOut === "Y" ||
                  participant?.exclusionaryCount) && (
                  <Grid item xs={12} sm={9} md={10}>
                    <Grid container spacing={2}>
                      {/* Student programs */}
                      {participant?.studentPrograms?.map((sp, i) => (
                        <Grid
                          item
                          key={i}
                          sx={
                            !(
                              user?.role === "TPS Admin" ||
                              user?.role === "TPS Owner" ||
                              user?.role === "TPS Viewer" ||
                              user?.role === "Partner Manager"
                            ) &&
                            sp.program === "FRL" && { display: "none" }
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              sx={GetProgramChipStyles(sp.program)}
                              label={`${sp.program}`}
                            />
                            <Typography sx={lesserTextStyles}>
                              {sp.schoolYear}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                      {/* Photo Opt-out */}
                      {participant?.photoOptOut === "Y" && (
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                              }}
                              label={`Photo Opt-out`}
                              avatar={
                                <Avatar>
                                  <FontAwesomeIcon icon={faCameraSlash} />
                                </Avatar>
                              }
                            />
                          </Box>
                        </Grid>
                      )}
                      {/* Exclusionary count */}
                      {participant?.exclusionaryCount && (
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              sx={{
                                backgroundColor: "transparent",
                                border: `2px solid ${theme.palette.error.main}`,
                                color: theme.palette.error.main,
                                fontWeight: "bold",
                              }}
                              label={`Exclusionary Count: ${participant?.exclusionaryCount}`}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {/* Restraining Note */}
                {participant?.restrainingProtectionNote && (
                  <Grid item xs={12} sm={9} md={10}>
                    <Paper sx={restrainingNoticeStyles}>
                      <Typography>
                        <strong>Restraining Notice</strong>
                      </Typography>
                      <Typography>
                        {participant?.restrainingProtectionNote}
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {/* Medical alerts */}
                {participant?.medicalAlerts?.length > 0 &&
                  (user?.permissions?.includes("View Medical") ||
                    user?.permissions?.includes("View Medical Protected")) && (
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h3" color="error">
                        <FontAwesomeIcon icon={faMessageMedical} />
                        &nbsp;Medical&nbsp;Alerts
                      </Typography>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            {participant?.medicalAlerts?.map((alert, index) => (
                              <TableRow key={index} hover>
                                <TableCell>
                                  {alert?.protectedData.toLowerCase() === "no"
                                    ? alert.description
                                    : user?.permissions?.includes(
                                        "View Medical Protected"
                                      )
                                    ? alert.description
                                    : "Protected"}{" "}
                                </TableCell>
                                <TableCell>
                                  {alert?.protectedData.toLowerCase() === "no"
                                    ? alert.comment
                                    : user?.permissions?.includes(
                                        "View Medical Protected"
                                      )
                                    ? alert.comment
                                    : "You don't have sufficient permissions to see this alert."}{" "}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* FIXME: Think this may be from something old...there is no medicalAlertComment property */}
                      {participant?.medicalAlertComment && (
                        <Typography>
                          <FontAwesomeIcon
                            size="lg"
                            icon={faFileMedical}
                            color={theme.palette.error.dark}
                          />{" "}
                          {participant?.medicalAlertComment.join(", ")}
                        </Typography>
                      )}
                    </Grid>
                  )}

                {/* Emergency Contacts */}
                <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                    Emergency Contacts
                  </Typography>
                  {participant?.emergencyContacts?.length === 0 ? (
                    <Typography>No emergency contacts in E-School</Typography>
                  ) : (
                    <TableContainer>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone(s)</TableCell>
                            <TableCell>Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {participant?.emergencyContacts?.map((c, index) => (
                            <TableRow key={index} hover>
                              <TableCell>
                                {getContactType(c?.contactType, c?.livingWith)}
                              </TableCell>
                              <TableCell>
                                {c?.firstName} {c?.lastName}
                              </TableCell>
                              <TableCell>
                                {c?.contactPhones?.length > 0 &&
                                  c?.contactPhones?.map((phone, i) => (
                                    <div key={i}>
                                      <Link href={`tel:${phone?.phoneNumber}`}>
                                        {formatPhoneNumber(phone?.phoneNumber)}
                                      </Link>
                                      <Typography
                                        component="span"
                                        variant="caption"
                                      >
                                        &nbsp;({phone?.phoneType})
                                      </Typography>
                                    </div>
                                  ))}
                              </TableCell>
                              <TableCell>
                                {!!c?.emailAddress && (
                                  <Link href={`mailto:${c?.emailAddress}`}>
                                    {c?.emailAddress}
                                  </Link>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
                <Grid item xs={12}></Grid>

                {/* Waitlists */}
                {participant?.currentWaitlist?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h3">
                      Current Waitlists
                    </Typography>
                    <TableContainer
                      sx={{
                        maxHeight: 185,
                        overflowY: "auto",
                        paddingBottom: theme.spacing(2),
                      }}
                    >
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Program Title</TableCell>
                            <TableCell>Program Days</TableCell>
                            <TableCell align="center">Activity</TableCell>
                            <TableCell align="center">Location</TableCell>
                            <TableCell align="center">Waitlist Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {participant?.currentWaitlist?.map((cw, i) => {
                            return (
                              <TableRow
                                onClick={() => {
                                  navigate(`/programs/${cw?.programId}`);
                                }}
                                key={i}
                                hover
                              >
                                <TableCell
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {cw?.title}
                                </TableCell>
                                <TableCell align="center">
                                  {formatCycleDays(cw.cycleName)}
                                </TableCell>
                                <TableCell align="center">
                                  {cw?.activityName}
                                </TableCell>
                                <TableCell align="center">
                                  {cw?.locationName}
                                </TableCell>
                                <TableCell align="center">
                                  {dayjs(cw?.waitlistDate).format("M/D/YYYY")}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {/* Program Participation */}
                <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                    Program Participation
                  </Typography>
                  <TableContainer sx={{ maxHeight: 185, overflowY: "auto" }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Program Title</TableCell>
                          <TableCell>Program Days</TableCell>
                          {/* <TableCell>Activity</TableCell> */}
                          <TableCell>Entry Date</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participant?.currentProgramParticipation?.map(
                          (cpp, i) => {
                            return (
                              <TableRow
                                onClick={() => {
                                  navigate(`/programs/${cpp?.programId}`);
                                }}
                                key={i}
                                hover
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    cpp?.status.toLowerCase() === "completed" &&
                                    theme.palette.background.default,
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color:
                                      cpp?.status.toLowerCase() ===
                                        "completed" &&
                                      theme.palette.text.secondary,
                                  }}
                                >
                                  {cpp?.title ? cpp?.title : "No Title"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color:
                                      cpp?.status.toLowerCase() ===
                                        "completed" &&
                                      theme.palette.text.secondary,
                                  }}
                                >
                                  {formatCycleDays(cpp.cycleName)}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color:
                                      cpp?.status.toLowerCase() ===
                                        "completed" &&
                                      theme.palette.text.secondary,
                                  }}
                                >
                                  {dayjs(cpp?.entryDate).format("M/D/YYYY")}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color:
                                      cpp?.status.toLowerCase() ===
                                        "completed" &&
                                      theme.palette.text.secondary,
                                  }}
                                >
                                  {cpp?.locationName}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color:
                                      cpp?.status.toLowerCase() ===
                                        "completed" &&
                                      theme.palette.text.secondary,
                                  }}
                                >
                                  {cpp?.status}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
