import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation, QueryCache } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

export function useGetPartners() {
  const token = GetAccessToken();
  const url = `Partners/GetPartners`;
  const query = useQuery(url, async () => {
    return await AppsGet(token, url);
  });

  return query;
}

export function useGetPartnerTypes() {
  const token = GetAccessToken();
  const url = `Partners/GetPartnerTypes`;
  const query = useQuery(url, async () => {
    return await AppsGet(token, url);
  });

  return query;
}

export function useGetPartner(partnerId) {
  const token = GetAccessToken();
  const url = `Partners/GetPartner/${partnerId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: partnerId != null,
    }
  );
  return query;
}

export function useGetPartnerDsaDates(partnerId) {
  const token = GetAccessToken();
  const url = `Partners/GetPartnerDsaDates/${partnerId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: partnerId != null,
    }
  );
  return query;
}

export function useUpsertPartner(partner) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "Partners/UpsertPartner";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, partner);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("partners")
        );
        snackbar.enqueueSnackbar(`Partner saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving the partner. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
