import React from "react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ProgramStatusChip(props) {
  const { startDate, endDate, numParticipants, numAttendanceMissing, isDraft } =
    props;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const hasDetails = () => {
    return (
      startDate != null &&
      endDate != null &&
      numParticipants != null &&
      numAttendanceMissing != null
    );
  };

  const getStatus = () => {
    let today = dayjs();

    //Draft
    if (isDraft) {
      return {
        text: "Draft",
        subtext: "",
        styles: {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 999,
          color: theme.palette.secondary.contrastText,
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          whiteSpace: "nowrap",
        },
      };
    }

    //Upcoming
    if (today.isBefore(dayjs(startDate))) {
      return {
        text: "Upcoming",
        subtext: `Begins ${dayjs(startDate).format("M/D/YY")}`,
        styles: {
          backgroundColor: theme.palette.info.main,
          borderRadius: 999,
          color: theme.palette.info.contrastText,
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          whiteSpace: "nowrap",
        },
      };
    }
    //No Participants
    if (numParticipants === 0) {
      return {
        text: "No Participants",
        subtext: ``,
        styles: {
          backgroundColor: theme.palette.warning.main,
          borderRadius: 999,
          color: theme.palette.warning.contrastText,
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          whiteSpace: "nowrap",
        },
      };
    }
    //Missing Attendance
    if (numAttendanceMissing > 0) {
      return {
        text: "Missing Attendance",
        subtext: `${numAttendanceMissing} ${
          numAttendanceMissing > 1 ? "days" : "day"
        } without attendance`,
        styles: {
          backgroundColor: theme.palette.error.main,
          borderRadius: 999,
          color: theme.palette.error.contrastText,
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          whiteSpace: "nowrap",
        },
      };
    }
    //Completed
    if (today.isAfter(dayjs(endDate)) && numAttendanceMissing === 0) {
      return {
        text: "Completed",
        subtext: `Ended ${dayjs(endDate).format("M/D/YY")}`,
        styles: null,
        styles: {
          backgroundColor: theme.palette.nav.main,
          borderRadius: 999,
          color: theme.palette.nav.contrastText,
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          whiteSpace: "nowrap",
        },
      };
    }
    //All caught up
    return {
      text: "All caught up",
      subtext: ``,
      styles: {
        backgroundColor: theme.palette.success.main,
        borderRadius: 999,
        color: theme.palette.success.contrastText,
        px: theme.spacing(1),
        py: theme.spacing(0.5),
        whiteSpace: "nowrap",
      },
    };
    // }
  };

  //styles
  const chipStyles = {
    borderRadius: 1000,
    p: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  };

  return (
    <>
      {hasDetails() && (
        <span>
          <Typography component="span" sx={getStatus().styles}>
            {getStatus().text}
          </Typography>

          <Typography sx={commonStyles.deemphasizedText}>
            {getStatus().subtext}
          </Typography>
        </span>
      )}
    </>
  );
}
