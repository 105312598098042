import React, { useState } from "react";
import { Box, Grid2, Button, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faPlay,
  faEllipsisV,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";

export default function TableTipsGroup(props) {
  const { sx } = props;
  const theme = useTheme();
  const textColor = theme?.palette?.text?.primary;

  const [play, setPlay] = useState(true);
  const handleToggle = () => setPlay(!play);

  const slideStyles = {
    ...sx,
    background: theme?.palette?.background?.default,
    color: textColor,
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: 64,
                width: 64,
              }}
            >
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-221qx1"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="GroupWorkIcon"
                fill={textColor}
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2M8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5M9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8m6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5"></path>
              </svg>
            </Box>
            Group
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            Use the Group feature to organize data into
            expandable&nbsp;sections.
          </Typography>
        </Box>

        <Paper
          elevation={3}
          sx={{
            width: "auto",
            mx: "auto",
            my: 1,
            p: 1,
            pb: 0,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme?.palette?.primary?.main,
            borderRadius: 4,
          }}
        >
          <img
            src={`https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-group.${
              !!play ? "gif" : "png"
            }`}
            alt="screenshot for filtering"
            style={{ borderRadius: 4 }}
          />
          <Button
            size="small"
            sx={{ color: theme?.palette?.primary?.contrastText }}
            startIcon={<FontAwesomeIcon icon={!!play ? faSquare : faPlay} />}
            onClick={handleToggle}
          >
            {!!play ? "Stop" : "Play"}
          </Button>
        </Paper>

        <Grid2 container spacing={2}>
          <Grid2 size={3}>
            <br />
          </Grid2>
          <Grid2 size={6}>
            <Typography variant="h5" component="h2">
              How to get your group on...
            </Typography>
            <Typography variant="body2" component="div">
              <ol>
                <li>
                  Hover over any column header and click the menu
                  button.&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                </li>
                <li>
                  Click Group&nbsp;by...
                  <small>
                    <br />
                    (Optionally sort the&nbsp;column.)
                  </small>
                </li>
                <li>Expand or collapse any group to see its&nbsp;contents.</li>
              </ol>
              Note that you can have groups within groups and can use "Stop
              grouping by..." to remove a group.
            </Typography>
          </Grid2>
          <Grid2 size={3}>
            <br />
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}
