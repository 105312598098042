import { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export default function TableSample(props) {
  const { density } = props;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  //MARK: COLUMNS
  const columns = [
    { field: "studentId", headerName: "ID", width: 100 },
    {
      field: "school",
      headerName: "School",
      width: 100,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 60,
      align: "center",
    },
    { field: "food", headerName: "Food", width: 100 },
    { field: "color", headerName: "Color", width: 100 },
    { field: "subject", headerName: "Subject", width: 120 },
    { field: "movie", headerName: "Movie", minWidth: 100, flex: 1 },
  ];

  const schools = {
    elementary: [
      "Arlington",
      "Birney",
      "Blix",
      "Boze",
      "DeLong",
      "Downing",
      "Edison",
      "Fawcett",
      "Fern Hill",
      "Franklin",
      "Grant",
      "Stafford",
      "Jefferson",
      "Larchmont",
      "Lister",
      "Lowell",
      "Lyon",
      "Madison",
      "Mann",
      "Travis",
      "NE Tacoma",
      "Reed",
      "Roosevelt",
      "Sheridan",
      "Sherman",
      "Stanley",
      "Skyline",
      "Washington",
      "Whitman",
    ],
    middle: [
      "Giaudrone",
      "Baker",
      "Gray",
      "Hunt",
      "Hilltop",
      "Mason",
      "Meeker",
      "Stewart",
      "Truman",
    ],
    high: [
      "Foss",
      "Lincoln",
      "Mt Tahoma",
      "Stadium",
      "Silas",
      "Oakland",
      "IDEA",
      "SOTA",
      "SAMI",
    ],
  };
  const foods = [
    "Pizza",
    "Sushi",
    "Burger",
    "Salad",
    "Pasta",
    "Tacos",
    "Ice Cream",
    "Steak",
  ];
  const colors = [
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Purple",
    "Orange",
    "Pink",
    "Black",
  ];
  const subjects = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    "PE",
  ];
  const movies = [
    "Star Wars",
    "Harry Potter",
    "Avengers",
    "Frozen",
    "Lion King",
    "Jurassic Park",
    "Toy Story",
    "Finding Nemo",
    "The Incredibles",
    "Barbie",
    "Spiderman",
    "Wonder",
    "Yes Day",
  ];
  const olderStudentMovies = [
    "Mean Girls",
    "The Matrix",
    "Lord of the Rings",
    "Pirates of the Caribbean",
    "Indiana Jones",
    "Back to the Future",
    "Batman",
    "Divergent",
    "The Breakfast Club",
  ];

  //MARK: ROWS
  const rows = Array.from({ length: 100 }, (_, index) => {
    const grade = Math.floor(Math.random() * 12) + 1;
    return {
      id: index + 1,
      studentId: generateStudentId(grade),
      school: getSchoolByGrade(grade),
      grade: grade,
      food: getRandomElement(foods),
      color: getRandomElement(colors),
      subject: getRandomElement(subjects),
      movie: getMovieByGrade(grade),
    };
  });

  //#region STYLES
  const gridStyles = {
    border: "none",
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
      // allowing for wrapping of long column header titles
      {
        lineHeight: "1rem",
        whiteSpace: "normal",
      },
    "& .MuiDataGrid-columnHeader:last-of-type": {
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
  };
  //#endregion

  //MARK: RENDER
  return (
    <>
      <Paper elevation={5} sx={{ width: "720px" }}>
        <Typography variant="h4" component="h3" sx={{ m: 2, mt: 1 }}>
          Student Favorites
        </Typography>
        <DataGridPremium
          rows={rows}
          columns={columns}
          density={!!density ? density : "standard"}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick={true}
          autoHeight
          sx={gridStyles}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Paper>
    </>
  );

  //MARK: FUNCTIONS
  function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function generateStudentId(grade) {
    const prefix = 305 - (grade - 1) * 10;
    const suffix = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    return `${prefix}${suffix}`;
  }

  function getSchoolByGrade(grade) {
    if (grade <= 5) return getRandomElement(schools.elementary);
    if (grade <= 8) return getRandomElement(schools.middle);
    return getRandomElement(schools.high);
  }

  function getMovieByGrade(grade) {
    if (grade <= 6) {
      return getRandomElement(movies);
    } else {
      // Combine both arrays for grades 7-12
      const combinedMovies = [...movies, ...olderStudentMovies];
      return getRandomElement(combinedMovies);
    }
  }
}
