import React, { useState, useMemo, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useGetPartnerUser } from "../hooks/partnerSignInHook";
import useSessionStorage from "../hooks/useSessionStorage";

const UserContext = React.createContext();

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserContextProvider`);
  }
  return context;
}

export function UserContextProvider(props) {
  const msal = useMsal();

  const userQuery = useGetPartnerUser(msal?.accounts?.length > 0);
  const userData = userQuery?.data?.data;

  const user = useMemo(() => {
    return {
      oid: msal?.accounts[0]?.idTokenClaims?.oid,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: msal?.accounts[0]?.username?.toLowerCase(),
      username: msal?.accounts[0]?.username?.toLowerCase()?.split("@")?.[0],
      domain: msal?.accounts[0]?.username?.toLowerCase()?.split("@")?.[1],

      validUser: userData?.personId != null && userData?.role != null,
      personId: userData?.personId,
      role: userData?.role,
      locationIds: userData?.locationIds,
      permissions: userData?.permissions,
      settings: userData?.settings,
      partners: userData?.partners,
      sharedPartners: userData?.sharedPartners,
    };
  }, [msal?.accounts, userData]);

  const value = { user, userQuery };
  return <UserContext.Provider value={value} {...props} />;
}
