import { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDizzy } from "@fortawesome/free-solid-svg-icons";

export default function Error(props) {
  const { message, query } = props;
  const theme = useTheme();
  const [showMessage, setShowMessage] = useState(false);

  const containerStyles = {
    p: theme.spacing(2),
  };
  const lesserText = {
    fontSize: "0.75rem !important",
    wordWrap: "break-word",
    maxWidth: "500px",
  };

  return (
    <>
      {query?.isError && (
        <Grid container alignItems={"top"} spacing={2} sx={containerStyles}>
          <Grid item>
            <FontAwesomeIcon
              color={theme?.palette?.text?.secondary}
              size="3x"
              icon={faDizzy}
            />
          </Grid>
          <Grid item>
            <Typography color={theme?.palette?.text?.secondary} variant="h5">
              {message ?? "Something went wrong"}
            </Typography>
            <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
              {query?.error?.message}
            </Typography>
            <br />
            {/* {(process.env.REACT_APP_ENVIRONMENT === "local" ||
              process.env.REACT_APP_ENVIRONMENT === "development") && ( */}
            <>
              {query && (
                <Button
                  onClick={() => {
                    setShowMessage(!showMessage);
                  }}
                >
                  {showMessage ? "Hide" : "Show"} Error
                </Button>
              )}
              {showMessage && (
                <Box>
                  <Typography sx={lesserText}>
                    {query?.error?.request?.responseURL}
                  </Typography>
                  <br />
                  <Typography sx={lesserText}>
                    {query?.error?.request?.responseText}
                  </Typography>
                </Box>
              )}
            </>
            {/* )} */}
          </Grid>
        </Grid>
      )}
    </>
  );
}
