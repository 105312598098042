import React, {
  // useEffect,
  useState,
  // useMemo
} from "react";
import {
  //   IconButton,
  Button,
  Dialog,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import DialogCloseButton from "./DialogCloseButton";
import Glossary from "./../pages/Glossary";

export default function GlossaryButtonDialog(props) {
  const { buttonText, variant, term } = props;
  //   const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMainButtonClick = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  //#region STYLES
  const slideStyles = {
    // width: 836, // 836 is 900 (md width) minus 64 for dialog margins
    // height: 836,
  };
  //#endregion

  //MARK: RENDER
  return (
    <>
      {!!term ? (
        <IconButton
          aria-label={`Define ${term}`}
          title={`Define ${term}`}
          size="small"
          onClick={() => {
            handleMainButtonClick();
          }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </IconButton>
      ) : (
        <Button
          variant={variant ?? "text"}
          size="small"
          startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
          onClick={() => {
            handleMainButtonClick();
          }}
        >
          {buttonText ?? "Glossary"}
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            border: "none",
            width: 836,
            minWidth: 836,
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <DialogCloseButton handleClose={handleClose} scrollable />
          <Box sx={slideStyles}>
            <Glossary term={term} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
