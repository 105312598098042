import React, { useState } from "react";
import { Box, Grid2, Button, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSquare,
  faPlay,
  faEllipsisV,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";

export default function TableTipsFilter(props) {
  const { sx } = props;
  const theme = useTheme();
  const mode = theme?.palette?.mode;

  const [play, setPlay] = useState(true);
  const handleToggle = () => setPlay(!play);

  const slideStyles = {
    ...sx,
    background: theme?.palette?.success?.[mode === "dark" ? "light" : "dark"], //use light background for dark mode, vice versa
    color: theme?.palette?.background?.default,
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography component="h1" variant="h1">
            <FontAwesomeIcon icon={faFilter} />
            &nbsp;Filter
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            <strong>Think of it like a targeted search. </strong> Use the Filter
            feature to find specific data in a column and shrink the table to
            only those&nbsp;matches.
          </Typography>
        </Box>

        <Grid2
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          flexWrap="nowrap"
        >
          <Grid2>
            <Paper
              sx={{
                width: "auto",
                mx: "auto",
                my: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={`https://dnastash.blob.core.windows.net/apps-images/Common/table-tips-filter.${
                  !!play ? "gif" : "png"
                }`}
                alt="screenshot for filtering"
              />
              <Button
                size="small"
                startIcon={
                  <FontAwesomeIcon icon={!!play ? faSquare : faPlay} />
                }
                onClick={handleToggle}
              >
                {!!play ? "Stop" : "Play"}
              </Button>
            </Paper>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Typography variant="h5" component="h2">
              The basics
            </Typography>
            <Typography variant="body2" component="div">
              <ol>
                <li>
                  Hover over any column header and click the menu
                  button.&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faEllipsisV} size="xs" />
                </li>
                <li>Click Filter to open the filter&nbsp;panel.</li>
                <li>Type the value to filter&nbsp;by.</li>
                <li>Click outside the filter panel to close&nbsp;it.</li>
                <li>
                  Click the filter icon{" "}
                  <FontAwesomeIcon icon={faFilter} size="xs" /> if you want to
                  open the panel&nbsp;again.
                </li>
              </ol>
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Typography variant="h5" component="h2">
              And more...
            </Typography>
            <Typography variant="body2" component="div">
              <ul>
                <li>
                  Try switching "contains" to another&nbsp;option.
                  <ul>
                    <li>Use "equals" for an exact&nbsp;match.</li>
                    <li>
                      Use "is not empty" to exclude the rows with no data in
                      this&nbsp;column.
                    </li>
                    <li>Or use "starts with", "ends with"&nbsp;etc.</li>
                  </ul>
                </li>
                <li>You can add multiple filters to the same&nbsp;column.</li>
                <li>And you can also add filters to multiple&nbsp;columns.</li>
              </ul>
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}
