import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";

export default function DialogCloseButton(props) {
  const { handleClose, inverse, scrollable } = props;
  const theme = useTheme();

  return (
    <IconButton
      onClick={handleClose}
      aria-label="close"
      sx={{
        height: "2rem",
        width: "2rem",
        position: "absolute",
        top: 8,
        right: !!scrollable ? 20 : 8,
        backgroundColor: "transparent",
        backdropFilter: "blur(2px)",
        border: "2px solid transparent",
      }}
    >
      <FontAwesomeIcon
        icon={faTimes}
        size="sm"
        color={
          !!inverse
            ? theme?.palette?.getContrastText(theme?.palette?.text?.primary)
            : theme?.palette?.text?.primary
        }
      />
    </IconButton>
  );
}
