import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation, QueryCache } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

export function useGetPartnerUser(enabled) {
  const token = GetAccessToken();
  const url = `PartnerSignIn/GetPartnerUser`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    {
      enabled: enabled,
      retry: 50,
      cacheTime: 1000 * 60 * 60, // 1 hour
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );
  return query;
}
