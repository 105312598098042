import { Avatar } from "@mui/material";
import { useStudentPhoto } from "../../services/hooks/studentPhotoHook";

export default function StudentPhoto(props) {
  const { studentId, name, variant, styles } = props;

  const photoQuery = useStudentPhoto(studentId);
  const photoData = photoQuery?.data;

  const avatarStyles = {
    height: "8rem",
    width: "8rem",
    "&.MuiAvatar-root": {
      "& img": {
        mt: "20%",
        height: "140%",
      },
    },
  };

  return (
    <Avatar
      alt={`${name}'s photo`}
      src={photoData}
      variant={variant ?? "circular"}
      sx={[avatarStyles, styles]}
    />
  );
}
