import { useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  ScrollToTopOnMount,
  getSchoolYear,
  getGradeName,
} from "../../services/utilities";
import { useGetSlicers } from "../../services/hooks/partnerReportsHook";
import ChartLocationCounts from "./ChartLocationCounts";
import ChartActivityCounts from "./ChartActivityCounts";
import ChartActivityTypeCounts from "./ChartActivityTypeCounts";
import ChartProviderCounts from "./ChartProviderCounts";
import ChartTopActivityParticipation from "./ChartTopActivityParticipation";
import ReportSummary from "./ReportSummary";
import { useGetProgramReport } from "../../services/hooks/partnerReportsHook";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useUser } from "../../services/contexts/userContext";
import { ViewPrograms, ViewReports } from "../../services/permissions";

export default function ReportsPrograms() {
  const { user } = useUser();
  const theme = useTheme();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [selectedProviderIds, setSelectedProviderIds] = useState([]);
  const [selectedSchoolYears, setSelectedSchoolYears] = useState([
    getSchoolYear(),
  ]);
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [selectedActivityTypeIds, setSelectedActivityTypeIds] = useState([]);
  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  //Unlike the other report tabs...
  //This report centers on programs, not students.
  //Grades, genders and races are NOT columns in the data grid here (ie, programs don't have races).
  //Selections on this toolbar use a stored proc to requery the data, not just filter it in place.
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedRaces, setSelectedRaces] = useState([]);

  //#region SLICERS
  const slicers = {
    providerIds: selectedProviderIds,
    schoolYears: selectedSchoolYears,
    sessionIds: selectedSessionIds,
    locationIds: selectedLocationIds,
    activityIds: selectedActivityIds,
    activityTypeIds: selectedActivityTypeIds,
    grades: selectedGrades,
    genders: selectedGenders,
    races: selectedRaces,
  };

  //All Slicers
  const slicerQuery = useGetSlicers();
  const allSlicers = slicerQuery?.data?.data;

  const providers = useMemo(() => {
    return allSlicers?.providers ?? [];
  }, [allSlicers]);

  const schoolYears = useMemo(() => {
    return allSlicers?.schoolYears?.map((s) => s.schoolYear) ?? [];
  }, [allSlicers]);

  const sessions = useMemo(() => {
    return allSlicers?.sessions ?? [];
  }, [allSlicers]);

  const locations = useMemo(() => {
    return allSlicers?.locations ?? [];
  }, [allSlicers]);

  const activityTypes = useMemo(() => {
    return allSlicers?.activityTypes ?? [];
  }, [allSlicers]);

  const activities = useMemo(() => {
    return allSlicers?.activities ?? [];
  }, [allSlicers]);

  const grades = useMemo(() => {
    return allSlicers?.grades ?? [];
  }, [allSlicers]);

  const genders = useMemo(() => {
    return allSlicers?.genders ?? [];
  }, [allSlicers]);

  const races = useMemo(() => {
    return allSlicers?.races ?? [];
  }, [allSlicers]);
  //#endregion

  const query = useGetProgramReport(slicers);
  const data = query?.data?.data;

  const slicerData = useMemo(() => {
    return data ? data : {};
  }, [data]);
  const programs = useMemo(() => {
    return data?.programs;
  }, [data]);

  //MARK: COLUMNS
  const columns = [
    { field: "provider", headerName: "Provider", flex: 2.25 },
    {
      field: "title",
      headerName: "Program",
      renderCell: (val) => {
        return <Link href={`/programs/${val.id}`}>{val.value}</Link>;
      },
      flex: 3,
    },
    { field: "activityTypeName", headerName: "Type", flex: 1 },
    { field: "locationName", headerName: "Location", flex: 1.5 },
    { field: "sessionName", headerName: "Session", align: "right", flex: 0.75 },
    { field: "programDays", headerName: "Days", align: "right", flex: 0.5 },
    {
      field: "attendance",
      headerName: "Attendance",
      align: "right",
      flex: 1,
      valueFormatter: (val) => {
        return (val * 100).toFixed(1) + "%";
      },
    },
  ];

  //#region STYLES
  const toolbarStyles = {
    p: 1,
    pt: ".75rem",
    borderRadius: "1rem",
    background: theme?.palette?.text?.primary,
    color: alpha(theme?.palette?.background?.default, 0.8),
    overflow: "visible",
    "& .MuiButton-root": {
      color: !theme?.palette?.primaryAlt
        ? theme?.palette?.primary?.main
        : theme?.palette?.primaryAlt?.light,
    },
    "& .MuiInputBase-root input": {
      color: theme?.palette?.background?.default,
    },
    "& .MuiAutocomplete-endAdornment": {
      color: `${theme?.palette?.background?.default} !important`,
    },
  };
  const filterLabelStyles = {
    lineHeight: "0.8rem",
    p: 0,
    m: 0,
    ml: 1,
  };
  const autoCompletePaperStyles = {
    background: theme?.palette?.text?.primary,
    color: theme?.palette?.background?.default,
  };
  const gridStyles = {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    borderRadius: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
      // allowing for wrapping of long column header titles
      {
        lineHeight: "1rem",
        whiteSpace: "normal",
      },
    "& .MuiDataGrid-columnHeader:last-of-type": {
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
  };
  //#endregion

  //MARK: RENDER
  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h4" component="h3" gutterBottom>
        Expanded Learning Opportunity Programs
      </Typography>
      {!(
        user?.permissions?.includes(ViewPrograms) &&
        user?.permissions?.includes(ViewReports)
      ) ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={12}
            sx={{
              p: 3,
              width: "50%",
            }}
          >
            <Typography sx={{ mb: 4 }}>
              Partners who work directly with Tacoma students through Beyond the
              Bell and Club B programming see charts and data here.{" "}
              <em>That could be&nbsp;you...</em>
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                href="https://www.tacomaschools.org/departments/community-partnerships"
                endIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                target="_blank"
                rel="noopener noreferrer"
              >
                Connect with the Partnership Office
              </Button>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Grid container spacing={4}>
          {/* MARK: Charts
           */}
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "98vw" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReportSummary
                    status={query?.isLoading}
                    slicerData={slicerData}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ChartActivityTypeCounts
                    status={query?.isLoading}
                    slicerData={slicerData?.activitiesType}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <ChartActivityCounts
                    status={query?.isLoading}
                    slicerData={slicerData?.activities}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ChartTopActivityParticipation
                    status={query?.isLoading}
                    slicerData={
                      slicerData?.activities
                        ? slicerData?.activities
                            .sort((a, b) => {
                              return b.programs - a.programs;
                            })
                            .slice(0, 10)
                        : []
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <ChartLocationCounts
                    status={query?.isLoading}
                    slicerData={slicerData?.locations}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChartProviderCounts
                    status={query?.isLoading}
                    slicerData={slicerData?.providers}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* MARK: Toolbar
           */}
          <Grid item xs={12}>
            <Paper elevation={12} sx={toolbarStyles}>
              <Grid container spacing={2} alignItems="center">
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon icon={faFilter} />
                  <Typography variant="caption" sx={filterLabelStyles}>
                    PROGRAM
                    <br />
                    FILTERS
                  </Typography>
                </Grid>

                {/* School Year */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={selectedSchoolYears ?? []}
                      options={schoolYears ?? []}
                      getOptionLabel={(option) => option ?? ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedSchoolYears(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="School Years"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Provider */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        providers?.filter((p) =>
                          selectedProviderIds?.includes(p.providerID ?? 0)
                        ) ?? []
                      }
                      options={providers ?? []}
                      getOptionLabel={(option) => option.name ?? ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedProviderIds(value.map((v) => v.providerID));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Providers"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Sessions */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        sessions?.filter((s) =>
                          selectedSessionIds?.includes(s.sessionID ?? 0)
                        ) ?? []
                      }
                      options={sessions ?? []}
                      getOptionLabel={(option) => option.name ?? ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedSessionIds(value.map((v) => v.sessionID));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sessions"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Locations */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        locations?.filter((l) =>
                          selectedLocationIds?.includes(l.locationID ?? 0)
                        ) ?? []
                      }
                      options={locations ?? []}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedLocationIds(value.map((v) => v.locationID));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Locations"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Activity Types */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        activityTypes?.filter((a) =>
                          selectedActivityTypeIds?.includes(
                            a.activityTypeID ?? 0
                          )
                        ) ?? []
                      }
                      options={activityTypes ?? []}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedActivityTypeIds(
                          value.map((v) => v.activityTypeID)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Activity Types"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Activities */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        activities?.filter((a) =>
                          selectedActivityIds?.includes(a.activityID ?? 0)
                        ) ?? []
                      }
                      options={activities ?? []}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedActivityIds(value.map((v) => v.activityID));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Activities"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Grades */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        grades?.filter((g) =>
                          selectedGrades?.includes(g.grade ?? 0)
                        ) ?? []
                      }
                      options={grades ?? []}
                      getOptionLabel={(option) =>
                        option ? getGradeName(option.grade) : "Unknown"
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option ? getGradeName(option.grade) : "Unknown"}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedGrades(value.map((v) => v.grade));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Grades"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={
                              option ? getGradeName(option.grade) : "Unknown"
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Genders */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },
                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        genders?.filter((sp) =>
                          selectedGenders?.includes(sp)
                        ) ?? []
                      }
                      options={genders ?? []}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedGenders(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Genders"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Races */}
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      slotProps={{
                        paper: {
                          sx: autoCompletePaperStyles,
                        },
                        clearIndicator: {
                          sx: { color: theme?.palette?.background?.default },
                        },
                      }}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: `${theme?.palette?.background?.default} !important`,
                        },
                        "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root":
                          {
                            color: `${theme?.palette?.background?.default} !important`,
                          },

                        "& .MuiInputBase-root": {
                          background: alpha(
                            theme?.palette?.background?.default,
                            0.1
                          ),
                        },
                      }}
                      multiple
                      disableCloseOnSelect
                      disablePortal
                      disabled={slicerQuery.isLoading}
                      value={
                        races?.filter((r) => selectedRaces?.includes(r)) ?? []
                      }
                      options={races ?? []}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            sx={{ color: theme?.palette?.background?.default }}
                          />
                          {option}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedRaces(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Races"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {slicerQuery.isLoading && (
                                  <CircularProgress size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="contained"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* MARK: Table
           */}
          <Grid item xs={12}>
            <Paper>
              <DataGridPremium
                rows={programs ? programs : []}
                columns={columns}
                pagination
                pageSizeOptions={[10, 25, 50]}
                disableRowSelectionOnClick={true}
                autoHeight
                sx={gridStyles}
                columnHeaderHeight={64}
                getRowId={(row) => row.programId}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
