import { Grid, useTheme } from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import HomeTableLocationProgramCounts from "./HomeTableLocationProgramCounts";
import HomeTableTodaysPrograms from "./HomeTableTodaysPrograms";
import HomeChartGenderRace from "./HomeChartGenderRace";

export default function HomeSiteLead() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HomeTableTodaysPrograms />
        </Grid>
        <Grid item xs={12} md={5}>
          <HomeTableLocationProgramCounts />
        </Grid>
        <Grid item xs={12} md={7}>
          <HomeChartGenderRace />
        </Grid>
      </Grid>
    </>
  );
}
