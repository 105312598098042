import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ScrollToTopOnMount } from "../../services/utilities";
import { useGetSlicers } from "../../services/hooks/partnerReportsHook";
import GlossaryButtonDialog from "../common/GlossaryButtonDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";

export default function Reports() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const ChangeTab = (event, newValue) => {
    navigate(`/reports/${newValue}`);
  };

  const GetSelectedTab = () => {
    const tab = location.pathname
      .replace("/reports/", "")
      .replace("/reports", "");
    if (tab === "") {
      return "demographics"; //setting a default avoids console error if URL is /reports/student and then redirects to full path
    }
    return tab;
  };

  //SLICERS
  const slicers = useGetSlicers();

  //MARK: RENDER
  return (
    <>
      <ScrollToTopOnMount />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h1" sx={{ mr: 2 }}>
            Reports
          </Typography>
          <GlossaryButtonDialog variant="contained" />
        </Grid>
        <Grid item sx={{ textAlign: "right" }}>
          <Typography
            component="h3"
            variant="body2"
            color={theme?.palette?.text?.deemphasized}
          >
            <strong>More Data&nbsp;</strong>
          </Typography>
          <Button
            size="small"
            variant="text"
            href="https://reportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100261"
            target="_blank"
            rel="noopener noreferrer"
          >
            <small>
              State Report Card
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: "4px" }}
              />
            </small>
          </Button>
          <br />
          <Button
            size="small"
            variant="text"
            href="https://www.tacomaschools.org/about/strategic-plan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <small>
              TPS Benchmarks
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: "4px" }}
              />
            </small>
          </Button>
        </Grid>
        {/* <Grid item>
          <GlossaryButtonDialog variant="contained" />
        </Grid> */}
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} xl={12}>
          <Box sx={{ maxWidth: "98vw" }}>
            <Tabs
              onChange={ChangeTab}
              value={GetSelectedTab()}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab value="demographics" label="Demographics" />
              <Tab value="attendance" label="Attendance" />
              <Tab value="enrollment" label="Enrollment" />
              <Tab value="assessments" label="Assessments" />
              <Tab value="marks" label="Marks" />
              <Tab value="discipline" label="Discipline" />
              <Tab value="programs" label="Programs" />
            </Tabs>
            <Divider />
            <br />
            <Outlet context={[slicers]} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
