import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faFaceFrownOpen,
} from "@fortawesome/pro-solid-svg-icons";
import { ScrollToTopOnMount } from "../../services/utilities";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { widowUnmaker } from "../../services/utilities";

export default function Glossary({ term }) {
  const { paramTerm } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  //MARK: TERMS
  const terms = useMemo(() => {
    return [
      // {
      //   name: "",
      //   abbreviation: "",
      //   definition: "",
      // },
      {
        name: "504 Plan",
        abbreviation: "504",
        definition:
          "A 504 Plan is a legal document under Section 504 of the Rehabilitation Act of 1973 in the United States. It outlines accommodations and modifications that must be provided to students with disabilities to ensure they have equal access to education. A 504 Plan is more focused on removing barriers to learning for students with disabilities in a regular classroom setting.",
      },
      {
        name: "Activity",
        abbreviation: "",
        definition:
          "An activity is a category of ELO programs. For instance, a 3-on3 Basketball program would be categorized as a Sports activity.",
      },
      {
        name: "Advaced Placement",
        abbreviation: "AP",
        definition:
          "Advanced Placement is a program created by the College Board that offers college-level curricula and examinations to high school students. American colleges and universities may grant placement and course credit to students who obtain high scores on the examinations.",
      },
      {
        name: "ELO Program",
        abbreviation: "",
        definition:
          "An ELO program is an Expanded Learning Opportunity (ELO) that provides students with opportunities to learn and grow outside of the traditional school day. Examples include summer school programs, after-school tutoring, art classes or sports teams. An ELO may be available before and after school, during school breaks or on weekends. ELO programs may be offered by schools, or community-based organizations serving as partners or providers. ELO offerings are branded as 'Beyond the Bell' for elementary students and 'Club B' for middle schoolers.",
      },
      {
        name: "English Language Arts",
        abbreviation: "ELA",
        definition:
          "English Language Arts is the study and improvement of reading, writing, speaking, and listening skills in the English language.",
      },
      {
        name: "Exclusionary Discipline",
        abbreviation: "",
        definition:
          "Exclusionary discipline refers to disciplinary actions that remove students from the school environment such as suspensions and expulsions.",
      },
      {
        name: "Expanded Learning Opportunity",
        abbreviation: "ELO",
        definition:
          "An ELO is a program that provides students with opportunities to learn and grow outside of the traditional school day. ELOs can take place before or after school, on weekends, or during the summer. They can be offered by schools, community-based organizations, or other partners.",
      },
      {
        name: "Foster Care",
        abbreviation: "",
        definition:
          "Foster care refers to a system in which children are placed in the temporary care of individuals or families (foster parents) when they cannot live with their biological parents.",
      },
      {
        name: "Highly Capable",
        abbreviation: "HC",
        definition:
          "Our HC Program provides services to students who perform or show potential for performing at significantly advanced academic levels when compared with others of their age, experiences, or environments.",
      },
      {
        name: "i-Ready",
        abbreviation: "",
        definition:
          "i-Ready is a web-based adaptive diagnostic assessment and instruction program. i-Ready assesses students' reading skills to the sub-domain level, prescribing differentiated Common Core instruction so learners of all abilities can achieve success.",
      },
      {
        name: "Indian Education",
        abbreviation: "",
        definition:
          "Indian Education refers to programs and initiatives aimed at addressing the unique educational needs and challenges faced by Native American and Alaska Native students. These programs often focus on preserving and promoting the cultural heritage of Indigenous communities while providing educational opportunities that meet the academic needs of Native American students.",
      },
      {
        name: "McKinney-Vento",
        abbreviation: "MV",
        definition:
          "The McKinney-Vento Homeless Assistance Act is a federal law in the United States that addresses the educational rights and support for homeless children and youth. It ensures that these students have access to a free, appropriate public education despite their housing situation. The act provides guidelines for school districts to identify and support homeless students, including those living in shelters, hotels, cars, or temporarily with others.",
      },
      {
        name: "Multilingual Learners",
        abbreviation: "ML",
        definition:
          "An Multilingual Learner is a student whose first language is not English and is in the process of acquiring proficiency in English. ML students may receive specialized support and instruction to help them develop their English language skills while participating in academic content. Similar programs may be referred to as ELL or ESL.",
      },
      {
        name: "Participant",
        abbreviation: "",
        definition:
          "Each time students are enrolled in ELO programs, they are considered participants. For instance, if Andre is registered for three programs, we count that as one student and three participants.",
      },
      {
        name: "Partner",
        abbreviation: "",
        definition:
          "Generally, partners are all organizations that collaborate with Tacoma Public Schools to help students. Within the Partner Portal app, Partner is a specific, technical term that refers to an organization who manages other orgs which provide ELO programs. (For instance, Metro Parks is a partner who manages multiple providers.)",
      },
      {
        name: "Program",
        abbreviation: "",
        definition:
          "A program provides students with opportunities to learn and grow outside of the traditional school day. Expanded Learning Opportunity (ELO) programs can take place before or after school, on weekends, or during the summer. They can be offered by schools, community-based organizations, or other partners. They occur during a session, have start and end dates, and have a group of participants whose attedance is tracked in this app.  ELO offerings are branded as 'Beyond the Bell' for elementary students and 'Club B' for middle schoolers.",
      },
      {
        name: "Provider",
        abbreviation: "",
        definition:
          "An organization that provides ELO programs to students. Providers are managed by Partners. (For instance, YMCA is a provider that offers ELO programs to students and is managed by Metro Parks.)",
      },
      {
        name: "Unaccompanied Youth",
        abbreviation: "",
        definition:
          "Unaccompanied Youth refers to students who are not in the physical custody of a parent or guardian. In an educational context, this term is often associated with students who are homeless and not living with their families. Unaccompanied youth may face unique challenges, and the McKinney-Vento Act provides specific provisions to support their access to education and related services.",
      },
      {
        name: "Smarter Balanced Assessment",
        abbreviation: "SBA",
        definition:
          "The Smarter Balanced Assessment is a state test that measures student proficiency in English Language Arts (ELA) and Math. The test is administered to students in grades 3-8 and 10.",
      },
      {
        name: "Special Education",
        abbreviation: "SPED",
        definition:
          "Special Education involves the provision of tailored instructional programs and services to meet the unique learning needs of students with disabilities. These services are designed to help students with disabilities access the curriculum and participate in school activities. Special Education is governed by federal laws, including the Individuals with Disabilities Education Act (IDEA), which mandates the creation of Individualized Education Programs (IEPs) for eligible students.",
      },
    ];
  }, []);

  const [selectedTerm, setSelectedTerm] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(terms);

  //handle term in URL
  useEffect(() => {
    if (paramTerm) {
      setSelectedTerm(paramTerm);
    } else {
      setSelectedTerm(null);
    }
  }, [paramTerm]);

  //handle term from props
  useEffect(() => {
    if (term) {
      setSelectedTerm(term);
    }
  }, [term]);

  //handle search
  useEffect(() => {
    setSearchResults(
      terms.filter((term) =>
        !selectedTerm
          ? term.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            term.abbreviation
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            term.definition.toLowerCase().includes(searchQuery.toLowerCase())
          : term.name.toLowerCase() === selectedTerm.toLowerCase()
      )
    );
  }, [searchQuery, selectedTerm, terms]);

  //#region STYLES
  const toolbarStyles = {
    p: 2,
    borderRadius: 0,
    background: theme?.palette?.primary?.main,
    " & .MuiFormLabel-root": {
      color: alpha(theme?.palette?.background?.default, 0.8),
    },
    " & .MuiInputLabel-shrink": {
      color: theme?.palette?.background?.default,
    },
    "& .MuiInputBase-root input": {
      color: theme?.palette?.background?.default,
    },
    "& .MuiInputBase-root svg": {
      color: theme?.palette?.background?.default,
    },
  };
  //#endregion

  //MARK: RENDER
  return (
    <>
      <ScrollToTopOnMount />
      <Container sx={{ mt: 1, mb: 3 }}>
        <Typography variant="h1" gutterBottom>
          Glossary
        </Typography>
        <Paper>
          {!selectedTerm && (
            <Paper elevation={3} sx={toolbarStyles}>
              <FormControl>
                <TextField
                  value={searchQuery}
                  id="glossary-search"
                  label="Search"
                  variant="outlined"
                  color="primaryAlt"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                      ),
                    },
                  }}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </FormControl>
            </Paper>
          )}
          <List>
            {searchResults?.length === 0 ? (
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faFaceFrownOpen}
                  size="6x"
                  color={theme?.palette?.text?.deemphasized}
                />
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ mt: 2, color: theme?.palette?.text?.deemphasized }}
                >
                  Sorry
                </Typography>
                <Typography sx={{ color: theme?.palette?.text?.deemphasized }}>
                  We didn't find any results for{" "}
                  <strong>
                    <em>{selectedTerm ?? searchQuery}</em>
                  </strong>
                  .
                </Typography>
              </Box>
            ) : (
              searchResults?.map((term, index) => (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <strong>
                          {term.name}
                          {term.abbreviation && ` (${term.abbreviation})`}
                        </strong>
                      }
                      secondary={widowUnmaker(term.definition)}
                    />
                  </ListItem>
                  {searchResults?.length !== index + 1 && <Divider />}
                </div>
              ))
            )}
          </List>
        </Paper>
        {!!selectedTerm && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={() => navigate("/resources/glossary")}
            >
              Go to the full Glossary
            </Button>
          </Box>
        )}
      </Container>
    </>
  );
}
