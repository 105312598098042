import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import {
  GetAccessToken,
  AppsGet,
  AppsPut,
  AppsPost,
  AppsPatch,
} from "../apiService";

export function useGetPartnerHomeCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetPartnerHomeCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetMyInstructedPrograms() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetMyInstructedPrograms`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetProgramsForToday() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetProgramsForToday`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetActivityTypeCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetActivityTypeCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetGenderRaceCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetGenderRaceCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetSessionYearCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetSessionYearCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetSpecialProgramCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetSpecialProgramCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetLocationProgramCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetLocationProgramCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetPartnerProgramCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetPartnerProgramCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetProviderProgramCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetProviderProgramCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetPartnerHomeCurrentProgramCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetCurrentProgramCounts`;
  const query = useQuery(
    url,
    ({ signal }) => {
      return AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}

export function useGetDsaHomeDataCounts() {
  const token = GetAccessToken();
  const url = `PartnerHome/GetDSAHomeDataCounts`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGet(token, url, signal);
    },
    { cacheTime: 1000 * 60 * 10 } //10 mins
  );
  return query;
}
