import { useState, useEffect } from "react";
import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import EChartsReact from "echarts-for-react";

export default function ChartTopActivityParticipation({ status, slicerData }) {
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const [countSort, setCountSort] = useState(true);
  const [height, setHeight] = useState(320);

  const data = slicerData?.sort((a, b) => {
    if (countSort) {
      return a.programs - b.programs;
    }
    return b.name.localeCompare(a.name);
  });

  useEffect(() => {
    const items = data?.length > 0 ? data?.length : 10;
    setHeight(160 + items * 16);
  }, [data]);

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    grid: [
      {
        left: 210,
        right: 10,
        height: "auto",
        top: 20,
      },
    ],
    yAxis: {
      type: "category",
      data: data?.map((d) => d.name),
      minInterval: 1,
      axisLabel: {
        overflow: "truncate",
        ellipsis: "...",
        width: 200,
      },
    },
    xAxis: {
      type: "value",
    },
    series: [
      {
        data: data?.map((d) => d.programs),
        type: "bar",
        color: status
          ? theme.palette.text.secondary
          : theme.palette.chart && theme.palette.chart[0],
      },
    ],
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Top Activity Participation
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Alphabetical</Typography>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={countSort}
                onChange={(e) => {
                  setCountSort(e.target.checked);
                }}
              />
            }
          />
          <Typography>Count</Typography>
        </Stack>
      </Stack>

      {data?.length > 0 ? (
        <EChartsReact option={chartOptions} style={{ height: `${height}px` }} />
      ) : (
        <Typography
          variant="caption"
          component="div"
          sx={{ textAlign: "center" }}
        >
          <br />
          <em>&mdash;&nbsp;No&nbsp;participants&nbsp;&mdash;</em>
          <br />
        </Typography>
      )}
    </Paper>
  );
}
