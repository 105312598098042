import { useQuery, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsGetImage, AppsPost } from "../apiService";

export function useStudentPhoto(studentId) {
  const token = GetAccessToken();
  const url = `StudentPhoto/${studentId}`;
  const query = useQuery(
    url,
    async ({ signal }) => {
      return await AppsGetImage(token, url, signal);
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour
      retry: 5,
    }
  );
  return query;
}
