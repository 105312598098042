import { useQuery } from "react-query";
import { GetAccessToken, AppsGet } from "../apiService";

//Get Locations
export function useGetLocations() {
  const token = GetAccessToken();
  const url = "location/GetPartnerLocations";

  const query = useQuery(url, async ({ signal }) => {
    return await AppsGet(token, url, signal);
  });

  return query;
}

//Get User Locations for Roster
export function useGetUserRosterLocations(date) {
  const token = GetAccessToken();
  const url = `location/GetPartnerRosterLocations/${date}`;
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });
  return query;
}
