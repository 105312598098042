import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentDemographics({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const chartOptionsGenderRace = useMemo(() => {
    const groupedData = {};
    data.forEach((item) => {
      const gender = item.gender;
      const race = item.race;
      if (!groupedData[race]) {
        groupedData[race] = { F: 0, M: 0, X: 0 };
      }
      groupedData[race][gender] += 1;
    });

    const races = Object.keys(groupedData).sort();
    const genders = ["F", "M", "X"];
    const series = genders.map((gender, i) => ({
      name: gender,
      type: "bar",
      stack: "total",
      label: {
        show: true,
        formatter: (params) => (params.value === 0 ? "" : params.value),
      },
      emphasis: {
        focus: "series",
      },
      data: races.map((race) => groupedData[race][gender] || 0),
      color: theme?.palette?.chart && theme?.palette?.chart[i],
    }));

    const chartOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: races,
      },
      yAxis: {
        type: "value",
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        textStyle: {
          color: theme?.palette?.text?.secondary,
        },
      },
      series,
    };

    return chartOptions;
  }, [data, theme]);

  const chartOptionsGrade = useMemo(() => {
    const groupedData = {};
    data.forEach((item) => {
      const grade = item.grade.toString();
      if (!groupedData[grade]) {
        groupedData[grade] = 0;
      }
      groupedData[grade] += 1;
    });

    const grades = Object.keys(groupedData).sort();
    const chartOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: grades,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Count",
          type: "bar",
          data: grades.map((grade) => groupedData[grade] || 0),
          itemStyle: {
            color: (params) =>
              theme?.palette?.chart[
                params.dataIndex % theme?.palette?.chart?.length
              ],
          },
        },
      ],
    };

    return chartOptions;
  }, [data, theme]);

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h6" color={theme?.palette?.text?.secondary}>
            Students by Gender/Race
          </Typography>
          <EChartsReact option={chartOptionsGenderRace} />
          <Typography variant="h6" color={theme?.palette?.text?.secondary}>
            Students by Grade
          </Typography>
          <EChartsReact option={chartOptionsGrade} />
        </>
      )}
    </Paper>
  );
}
