import { Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import HomeTableMyInstructedPrograms from "./HomeTableMyInstructedPrograms";

export default function HomeInstructor() {
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();

  return <HomeTableMyInstructedPrograms />;
}
