import React, { useState, useMemo } from "react";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useGetUsers } from "../../services/hooks/personHook";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Error from "../common/Error";
import { ScrollToTopOnMount } from "../../services/utilities";

import { getComparator, stableSort } from "../../services/tableFunctions";
import { ManageUsers } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";

export default function Users() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { user } = useUser();
  const navigate = useNavigate();

  const [showInactive, setShowInactive] = useLocalStorage(
    "userListShowInactive",
    false
  );

  const filterUsers = (progs, search) => {
    let copyUsers = progs?.data?.data;

    if (!showInactive) {
      copyUsers = copyUsers?.filter((user) => user.active);
    }

    if (search?.length > 0) {
      copyUsers = copyUsers?.filter((user) => {
        return (
          user.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
          user.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
          user.partnerProviderName
            ?.toLowerCase()
            .includes(search?.toLowerCase())
        );
      });
    }

    copyUsers?.forEach((u) => {
      if (u.role?.startsWith("TPS") && u.partnerProviderName !== null)
        u.partnerProviderName = "TPS";
    });

    return copyUsers;
  };

  const usersQuery = useGetUsers();
  const [searchInput, setSearchInput] = useState("");
  const filteredUsers = useMemo(
    () => filterUsers(usersQuery, searchInput),
    [usersQuery, searchInput]
  );

  //table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "UserListRowsPerPage",
    10
  );

  const tableHeadCells = [
    {
      id: "firstName",
      numeric: false,
      label: "First",
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last",
    },
    {
      id: "lastLoginDate",
      numeric: false,
      label: "Last Login",
    },
    {
      id: "role",
      numeric: false,
      label: "Role",
    },
    {
      id: "partnerProviderName",
      numeric: false,
      label: "Org",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {tableHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography>{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Styles
  // const mdScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const commonPaddingStyles = {
    padding: theme.spacing(2),
  };
  // const programTableStyles = {
  //   "& .MuiTableCell-root": { p: mdScreen ? "0.5rem" : "1rem" },
  // };
  const programTitleStyles = {
    fontSize: "1.2rem",
    fontWeight: "bold",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h1">Users</Typography>
        </Grid>
        {user?.permissions?.includes(ManageUsers) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/users/create");
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Add User
            </Button>
          </Grid>
        )}
      </Grid>

      <br />

      <Paper>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={commonStyles.paperPadding}
        >
          {/* Search bar */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                value={searchInput}
                id="program-search"
                label="Search"
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                }}
              />
            </FormControl>
          </Grid>
          {/* Active Filter */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={showInactive}
                  onChange={(e) => {
                    setShowInactive(e.target.checked);
                    setPage(0);
                  }}
                />
              }
              label="Show Inactive"
            />
          </Grid>
        </Grid>

        <Error
          message={"There was a problem loading programs."}
          query={usersQuery}
        />

        <>
          <TableContainer>
            <Table
              aria-label="program table"
              size="small"
              sx={commonStyles.tableStyles}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredUsers?.length ?? 0}
              />

              <TableBody>
                {usersQuery.isSuccess && filteredUsers?.length == 0 && (
                  <TableRow>
                    <TableCell colSpan="8">
                      <Typography
                        sx={{
                          m: theme.spacing(2),
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <strong>No programs found</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {usersQuery.isLoading &&
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan="8">
                          <Skeleton variant="rect" height={30} />
                        </TableCell>
                      </TableRow>
                    ))}
                {filteredUsers &&
                  stableSort(filteredUsers, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((person, index) => (
                      <TableRow
                        key={index}
                        hover={true}
                        sx={{
                          cursor:
                            user?.permissions?.includes(ManageUsers) &&
                            "pointer",
                          backgroundColor:
                            person.active == false &&
                            theme.palette.background.default,
                        }}
                        onClick={() => {
                          if (user?.permissions?.includes(ManageUsers)) {
                            navigate(`/users/edit/${person.personId}`);
                          }
                        }}
                      >
                        <TableCell>
                          <Typography>{person.firstName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{person.lastName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {person.lastLoginDate
                              ? dayjs(person.lastLoginDate).format(
                                  "YYYY-MM-DD hh:mma"
                                )
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {person.role ? (
                            <Typography>{person.role}</Typography>
                          ) : (
                            person.active && (
                              <Chip color="error" label="Role Missing" />
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>{person.partnerProviderName}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={filteredUsers?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </>
  );
}
