import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import YouTubeVideo from "../common/YouTubeVideo";

export default function Sel() {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h1">
        Social Emotional Learning Signature Practices
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: theme.spacing(2) }}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Aligning Practices
                </Typography>
                <br />
                <Typography variant="body1" color="textSecondary" component="p">
                  Aligning and implementing Signature Whole Child Practices
                  during the school day and in the ELO space creates the process
                  through which children and adults can acquire and effectively
                  apply the knowledge, attitudes, and skills necessary to
                  understand and manage emotions, set and achieve positive
                  goals, feel and show empathy for others, establish and
                  maintain positive relationships, and make responsible
                  decisions" –{" "}
                  <a
                    href="https://casel.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CASEL.org
                  </a>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <YouTubeVideo id="P-w9sX_TOLk" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardActionArea
                    href="https://rise.articulate.com/share/ZKffhmHHWjrW1_rns9yFEq7JUNMdKYOo#/lessons/JNI90MOzYzcSQ5Fv8xuArhD25GSsoJ1P"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      image="/img/warmgreeting.jpg"
                      title="Warm Greeting"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Warm Greeting
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Greeting youth with enthusiasm, warmth, and care at the
                        start of a session is one powerful way to promote a
                        sense of welcome, acceptance, and belonging.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      href="https://rise.articulate.com/share/ZKffhmHHWjrW1_rns9yFEq7JUNMdKYOo#/lessons/JNI90MOzYzcSQ5Fv8xuArhD25GSsoJ1P"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardActionArea
                    href="https://rise.articulate.com/share/z8tH_Oopfr2Sd2x-WEIYgxhS2VkomqmN#/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      image="/img/emotioncheckin.jpg"
                      title="Emotion Check-in"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Emotion Check-in
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Adults ask all youth to name or describe their emotions
                        or identify the intensity of their emotions.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      href="https://rise.articulate.com/share/z8tH_Oopfr2Sd2x-WEIYgxhS2VkomqmN#/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardActionArea
                    href="https://rise.articulate.com/share/ue9bYm-eSlSftfgpQ7pWAZmMwQRBx_rs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CardMedia
                      image="/img/communitycircles.jpg"
                      title="Community Circles"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Community Circles
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Group Circles support building community and consider
                        the needs of each member to intentionally provide access
                        and equity to the activities within the circle.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      href="https://rise.articulate.com/share/ue9bYm-eSlSftfgpQ7pWAZmMwQRBx_rs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography gutterBottom variant="h5" component="h2">
            The Whole Child
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Tacoma Whole Child program is committed to continuous improvement
            efforts to proactively support the social, emotional, and academic
            excellence of our youth.
          </Typography>
          <Button
            size="small"
            color="primary"
            href="https://www.tacomaschools.org/departments/tacoma-whole-child"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
