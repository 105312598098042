import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetRestrictions() {
  const token = GetAccessToken();
  const url = `Restrictions/GetRestrictions`;
  const query = useQuery(url, ({ signal }) => {
    return AppsGet(token, url, signal);
  });
  return query;
}
