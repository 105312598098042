import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetParticipantAttendanceByProgramDayId(programDayId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `ProgramAttendances/GetParticipantAttendanceByProgramDayId/${programDayId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (programDayId == null) return Promise.resolve();

      return AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting attendances for programDayId ${programDayId}. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return query;
}

//Post Program Attendance
export function useBatchMergeProgramAttendance(attendances) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "ProgramAttendances/BatchMergeProgramAttendance";
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      await AppsPost(token, url, attendances);
    },
    {
      onSuccess: async () => {
        // queryClient.queryCache.clear();
        await queryClient.invalidateQueries((key) =>
          key.startsWith("ProgramAttendances")
        );
        snackbar.enqueueSnackbar(`Attendance saved`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving attendance. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
