import React from "react";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { alpha } from "@mui/material/styles";
import TableTips from "../common/TableTips";

export default function ReportsToolbar({
  filterControls,
  filterModel,
  onFilterModelChange,
  isLoading,
}) {
  const theme = useTheme();

  // Handle filter changes from Autocomplete components
  const handleFilterChange = (value, control) => {
    const fieldsToUpdate = filterControls.map((fc) => fc.field.toLowerCase());

    const existingFilteredItems = filterModel?.items.filter(
      (item) => !fieldsToUpdate.includes(item.field.toLowerCase())
    );

    // Update the selected values through the control's setter
    control.setSelectedValues(value);

    // Create new filters array from all controls
    const newFilters = filterControls
      .filter((fc) => {
        // For the current control, use the new value
        if (fc.id === control.id) {
          return value.length > 0;
        }
        // For other controls, use their current selected values
        return fc.selectedValues?.length > 0;
      })
      .map((fc) => ({
        field: fc.field,
        operator: "isAnyOf",
        id: `${fc.id}-filter`,
        value: fc.id === control.id ? value : fc.selectedValues,
      }));

    const newFilterModel = {
      ...filterModel,
      items: [...existingFilteredItems, ...newFilters],
    };

    onFilterModelChange(newFilterModel);
  };

  //#region STYLES
  const toolbarStyles = {
    borderRadius: 0,
    background: theme?.palette?.text?.primary,
    color: alpha(theme?.palette?.background?.default, 0.8),
    overflow: "visible",
    "& .MuiButton-root": {
      color: !theme?.palette?.primaryAlt
        ? theme?.palette?.primary?.main
        : theme?.palette?.primaryAlt?.light,
    },
    "& .MuiInputBase-root input": {
      color: theme?.palette?.background?.default,
    },
    "& .MuiAutocomplete-endAdornment": {
      color: `${theme?.palette?.background?.default} !important`,
    },
  };
  const filterLabelStyles = {
    lineHeight: "0.8rem",
    p: 0,
    m: 0,
    ml: 1,
  };
  const autoCompletePaperStyles = {
    background: theme?.palette?.text?.primary,
    color: theme?.palette?.background?.default,
  };
  //#endregion

  //MARK: RENDER
  return (
    <Paper elevation={12} sx={toolbarStyles}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ display: "flex" }}
      >
        <Grid item>
          <GridToolbarContainer sx={{ width: "100%" }}>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <TableTips />
          </GridToolbarContainer>
        </Grid>
        {/* <Grid item>
          <TableTips />
        </Grid> */}
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ p: 1 }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon={faFilter} />
              <Typography variant="caption" sx={filterLabelStyles}>
                QUICK
                <br />
                FILTERS
              </Typography>
            </Grid>
            {filterControls.map((fc) => (
              <Grid item xs={2} key={fc?.id}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    slotProps={{
                      paper: {
                        sx: autoCompletePaperStyles,
                      },
                      clearIndicator: {
                        sx: { color: theme?.palette?.background?.default },
                      },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: `${theme?.palette?.background?.default} !important`,
                      },
                      "& .MuiAutocomplete-endAdornment > .MuiButtonBase-root": {
                        color: `${theme?.palette?.background?.default} !important`,
                      },
                      "& .MuiInputBase-root": {
                        background: alpha(
                          theme?.palette?.background?.default,
                          0.1
                        ),
                      },
                    }}
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    disabled={isLoading}
                    value={fc?.options?.filter(fc?.slicerFilterFn) ?? []}
                    options={fc?.options ?? []}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                          sx={{ color: theme?.palette?.background?.default }}
                        />
                        {option}
                      </li>
                    )}
                    onChange={(event, value) => handleFilterChange(value, fc)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={fc?.label}
                        slotProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoading && <CircularProgress size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          //   sx: {
                          //     ...params.InputProps.sx,
                          //     "& input": {
                          //       background: "red !important",
                          //     },
                          //   },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          size="small"
                          color="primary"
                          variant="contained"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
