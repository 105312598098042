import dayjs from "dayjs";
import { useEffect } from "react";

export const maxWidth = 1800;

export const formatDate = (date) => {
  return dayjs(date).format("M/DD/YY");
};

export const formatDateOnly = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const formatTime = (time) => {
  let today = dayjs().format("M/DD/YY");
  return dayjs(today + " " + time).format("h:mm A");
};

export const formatDateTime = (dateTime) => {
  return dayjs(dateTime).format("M/DD/YY h:mm A");
};

export const getTimeRange = (startTime, durationHours) =>
  dayjs(dayjs().format("YYYY-MM-DD") + " " + startTime).format("h:mma") +
  " - " +
  dayjs(dayjs().format("YYYY-MM-DD") + " " + startTime)
    .add(durationHours, "hour")
    .format("h:mma");

export const decodeHtml = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const validateEmail = (email) => {
  const regExPattern =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  // RFC 5322 compliant, from https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  if (email.match(regExPattern)) {
    return true;
  } else {
    return false;
  }
};

export const formatPhoneNumber = (phoneNumberString, nonBreaking) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    if (!!nonBreaking) {
      return (
        <span>
          ({match[1]})&nbsp;{match[2]}&#8209;{match[3]}
        </span>
      );
    }
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const widowUnmaker = (text) => {
  text = text?.trim();
  const lastSpace = text?.lastIndexOf(" ");
  return (
    <span>
      {text?.substring(0, lastSpace)}&nbsp;{text?.substring(lastSpace + 1)}
    </span>
  );
};

export const getSchoolYear = (date = new Date()) =>
  new Date(date).getMonth() >= 8
    ? new Date(date).getFullYear() + 1
    : new Date(date).getFullYear();

export const titleCase = (str) => {
  if (!!str && str?.toUpperCase() === str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else return str;
};

export const getGradeName = (grade) => {
  //"09" becomes "9th Grade"
  switch (grade.trim()) {
    case "B2":
      return "Birth to 3";
    case "B3":
      return "Birth to 3";
    case "PS":
    case "-01":
      return "Preschool";
    case "PP":
      return "Preschool (pre-registered)";
    case "PK":
      return "Pre-Kindergarten";
    case "TK":
      return "Transitional Kindergarten";
    case "K":
    case "K2":
    case "K3":
    case "00":
      return "Kindergarten";
    case "KP":
      return "Kindergarten (pre-registered)";

    default:
      //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
      let i = grade?.replace(/^0+/, "");

      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st Grade";
      }
      if (j === 2 && k !== 12) {
        return i + "nd Grade";
      }
      if (j === 3 && k !== 13) {
        return i + "rd Grade";
      }
      return i + "th Grade";
  }
};

export const checkmarkify = (val) => {
  const value = val?.toString().toLowerCase().trim();
  if (value === "y" || value === "yes" || value === "true" || value === "1") {
    return <span>&#x2714;</span>;
  }
  return "";
};

//https://v5.reactrouter.com/web/guides/scroll-restoration
export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
