import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormGroup,
  FormControlLabel,
  Link,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { useGetRoster } from "../../services/hooks/participantsHook";
import { useUser } from "../../services/contexts/userContext";
import { useGetUserRosterLocations } from "../../services/hooks/locationHook";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getComparator,
  stableSort,
  EnhancedTableHead,
} from "../../services/tableFunctions";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import ParticipantDetails from "../common/ParticipantDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessageMedical,
  faCircleExclamation,
  faCameraSlash,
} from "@fortawesome/pro-solid-svg-icons";
import {
  formatPhoneNumber,
  getGradeName,
  ScrollToTopOnMount,
} from "../../services/utilities";
import useSessionStorage from "../../services/hooks/useSessionStorage";
import Error from "../common/Error";
import { TakeAttendance } from "../../services/permissions";
import { useEffect } from "react";
import { useBatchMergeProgramAttendance } from "../../services/hooks/programAttendancesHook";
import useLocalStorage from "../../services/hooks/useLocalStorage";

export default function Roster() {
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //Styles
  const iconStyles = { pr: "0.25rem" };

  //params
  const [selectedDate, setSelectedDate] = useSessionStorage(
    "rosterSelectedDate",
    dayjs()
  );
  const [selectedLocationIds, setSelectedLocationIds] = useSessionStorage(
    "rosterLocationIDs",
    []
  );

  //states
  const [takingAttendance, setTakingAttendance] = useSessionStorage(
    "rosterTakeAttendance",
    false
  );
  const [searchInput, setSearchInput] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("lastName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "rosterRowsPerPage",
    10
  );
  const [inspectParticipant, setInspectParticipant] = useState(null);

  const [showSchool, setShowSchool] = useLocalStorage(
    "rosterShowSchool",
    false
  );
  const [showTshirt, setShowTshirt] = useLocalStorage(
    "rosterShowTshirt",
    false
  );
  const [showTransportation, setShowTransportation] = useLocalStorage(
    "rosterShowTransportation",
    true
  );
  const [showContacts, setShowContacts] = useLocalStorage(
    "rosterShowContacts",
    false
  );
  const [showEmails, setShowEmails] = useLocalStorage(
    "rosterShowEmails",
    false
  );

  //data
  const rosterQuery = useGetRoster(
    dayjs(selectedDate).format("YYYY-MM-DD"),
    selectedLocationIds
  );
  const rosterData = rosterQuery?.data?.data ?? [];
  const rosterRows = useMemo(() => {
    if (rosterData) {
      return rosterData?.map((participant) => {
        let participantData = {};
        participant.programs.forEach((program) => {
          participantData = {
            studentId: participant.studentId,
            firstName: participant.firstName,
            lastName: participant.lastName,
            grade: participant.grade,
            school: participant.locationNameShort,
            hasMedicalAlerts: participant.hasMedicalAlerts,
            photoOptOut: participant.photoOptOut,
            restrainingProtectionNote: participant.restrainingProtectionNote,
            teeShirtSize: participant.teeShirtSize,
            contacts: participant.emergencyContacts,
            attended: program.attended,
            entryDate: program.entryDate,
            LocationID: program.LocationID,
            participantID: program.participantID,
            programAttendanceID: program.programAttendanceID,
            programDayId: program.programDayId,
            programId: program.programId,
            startTime: program.startTime,
            title: program.title,
            transportation: program.transportation,
          };
          return participantData;
        });
        return participantData;
      });
    }
  }, [rosterData]);

  const roster = useMemo(() => {
    return rosterRows?.filter((p) => {
      let valid = true;
      if (
        takingAttendance === false &&
        searchInput.length > 0 &&
        !p.firstName.toLowerCase().includes(searchInput.toLowerCase()) &&
        !p.lastName.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        valid = false;
      }

      return valid;
    });
  }, [rosterRows, searchInput, takingAttendance]);

  const [attendance, setAttendance] = useState();
  useEffect(() => {
    if (rosterData) {
      let prog = [];
      rosterData?.map((r) => {
        return r.programs?.map((p) => {
          return (prog = [
            ...prog,
            {
              programDayId: p.programDayId,
              participantId: p.participantID,
              programId: p.programId,
              scheduledDate: dayjs(selectedDate).format("YYYY-MM-DD"),
              startTime: p.startTime,
              attended: p.attended ?? false,
              programAttendanceId: p.programAttendanceID ?? 0,
            },
          ]);
        });
      });
      setAttendance(prog);
    }
  }, [rosterData, selectedDate]);

  const defaultColumns = useMemo(() => {
    return [
      {
        id: "lastName",
        numeric: false,
        label: "Name",
      },
      {
        id: "grade",
        numeric: false,
        label: "Grade",
      },
      {
        id: "title",
        numeric: false,
        label: "Program",
      },
    ];
  }, []);
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    if (takingAttendance) {
      setColumns(defaultColumns);
    }
  }, [takingAttendance, defaultColumns]);

  useEffect(() => {
    let cols = [...defaultColumns];
    if (showSchool && !takingAttendance) {
      cols.push({
        id: "school",
        numeric: false,
        label: "School",
      });
    }
    if (showTransportation && !takingAttendance) {
      cols.push({
        id: "transportation",
        numeric: false,
        label: "Transportation",
      });
    }
    if (showTshirt && !takingAttendance) {
      cols.push({
        id: "teeShirtSize",
        numeric: false,
        label: "T-Shirt Size",
      });
    }
    if (showContacts && !takingAttendance) {
      cols.push({
        id: "contacts",
        numeric: false,
        label: "Contacts",
        noSort: true,
      });
    }
    setColumns(cols);
  }, [
    takingAttendance,
    defaultColumns,
    showSchool,
    showTransportation,
    showTshirt,
    showContacts,
  ]);

  const UpdateAttendanceRecord = (participantId, attended) => {
    setAttendance((prevAttendance) => {
      return prevAttendance?.map((a) => {
        if (a.participantId === participantId) {
          return {
            ...a,
            attended,
          };
        }
        return a;
      });
    });
  };

  const MergeAttendance = useBatchMergeProgramAttendance(attendance);
  const SubmitAttendance = () => {
    MergeAttendance.mutateAsync();
  };

  const locationsQuery = useGetUserRosterLocations(
    dayjs(selectedDate).format("YYYY-MM-DD")
  );
  const locations = locationsQuery?.data?.data.sort((a, b) => {
    if (a?.LocationName.toUpperCase() < b?.LocationName.toUpperCase()) {
      return -1;
    } else if (a?.LocationName.toUpperCase() > b?.LocationName.toUpperCase()) {
      return 1;
    } else {
      return 0;
    }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSwitches = (event) => {
    switch (event.target.name) {
      case "school":
        setShowSchool(event.target.checked);
        break;
      case "tshirt":
        setShowTshirt(event.target.checked);
        break;
      case "transportation":
        setShowTransportation(event.target.checked);
        break;
      case "contacts":
        setShowContacts(event.target.checked);
        break;
      case "email":
        setShowEmails(event.target.checked);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ScrollToTopOnMount />

      <Typography variant="h1">Roster</Typography>

      {/* Participant details dialog */}
      {inspectParticipant && (
        <ParticipantDetails
          participantId={inspectParticipant?.participantID}
          onClose={() => {
            setInspectParticipant(null);
          }}
        />
      )}

      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ my: theme.spacing(2) }}
      >
        {user?.permissions?.includes(TakeAttendance) && (
          <Grid item>
            <Button
              variant={takingAttendance ? "outlined" : "contained"}
              onClick={() => {
                setTakingAttendance(!takingAttendance);
              }}
              disabled={dayjs(selectedDate).isAfter(dayjs())}
            >
              {takingAttendance ? "Stop Taking Attendance" : "Take Attendance"}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Scheduled Date"
              format="MM/DD/YY"
              value={dayjs(selectedDate)}
              onChange={(value) => {
                setSelectedDate(value);
                setTakingAttendance(false);
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Scheduled Date",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Error
            message="There was an issue loading locations."
            query={locationsQuery}
          />
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disablePortal
              options={locations ?? []}
              disableCloseOnSelect
              disabled={locationsQuery?.isLoading}
              value={
                locations?.filter((l) =>
                  selectedLocationIds?.includes(l.LocationID)
                ) ?? []
              }
              getOptionLabel={(option) => option.LocationName}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option.LocationName}
                </li>
              )}
              onChange={(event, value) => {
                setSelectedLocationIds(value.map((v) => v.LocationID ?? v));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Locations" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    variant="contained"
                    label={
                      locations?.find((l) => l.LocationID === option.LocationID)
                        ?.LocationName
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Paper>
        <Box sx={commonStyles.paperPadding}>
          {takingAttendance === false && (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} md={3} xl={2}>
                <FormControl fullWidth>
                  <TextField
                    value={searchInput}
                    id="student-name-search"
                    label="Search"
                    variant="outlined"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} md={9} xl={10} flexDirection="row">
                <FormGroup sx={{ display: "inline-block", ml: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="school"
                        checked={showSchool}
                        onChange={handleSwitches}
                        inputProps={{ "aria-label": "controlled" }}
                        color="secondary"
                      />
                    }
                    label="Show School"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="transportation"
                        checked={showTransportation}
                        onChange={handleSwitches}
                        inputProps={{ "aria-label": "controlled" }}
                        color="secondary"
                      />
                    }
                    label="Show Transportation"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="tshirt"
                        checked={showTshirt}
                        onChange={handleSwitches}
                        inputProps={{ "aria-label": "controlled" }}
                        color="secondary"
                      />
                    }
                    label="Show T-shirt Size"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="contacts"
                        checked={showContacts}
                        onChange={handleSwitches}
                        inputProps={{ "aria-label": "controlled" }}
                        color="secondary"
                      />
                    }
                    label="Show Contacts"
                  />
                  {!!showContacts && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="email"
                          checked={showEmails}
                          onChange={handleSwitches}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          color="primary"
                        />
                      }
                      label={
                        <Typography component="span" variant="body2">
                          Show Emails
                        </Typography>
                      }
                    />
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Box>
        <Error
          message="There was an issue loading participants."
          query={rosterQuery}
        />
        <TableContainer>
          <Table
            aria-label="program table"
            size="small"
            sx={commonStyles.tableStyles}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={roster?.length ?? 0}
              columns={columns}
            />

            <TableBody>
              {roster?.length === 0 && (
                <TableRow>
                  <TableCell colSpan="8">
                    <Typography
                      sx={{
                        m: theme.spacing(2),
                        color: theme.palette.text.secondary,
                      }}
                    >
                      <strong>No participants found</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {rosterQuery?.isLoading &&
                Array(5)
                  ?.fill(0)
                  ?.map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan="8">
                        <Skeleton variant="rect" height={60} />
                      </TableCell>
                    </TableRow>
                  ))}

              {roster &&
                stableSort(roster, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((participant, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover={true}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setInspectParticipant(participant);
                        }}
                      >
                        <TableCell>
                          <Grid container alignItems="center">
                            <Grid item sx={{ pl: theme.spacing(1) }}>
                              {takingAttendance ? (
                                <FormGroup
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={MergeAttendance.isLoading}
                                        sx={{
                                          py: 0,
                                        }}
                                        checked={
                                          attendance?.find(
                                            (a) =>
                                              a.participantId ===
                                              participant.participantID
                                          )?.attended ?? false
                                        }
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          UpdateAttendanceRecord(
                                            participant.participantID,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontWeight: 700,
                                          color: theme?.palette?.primary?.main,
                                          textDecoration: "none",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                        {participant.lastName},{" "}
                                        {participant.firstName}
                                      </Typography>
                                    }
                                  />
                                </FormGroup>
                              ) : (
                                <Typography
                                  component="span"
                                  sx={{
                                    mr: theme.spacing(1),
                                    fontWeight: 700,
                                    color: theme?.palette?.primary?.main,
                                    textDecoration: "none",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  {participant.lastName},{" "}
                                  {participant.firstName}
                                </Typography>
                              )}
                            </Grid>
                            {participant.hasMedicalAlerts && (
                              <Grid item sx={iconStyles}>
                                <FontAwesomeIcon
                                  color={theme.palette.error.main}
                                  icon={faMessageMedical}
                                />
                              </Grid>
                            )}
                            {!!participant.restrainingProtectionNote && (
                              <Grid item sx={iconStyles}>
                                <FontAwesomeIcon
                                  size="xl"
                                  color={theme.palette.error.main}
                                  icon={faCircleExclamation}
                                />
                              </Grid>
                            )}
                            {participant.photoOptOut && (
                              <Grid item sx={iconStyles}>
                                <FontAwesomeIcon
                                  color={theme.palette.primary.main}
                                  icon={faCameraSlash}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          {participant.grade.length === 0
                            ? ""
                            : getGradeName(participant.grade)}
                        </TableCell>
                        <TableCell>
                          <Link
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            href={`/programs/${participant.programId}`}
                            sx={{
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            <Typography>{participant.title}</Typography>
                          </Link>
                        </TableCell>
                        {!takingAttendance && showSchool && (
                          <TableCell>{participant.school}</TableCell>
                        )}
                        {!takingAttendance && showTransportation && (
                          <TableCell>{participant.transportation}</TableCell>
                        )}
                        {!takingAttendance && showTshirt && (
                          <TableCell>{participant.teeShirtSize}</TableCell>
                        )}
                        {!takingAttendance && showContacts && (
                          <TableCell>
                            {participant.contacts?.length > 0 &&
                              participant.contacts?.map((c, index) => {
                                return (
                                  <Box key={index}>
                                    <Typography
                                      component="span"
                                      variant="caption"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {c.firstName}&nbsp;{c.lastName}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      variant="caption"
                                    >
                                      {c.contactPhones?.length > 0 &&
                                        c.contactPhones?.map((phone, i) => (
                                          <span>
                                            ,&nbsp;
                                            {formatPhoneNumber(
                                              phone?.phoneNumber,
                                              true
                                            )}
                                          </span>
                                        ))}
                                    </Typography>
                                    {showEmails && !!c.emailAddress && (
                                      <Typography
                                        component="span"
                                        variant="caption"
                                      >
                                        ,&nbsp;
                                        <Link
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          href={`mailto:${c.emailAddress}`}
                                          sx={{
                                            textDecoration: "none",
                                            "&:hover": {
                                              textDecoration: "underline",
                                            },
                                          }}
                                        >
                                          {c.emailAddress}
                                        </Link>
                                      </Typography>
                                    )}
                                  </Box>
                                );
                              })}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={roster?.length ?? 0}
          rowsPerPage={rowsPerPage ?? 0}
          page={page ?? 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {takingAttendance && (
        <>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                disabled={MergeAttendance.isLoading}
                onClick={() => {
                  SubmitAttendance();
                }}
                variant="contained"
              >
                Submit Attendance
              </Button>
            </Grid>
            <Grid item>
              <Typography fontSize="0.75rem">
                Attendance for all pages will be submitted.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
