import {
  Avatar,
  Box,
  Chip,
  // Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  // Tab,
  // Tabs,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
// import { Outlet, useLocation, useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import { useGetParticipantDetails } from "../../services/hooks/participantsHook";
import Error from "../common/Error";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileMedical,
  faMessageMedical,
  faCameraSlash,
} from "@fortawesome/pro-solid-svg-icons";
import StudentPhoto from "../common/StudentPhoto";
import { formatPhoneNumber, getGradeName } from "../../services/utilities";
import { ScrollToTopOnMount } from "../../services/utilities";
import { useUser } from "../../services/contexts/userContext";

export default function ReportsStudentDetails() {
  const { participantId } = useParams();
  const participantQuery = useGetParticipantDetails(participantId);
  const participant = participantQuery?.data?.data;
  const { user } = useUser();
  // const location = useLocation();
  // const navigate = useNavigate();

  const theme = useTheme();
  // const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  // const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

  // const ChangeTab = (event, newValue) => {
  //   // navigate(`/reports/instructor/${newValue}`);
  //   navigate(`${newValue}`);
  // };

  // const GetSelectedTab = () => {
  //   const tab = location.pathname
  //     .replace(`/reports/student/${participantId}/`, "")
  //     .replace(`/reports/student/${participantId}`, "");
  //   if (tab === "") {
  //     return "demographics"; //setting a default avoids console error if URL is /reports/instructor and then redirects to full path
  //   }
  //   return tab;
  // };

  // const slicers = {
  //   providerIds: [],
  //   schoolYears: [],
  //   sessionIds: [],
  //   locationIds: [],
  //   activityIds: [],
  //   activityTypeIds: [],
  //   grades: [],
  //   studentPrograms: [],
  //   genders: [],
  //   races: [],
  //   transportationIds: [],
  // };

  const getContactType = (contactType, livingWith) => {
    if (contactType === "G")
      return (
        <span>
          Guardian
          {!!livingWith && (
            <Typography component="span" variant="caption">
              &nbsp;(living&nbsp;with)
            </Typography>
          )}
        </span>
      );
    if (contactType === "C") return "Contact";
    if (contactType === "O") return "Other";
    else return contactType;
  };

  const GetProgramChipStyles = (program) => {
    //switch statement for 4 different programs
    switch (program) {
      case "504":
        return {
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          fontWeight: "bold",
        };
      case "ELL":
        return {
          background: "transparent",
          border: `2px solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
          fontWeight: "bold",
        };
      case "FRL":
        return {
          background: "transparent",
          border: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          fontWeight: "bold",
        };
      case "Spec Ed":
        return {
          background: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };

  //styles
  const lesserTextStyles = {
    fontSize: "0.75rem",
  };
  const restrainingNoticeStyles = {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    p: theme.spacing(2),
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">
        Reports
        <Typography component="span" variant="h3" sx={{ fontWeight: 400 }}>
          : Student Details
        </Typography>
      </Typography>
      {participantQuery?.isLoading && (
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton width="8rem" height="8rem" variant="circular" />{" "}
          </Grid>
          <Grid item>
            <Skeleton height="3rem" width="300px" />
            <Skeleton height="2rem" width="100px" />
            <Skeleton width="350px" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton height="2rem" width="200px" />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Grid>
        </Grid>
      )}
      <Error
        message="There was an error getting this participant's details"
        query={participantQuery}
      />
      {participantQuery.isSuccess && (
        <>
          <Grid
            container
            spacing={2}
            sx={{ mt: 1, mb: 1, alignItems: "center" }}
          >
            <Grid item>
              <StudentPhoto
                studentId={participant?.studentId}
                styles={{
                  height: "16rem",
                  width: "16rem",
                  boxShadow: "0 3px 10px #000",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3" component="h2">
                {participant.lastName}, {participant.firstName}
              </Typography>
              <Typography variant="h5" component="h3">
                {(user?.role === "TPS Admin" ||
                  user?.role === "TPS Owner" ||
                  user?.role === "TPS Viewer") &&
                  participant?.studentId}
                {participant.status !== "Active" && (
                  <Typography component="span" variant="h6" color="error">
                    &nbsp;|&nbsp;Inactive
                  </Typography>
                )}
              </Typography>
              <Typography>
                {!!participant.grade
                  ? getGradeName(participant.grade)
                  : "Unknown Grade"}
                {" | "}
                {!!participant.school ? participant.school : "Unknown School"}
                {" | "}
                {" Race: "}
                {participant.race ?? "Unknown"}
                {!!participant.teeShirtSize &&
                  ` | T-Shirt Size: ${participant.teeShirtSize}`}
              </Typography>
              {(participant?.studentPrograms?.length > 0 ||
                participant?.photoOptOut === "Y" ||
                participant?.exclusionaryCount) && (
                <Grid item xs={12} sm={9} md={10} sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    {/* Student programs */}
                    {participant?.studentPrograms?.map((sp, i) => (
                      <Grid
                        item
                        key={i}
                        sx={
                          !(
                            user?.role === "TPS Admin" ||
                            user?.role === "TPS Owner" ||
                            user?.role === "TPS Viewer" ||
                            user?.role === "Partner Manager"
                          ) &&
                          sp.program === "FRL" && { display: "none" }
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            sx={GetProgramChipStyles(sp.program)}
                            label={`${sp.program}`}
                          />
                          <Typography sx={lesserTextStyles}>
                            {sp.schoolYear}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                    {/* Photo Opt-out */}
                    {participant?.photoOptOut === "Y" && (
                      <Grid item>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              fontWeight: "bold",
                            }}
                            label={`Photo Opt-out`}
                            avatar={
                              <Avatar>
                                <FontAwesomeIcon icon={faCameraSlash} />
                              </Avatar>
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                    {/* Exclusionary count */}
                    {participant?.exclusionaryCount && (
                      <Grid item>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            sx={{
                              backgroundColor: "transparent",
                              border: `2px solid ${theme.palette.error.main}`,
                              color: theme.palette.error.main,
                              fontWeight: "bold",
                            }}
                            label={`Exclusionary Count: ${participant.exclusionaryCount}`}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2}>
        {/* Restraining Note */}
        {participant?.restrainingProtectionNote && (
          <Grid item xs={12} sm={9} md={10}>
            <Paper sx={restrainingNoticeStyles}>
              <Typography>
                <strong>Restraining Notice</strong>
              </Typography>
              <Typography>{participant?.restrainingProtectionNote}</Typography>
            </Paper>
          </Grid>
        )}

        {/* Medical alerts */}
        {participant?.medicalAlerts?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" color="error">
              <FontAwesomeIcon icon={faMessageMedical} />
              &nbsp;Medical&nbsp;Alerts
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {participant?.medicalAlerts?.map((alert, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{alert.description} </TableCell>
                      <TableCell>{alert.comment} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {participant?.medicalAlertComment && (
              <Typography>
                <FontAwesomeIcon
                  size="lg"
                  icon={faFileMedical}
                  color={theme.palette.error.dark}
                />{" "}
                {participant.medicalAlertComment.join(", ")}
              </Typography>
            )}
          </Grid>
        )}

        {/* Emergency Contacts */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">
            Emergency Contacts
          </Typography>
          {participant?.emergencyContacts?.length === 0 ? (
            <Typography>No emergency contacts in E-School</Typography>
          ) : (
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Home</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participant?.emergencyContacts?.map((c, index) => (
                    <TableRow key={index} hover>
                      <TableCell>
                        {getContactType(c?.contactType, c?.livingWith)}
                      </TableCell>
                      <TableCell>
                        {c?.firstName} {c?.lastName}
                      </TableCell>
                      <TableCell>
                        {!!c?.mobilePhone && (
                          <Link href={`tel:${c?.mobilePhone}`}>
                            {formatPhoneNumber(c?.mobilePhone)}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {!!c?.homePhone && (
                          <Link href={`tel:${c?.homePhone}`}>
                            {formatPhoneNumber(c?.homePhone)}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {!!c?.emailAddress && (
                          <Link href={`mailto:${c?.emailAddress}`}>
                            {c?.emailAddress}
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        {/* Program Participation */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">
            Program Participation
          </Typography>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Activity</TableCell>
                  <TableCell>Entry Date</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participant?.currentProgramParticipation?.map((cpp, i) => {
                  let status =
                    dayjs() < dayjs(cpp?.startDate)
                      ? "upcoming"
                      : dayjs() >= dayjs(cpp?.startDate) &&
                        dayjs() < dayjs(cpp?.endDate)
                      ? "current"
                      : "complete";
                  return (
                    <TableRow
                      key={i}
                      hover
                      sx={{
                        backgroundColor:
                          status === "complete" &&
                          theme.palette.background.default,
                      }}
                    >
                      <TableCell
                        sx={{
                          color:
                            status === "complete" &&
                            theme.palette.text.secondary,
                        }}
                      >
                        {cpp?.activityName}
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            status === "complete" &&
                            theme.palette.text.secondary,
                        }}
                      >
                        {dayjs(cpp?.entryDate).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            status === "complete" &&
                            theme.palette.text.secondary,
                        }}
                      >
                        {cpp?.locationName}
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            status === "complete" &&
                            theme.palette.text.secondary,
                        }}
                      >
                        {status}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* Tabs */}
        {/* <Box sx={{ maxWidth: "98vw" }}>
          <Tabs
            onChange={ChangeTab}
            value={GetSelectedTab()}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab value="demographics" label="Demographics" />
            <Tab value="attendance" label="Attendance" />
            <Tab value="enrollment" label="Enrollment" />
            <Tab value="assessments" label="Assessments" />
            <Tab value="marks" label="Marks" />
            <Tab value="discipline" label="Discipline" />
          </Tabs>
          <Divider />
          <br />
          <Outlet context={[slicers]} />
        </Box> */}

        {/* Test Scores 
        Doesn't work: lastSbaelalevel and lastSbamathLevel are no longer part of the participant object
        */}
        {/* <Typography variant="h5" component="h3">
          Test Scores
        </Typography>
        <TableContainer>
          <Table size="small" sx={smScreen && { width: "50%" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  {mdScreen ? "Smarter Balanced Assessment" : "SBA"}
                </TableCell>
                <TableCell align="center">Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell>
                  {mdScreen ? "English Language Arts/Literacy" : "ELA"}
                </TableCell>
                <TableCell align="center">
                  {participant?.lastSbaelalevel?.replace("Level ", "") ?? "N/A"}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Math</TableCell>
                <TableCell align="center">
                  {participant?.lastSbamathLevel?.replace("Level ", "") ??
                    "N/A"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer> */}
      </Grid>
    </>
  );
}
