import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetPartnerHomeCurrentProgramCounts } from "../../services/hooks/partnerHomeHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function HomeTableCurrentProgramCounts() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const query = useGetPartnerHomeCurrentProgramCounts();
  const data = query?.data?.data;

  return (
    <Paper
      sx={{
        ...commonStyles.paperPadding,
        height: "100%",
        maxHeight: 365,
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Current Program Enrollment
        </Typography>
      </Box>

      <TableContainer>
        <Table size="small">
          <TableBody>
            {data?.programCounts?.map((d, i) => (
              <React.Fragment key={i}>
                <TableRow key={i * 2 + 0}>
                  <TableCell colSpan={3}>
                    <Typography>
                      <strong>{d.title}</strong> @&nbsp;
                      {d.location.replace(/ /g, "\u00A0")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={i * 2 + 1}>
                  <TableCell>
                    Enrolled <strong>{d.enrolledCount}</strong>
                  </TableCell>
                  <TableCell>
                    Withdrawn <strong>{d.withdrawnCount}</strong>
                  </TableCell>
                  <TableCell>
                    Total <strong>{d.totalCount}</strong>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
