import { Box, Grid, Typography } from "@mui/material";
import BouncingO from "../common/BouncingO";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function LoadingUser(props) {
  const { userQuery } = props;

  const GetRandomLoadingMessage = () => {
    const messages = [
      "Opening doors to success",
      "Getting ready to rock",
      "Building the future",
      "Enabling postive feedback loops",
      "Powering Anti-Mass Spectrometers",
      "Calibrating warp stabilizers",
      "Downloading more RAM",
      "Dialing the modem",
      "Checking in on old friends",
      "Doing the heavy lifting",
      "Waiting on the minions",
      "How about this weather, eh?",
      "Ordering 1s and 0s...",
      "Initializing the initializer...",
      "Dividing by zero...",
      "Making sure all the i's have dots...",
      "Adjusting the flux capacitor...",
      "Let's take a mindfulness moment...",
      "Combobulating discombobulator",
      "Howdy, partner!",
      "Howdy, partner!", // giving this one a little extra weight
      "Turning 'impossible' into 'I'm possible'",
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const centerContentStyles = {
    alignItems: "center",
    display: "flex",
    minHeight: "63vh",
    justifyContent: "center",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Box sx={centerContentStyles}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item>
            <BouncingO />
          </Grid>
          <Grid item>
            <Typography>{GetRandomLoadingMessage()}...</Typography>
          </Grid>
          <Grid item xs={12}>
            {userQuery?.failureCount > 0 && (
              <>
                <Typography align="center">
                  Looks like we're having trouble getting some data. 😓
                </Typography>
                <Typography align="center" fontSize="0.75rem">
                  We'll keep trying
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
