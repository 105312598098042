import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ReportSummary({ status, slicerData }) {
  const commonStyles = useCommonStyles();

  const programCounts = useMemo(() => {
    return slicerData?.programCounts;
  }, [slicerData]);

  const attendanceSum = useMemo(() => {
    return slicerData?.programs?.reduce(
      (total, program) => total + program.attendance,
      0
    );
  }, [slicerData]);

  const attendanceAverage =
    slicerData?.programs?.length > 0
      ? ((attendanceSum / slicerData?.programs?.length) * 100).toFixed(2)
      : 0.0;

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Grid container spacing={2} justifyContent="space-around">
        {/* Programs */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.programs}
              </Typography>
            )}
            <Typography>Programs</Typography>
          </Stack>
        </Grid>

        {/* Activities */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.activities}
              </Typography>
            )}
            <Typography>Activities</Typography>
          </Stack>
        </Grid>

        {/* Providers */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.providers}
              </Typography>
            )}
            <Typography>Providers</Typography>
          </Stack>
        </Grid>

        {/* Participants */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.participants}
              </Typography>
            )}
            <Typography>Participants</Typography>
          </Stack>
        </Grid>

        {/* Students */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.students}
              </Typography>
            )}
            <Typography>Students</Typography>
          </Stack>
        </Grid>

        {/* Program Days */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {programCounts?.programDays}
              </Typography>
            )}
            <Typography>Program Days</Typography>
          </Stack>
        </Grid>

        {/* Days Attended */}
        <Grid item>
          <Stack textAlign={"center"}>
            {status ? (
              <Typography>
                <CircularProgress size={20} />
              </Typography>
            ) : (
              <Typography fontSize={"1.25rem"}>
                {/* {(programCounts?.AttendedDays * 100).toFixed(2)}% */}
                {attendanceAverage}%
              </Typography>
            )}
            <Typography>Attendance</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
