import { useEffect, useState, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  Link,
  List,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  Tooltip,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  formControlClasses,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as currency from "currency.js";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ParticipantRegistration from "../common/ParticipantRegistration";
import WaitlistParticipantRegistration from "../common/waitlistParicipantRegistration";
import Error from "../common/Error";
import ProgramDaysCalendar from "../common/ProgramDaysCalendar";
import {
  useGetProgramDetailsPage,
  useGetProgramGuardianEmails,
} from "../../services/hooks/programsHook";
import {
  useGetWaitlistByProgramId,
  useGetWaitlistStatuses,
  useUpsertWaitlist,
  useGetParticipantsByProgramId,
  useGetParticipantsPriorityContact,
  useWithdrawParticipant,
} from "../../services/hooks/participantsHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import ProgramStatusChip from "../common/ProgramStatusChip";
import ParticipantDetails from "../common/ParticipantDetails";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import {
  faCalendar,
  faPencil,
  faLocationDot,
  faFamily,
  faMessageMedical,
  faCircleExclamation,
  faCameraSlash,
  faUserPlus,
  faEllipsisV,
  faChevronDown,
  faPersonChalkboard,
  faXmark,
  faClipboard,
  faEnvelopes,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import {
  formatDate,
  formatDateTime,
  formatTime,
  getGradeName,
  getTimeRange,
  ScrollToTopOnMount,
} from "../../services/utilities";
import {
  ManagePrograms,
  RegisterStudents,
  TakeAttendance,
} from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";
import Overflow from "../common/Overflow";

export default function ProgramDetails() {
  const { user } = useUser();
  const { programId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const snackbar = useSnackbar();

  const waitlistQuery = useGetWaitlistByProgramId(programId);
  const waitlist = waitlistQuery?.data?.data;
  const [waitlistRegisterOpen, setWaitlistRegisterOpen] = useState();
  const [editStudentWaitlist, setEditStudentWaitlist] = useState();
  const [waitlistedStudent, setWaitlistedStudent] = useState();
  const [updatedWaitlistStatus, setUpdatedWaitlistStatus] = useState();
  const [selectedWaitlistStatus, setSelectedWaitlistStatus] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [waitlistNote, setWaitlistNote] = useState(null);
  const [isGuardianEmailsDialogOpen, setIsGuardianEmailsDialogOpen] =
    useState(false);

  const closeWaitlistRegistrationDialog = () => {
    setWaitlistRegisterOpen(false);
    setStudentForWaitlistMenu(null);
    setEditStudentWaitlist(false);
  };
  const closeWaitlistEditDialog = () => {
    setEditStudentWaitlist(false);
    setWaitlistedStudent(null);
    setWaitlistNote(null);
    setUpdatedWaitlistStatus(null);
  };
  const waitlistStatusQuery = useGetWaitlistStatuses();
  const waitlistStatusData = waitlistStatusQuery?.data?.data;

  const [inspectParticipant, setInspectParticipant] = useState(null);
  const [studentForParticipantsMenu, setStudentForParticipantsMenu] =
    useState(null);
  const [studentForParticipantsMenuEl, setStudentForParticipantsMenuEl] =
    useState(null);
  const [participantIdToEdit, setParticipantIdToEdit] = useState(null);
  const [participantToWithdraw, setParticipantToWithdraw] = useState(null);

  const closeParticipantMenu = () => {
    setStudentForParticipantsMenu(null);
    setStudentForParticipantsMenuEl(null);
  };

  const [registerOpen, setRegisterOpen] = useState(false);
  const closeRegistrationDialog = () => {
    setRegisterOpen(false);
    setParticipantIdToEdit(null);
  };

  const [showWaitlist, setShowWaitlist] = useState(false);

  const [studentForWaitlistMenu, setStudentForWaitlistMenu] = useState(null);
  const [studentForWaitlistMenuEl, setStudentForWaitlistMenuEl] =
    useState(null);

  const [nextOnWaitlist, setNextOnWaitlist] = useState(null);

  const closeWaitlistMenu = () => {
    setStudentForWaitlistMenu(null);
    setStudentForWaitlistMenuEl(null);
  };

  const [waitlistExpanded, setWaitlistExpanded] = useState(null);

  const detailsQuery = useGetProgramDetailsPage(programId);
  const details = detailsQuery?.data?.data;

  const participantsQuery = useGetParticipantsByProgramId(programId);
  const participants = participantsQuery?.data?.data;

  const programGuardianEmailsQuery = useGetProgramGuardianEmails(programId);
  const guardianEmails = programGuardianEmailsQuery?.data?.data;

  const [withdrawalDate, setWithdrawalDate] = useState(dayjs());
  const withdrawParticipant = useWithdrawParticipant();

  const getWaitlistStatusById = (waitlistStatusId) => {
    const waitlistStatus = waitlistStatusData?.find(
      (status) => status?.waitlistStatusId === waitlistStatusId
    );
    return waitlistStatus ? waitlistStatus?.status : null;
  };

  //set next on waitlist
  useEffect(() => {
    if (waitlist && waitlist.length > 0) {
      const nextParticipant = waitlist
        .filter((p) => p.waitlistStatusId === 1)
        .sort(
          (a, b) => new Date(dayjs(a.orderDate)) - new Date(dayjs(b.orderDate))
        )[0];
      setNextOnWaitlist(nextParticipant);
    }
  }, [waitlist]);

  //set show waitlist
  useEffect(() => {
    if (waitlist?.length > 0 || details?.enrolled >= details?.maxEnrollment) {
      setShowWaitlist(true);
    } else {
      setShowWaitlist(false);
    }
  }, [details, waitlist?.length]);

  //set waitlist expanded
  useEffect(() => {
    if (!!details && !!showWaitlist) {
      const startDate = dayjs(details.startDate);
      const today = dayjs();
      const waitlistCloseDate = startDate.add(2, "week");
      const diff = waitlistCloseDate.diff(today, "day");
      if (diff < 0) {
        setWaitlistExpanded(false);
      } else {
        setWaitlistExpanded(true);
      }
    }
  }, [details, showWaitlist]);

  //set disableSubmit
  useEffect(() => {
    if (
      !selectedWaitlistStatus ||
      !updatedWaitlistStatus ||
      (selectedWaitlistStatus?.status.includes("removed from waitlist") &&
        (waitlistNote?.trim().length === 0 || !waitlistNote)) ||
      (selectedWaitlistStatus?.status.includes("prior absences") &&
        (waitlistNote?.trim().length === 0 || !waitlistNote)) ||
      (selectedWaitlistStatus?.status.includes("disciplinary issues") &&
        (waitlistNote?.trim().length === 0 || !waitlistNote))
    ) {
      setDisableSubmit(true);
      return;
    }

    setDisableSubmit(false);
  }, [updatedWaitlistStatus, waitlistNote, selectedWaitlistStatus]);

  const waitlistObject = {
    waitlistId: waitlistedStudent?.waitlistId,
    programId: programId,
    studentId: waitlistedStudent?.student?.studentId,
    waitlistStatusId: updatedWaitlistStatus,
    note: waitlistNote?.trim().length > 0 ? waitlistNote : null,
    TransportationId: waitlistedStudent?.transportationId,
    Cost: waitlistedStudent?.cost,
    modifiedDate: dayjs(),
    modifiedBy: user?.email,
    orderDate: waitlistedStudent?.orderDate,
  };
  const upsertWaitlist = useUpsertWaitlist(waitlistObject);
  const updateWaitlistStatus = () => {
    upsertWaitlist?.mutateAsync().then((res) => {
      setDisableSubmit(true);
      setUpdatedWaitlistStatus(null);
      setWaitlistNote(null);
      setSelectedWaitlistStatus(null);
      closeWaitlistEditDialog();
    });
  };

  //styles
  const centerContentStyles = {
    display: "flex",
    justifyContent: "center",
  };
  const descriptionStyles = {
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  };
  const detailsContainerStyles = {
    backgroundColor: theme.palette.nav?.main,
    borderRadius: "16px",
    color: theme.palette.nav?.contrastText,
    my: theme.spacing(4),
    p: theme.spacing(4),
    "* .MuiSkeleton-root": {
      backgroundColor: theme.palette?.nav?.contrastTextSecondary,
    },
  };
  const participantRowStyles = {
    cursor: "pointer",
  };
  const deemphasizedStyles = {
    "& td": {
      color: theme.palette.text.secondary,
    },
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
  };
  const conflictStyles = {
    mx: theme.spacing(1),
    border: `1px solid ${theme.palette.error?.main}`,
    cursor: "pointer",
    backgroundColor: theme.palette.error?.main,
  };
  const conflictTitleStyles = {
    whiteSpace: "pre-line",
    display: "flex",
    flexDirection: "column",
  };

  const { activeCount, registeredCount, withdrawnCount } = useMemo(() => {
    const activeCount = waitlist?.filter(
      (student) => student.waitlistStatusId === 1
    ).length;
    const registeredCount = waitlist?.filter(
      (student) => student.waitlistStatusId === 2
    ).length;
    const withdrawnCount = waitlist?.filter(
      (student) =>
        student.waitlistStatusId !== 1 && student.waitlistStatusId !== 2
    ).length;

    return { activeCount, registeredCount, withdrawnCount };
  }, [waitlist, waitlistStatusData]);

  return (
    <>
      <ScrollToTopOnMount />
      {inspectParticipant && (
        <ParticipantDetails
          details={details}
          participantId={inspectParticipant.participantId}
          studentId={inspectParticipant?.studentId}
          conflictingPrograms={
            inspectParticipant?.student?.conflictingEnrolledPrograms
          }
          onClose={() => {
            setInspectParticipant(null);
          }}
        />
      )}

      <Error
        message="There was a problem loading program details."
        query={detailsQuery}
      />
      <>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {detailsQuery?.isLoading ? (
                  <Skeleton variant="rect" height={120} width={600} />
                ) : (
                  <>
                    <Typography variant="h1">
                      {details?.title ?? details?.activityName}
                    </Typography>{" "}
                    <Typography variant="h6" component="p">
                      {user?.sharedPartners?.includes(details?.partnerId) &&
                        "Shared with you by "}
                      {details?.partnerName}{" "}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item>
                <ProgramStatusChip
                  startDate={details?.startDate}
                  endDate={details?.endDate}
                  numParticipants={details?.enrolled}
                  numAttendanceMissing={details?.numAttendanceMissing}
                  isDraft={details?.isDraft}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              {user?.permissions?.includes(ManagePrograms) && (
                <Grid item>
                  <Button
                    sx={commonStyles.commonMargin}
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faPencil} />}
                    onClick={() => {
                      navigate("/programs/edit/" + programId);
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
              {
                //(user?.partners?.includes(details?.partnerId) ||
                //user?.sharedPartners?.includes(details?.partnerId)) &&
                user?.permissions?.includes(TakeAttendance) && (
                  <Grid item>
                    <Button
                      sx={commonStyles.commonMargin}
                      variant="contained"
                      onClick={() => {
                        navigate(`/programs/attendance/${programId}`);
                      }}
                    >
                      Attendance
                    </Button>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: theme.spacing(1) }} />

        {details?.restrictions?.map((r, i) => (
          <Chip
            sx={{
              color: theme.palette.nav?.contrastText,
              backgroundColor: theme.palette.nav?.main,
              mx: theme.spacing(1),
            }}
            label={r.name}
            key={i}
          />
        ))}

        {detailsQuery.isLoading ? (
          <Skeleton variant="rect" width={"100%"} height="40px" />
        ) : (
          <Typography sx={descriptionStyles}>
            <Markdown remarkPlugins={[remarkGfm]}>
              {details?.description}
            </Markdown>
          </Typography>
        )}
        <Container maxWidth="xl" sx={detailsContainerStyles}>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={12} sm={7}>
              <Stack spacing={4} sx={{ ml: theme.spacing(5) }}>
                {/* Location info */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faLocationDot}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="200px" />
                          <Skeleton width="180px" />
                          <Skeleton width="190px" />
                        </>
                      ) : (
                        <>
                          <Typography>{details?.locationName}</Typography>
                          <Typography>{details?.room}</Typography>
                          <Typography>
                            {details?.enableTransportation
                              ? "Transportation enabled"
                              : "Transportation disabled"}
                          </Typography>
                          {details?.url && (
                            <Typography variant="a">{details?.url}</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Instructor */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faPersonChalkboard}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="200px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {details?.instructors?.length > 0
                              ? details?.instructors?.join(", ")
                              : "No instructors"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Schedule info */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xs={2} item sx={commonStyles.commonPadding}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faCalendar}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="190px" />
                          <Skeleton width="210px" />
                          <Skeleton width="120px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {dayjs(details?.startDate).format("MMM D, YYYY")} -{" "}
                            {dayjs(details?.endDate).format("MMM D, YYYY")}
                          </Typography>
                          <Typography>{details?.cycleName}</Typography>
                          <Typography>
                            {getTimeRange(
                              details?.defaultStartTime,
                              details?.defaultDurationHours
                            )}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {/* Compassinfo */}
                <div>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faFamily}
                        color={theme.palette.nav?.contrastTextSecondary}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {detailsQuery.isLoading ? (
                        <>
                          <Skeleton width="80px" />
                          <Skeleton width="150px" />
                          <Skeleton width="200px" />
                          <Skeleton width="80px" />
                          <Skeleton width="180px" />
                        </>
                      ) : (
                        <>
                          <Typography>
                            <strong>Offered to:</strong>
                          </Typography>
                          <Typography>
                            {details?.grades
                              ?.map((g) => getGradeName(g))
                              .join(", ")}
                          </Typography>
                          <Overflow>
                            <Typography>
                              at {details?.schools?.join(", ")}
                            </Typography>
                          </Overflow>

                          <br />
                          <Typography variant="h5">Compass</Typography>
                          <Typography>
                            {details?.isHidden
                              ? "Hidden in Compass"
                              : "Visible"}
                          </Typography>
                          <Typography>
                            {details?.advertiseDate
                              ? `Advertise date: ${dayjs(
                                  details?.advertiseDate
                                ).format("MMM D, YYYY")}`
                              : "No advertise date set"}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            Registration:{" "}
                            {dayjs(details?.registrationStartDate).format(
                              "MMM D, YYYY"
                            )}{" "}
                            -{" "}
                            {dayjs(details?.registrationEndDate).format(
                              "MMM D, YYYY"
                            )}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            Cost:{" "}
                            {currency(details?.cost).value !== 0
                              ? currency(details?.cost).format()
                              : "FREE"}
                          </Typography>
                          <Typography sx={commonStyles.deemphasizedText}>
                            T-Shirt Required:{" "}
                            {details?.isTeeShirtRequired ? "Yes" : "No"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={[centerContentStyles, { flexDirection: "column" }]}
            >
              <ProgramDaysCalendar programId={programId} />
              <Accordion
                sx={{ "&.MuiPaper-root": { borderRadius: "16px" } }}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                >
                  Program Days
                </AccordionSummary>
                <Box>
                  <TableContainer sx={{ maxHeight: 300 }}>
                    <Table size="small">
                      <TableBody>
                        {details?.programDays?.map((pd, i) => (
                          <TableRow
                            key={i}
                            sx={
                              pd?.isEvent
                                ? {
                                    backgroundColor: theme.palette.info.main,
                                    "& td": {
                                      color: theme.palette.info.contrastText,
                                    },
                                  }
                                : {}
                            }
                          >
                            <TableCell>
                              <Typography>
                                {formatDate(pd.scheduledDate)}
                              </Typography>
                            </TableCell>
                            <TableCell>{pd.room}</TableCell>
                            <TableCell>{formatTime(pd.startTime)}</TableCell>
                            <TableCell>
                              {pd.durationHours && pd.durationHours + " hours"}
                            </TableCell>
                            <TableCell>{pd.isEvent && "Event"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Accordion>
            </Grid>
          </Grid>
        </Container>

        {/* Waitlist */}
        {!!showWaitlist && user?.permissions.includes(RegisterStudents) ? (
          <Box sx={{ mb: 6 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3">Waitlist</Typography>
              </Grid>
              {(details?.enrolled >= details?.maxEnrollment ||
                details?.hasActiveWaitlist) && (
                <Grid item>
                  <Button
                    onClick={() => {
                      setWaitlistedStudent(studentForWaitlistMenu);
                      setWaitlistRegisterOpen(true);
                    }}
                    sx={commonStyles.commonMargin}
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                  >
                    Add to Waitlist
                  </Button>
                </Grid>
              )}
            </Grid>

            <Accordion expanded={waitlistExpanded}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                onClick={() => {
                  setWaitlistExpanded(!waitlistExpanded);
                }}
              >
                &nbsp;
              </AccordionSummary>
              <TableContainer sx={{ mt: "-4rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>Student</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Grade</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Waitlist date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Note</Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!waitlist &&
                      waitlist
                        ?.sort((a, b) => {
                          if (
                            a.waitlistStatusId === 1 &&
                            b.waitlistStatusId === 1
                          ) {
                            return (
                              new Date(dayjs(a.orderDate)) -
                              new Date(dayjs(b.orderDate))
                            );
                          } else if (a.waitlistStatusId === 1) {
                            return -1;
                          } else if (b.waitlistStatusId === 1) {
                            return 1;
                          } else {
                            return (
                              new Date(dayjs(a.orderDate)) -
                              new Date(dayjs(b.orderDate))
                            );
                          }
                        })
                        ?.map((p, i) => (
                          <TableRow
                            hover
                            key={i}
                            onClick={(e) => {
                              //e.stopPropagation();
                              setInspectParticipant(p);
                            }}
                            sx={{
                              ...((getWaitlistStatusById(
                                p?.waitlistStatusId
                              ) !== "Active" ||
                                p?.student?.studentId !==
                                  nextOnWaitlist?.studentId) &&
                                deemphasizedStyles),
                              cursor: "pointer",
                            }}
                          >
                            <TableCell
                              onClick={(e) => {
                                // e.stopPropagation();
                                if (
                                  e.target.tagName !== "BUTTON" &&
                                  e.target.tagName !== "A" &&
                                  e.target.tagName !== "IMG"
                                ) {
                                  setInspectParticipant(p);
                                }
                              }}
                            >
                              {p?.student?.lastName}, {p?.student?.firstName}
                              {!!p?.student?.conflictingEnrolledPrograms
                                ?.length > 0 && (
                                <Tooltip
                                  title={
                                    <Grid container>
                                      <List sx={conflictTitleStyles}>
                                        {p?.student?.conflictingEnrolledPrograms.map(
                                          (program) => (
                                            <Grid item>
                                              <Link
                                                sx={{
                                                  color:
                                                    theme.palette.nav
                                                      ?.contrastText,
                                                }}
                                                key={program.programId}
                                                component="button"
                                                onClick={() => {
                                                  navigate(
                                                    `/programs/${program.programId}`
                                                  );
                                                }}
                                              >
                                                {">>"}&nbsp;
                                                {program?.title
                                                  ? program?.title
                                                  : "No Title"}
                                              </Link>
                                            </Grid>
                                          )
                                        )}
                                      </List>
                                    </Grid>
                                  }
                                >
                                  <Chip
                                    color="error"
                                    sx={conflictStyles}
                                    label={"Conflicting program"}
                                  />
                                </Tooltip>
                              )}
                              {/*waitlist chip */}
                              {p?.student?.onMultipleWaitlists &&
                                p?.programId === details?.programId && (
                                  <Chip
                                    sx={{
                                      color: theme.palette.nav?.contrastText,
                                      backgroundColor: theme.palette.nav?.main,
                                      mx: theme.spacing(1),
                                      border: "1px solid",
                                      cursor: "pointer",
                                    }}
                                    label={"Multiple Waitlists"}
                                  />
                                )}
                            </TableCell>
                            <TableCell>{p?.student?.grade}</TableCell>
                            <TableCell>
                              {formatDateTime(p?.orderDate)}
                            </TableCell>
                            <TableCell>
                              {getWaitlistStatusById(p?.waitlistStatusId)}
                            </TableCell>{" "}
                            <TableCell>
                              {/*removes newline char, and adds note on top of previous note  */}
                              {p?.note?.split("\n").map((line, index) => (
                                <Typography key={index}>{line}</Typography>
                              ))}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                id={`waitlist-button-${p.student?.studentId}`}
                                aria-controls={
                                  studentForParticipantsMenu === p
                                    ? "waitlist-menu"
                                    : undefined
                                }
                                aria-expanded={
                                  studentForWaitlistMenu === p
                                    ? "true"
                                    : undefined
                                }
                                onClick={(e) => {
                                  //prevent propagation
                                  e.stopPropagation();
                                  setStudentForWaitlistMenu(p);
                                  setStudentForWaitlistMenuEl(e.target);
                                }}
                                sx={{ width: "2.5rem" }}
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
          </Box>
        ) : showWaitlist && !user?.permissions?.includes(RegisterStudents) ? (
          // <Box sx={{ mb: 6 }}>
          <Paper sx={commonStyles.paperPadding}>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item>
                <Stack textAlign={"center"}>
                  <Typography fontSize={"1.25rem"}>
                    {activeCount ? activeCount : 0}
                  </Typography>
                  <Typography>Active on waitlist</Typography>
                </Stack>
              </Grid>

              <Grid item>
                <Stack textAlign={"center"}>
                  <Typography fontSize={"1.25rem"}>
                    {registeredCount ? registeredCount : 0}
                  </Typography>
                  <Typography>Enrolled from waitlist</Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack textAlign={"center"}>
                  <Typography fontSize={"1.25rem"}>
                    {withdrawnCount ? withdrawnCount : 0}
                  </Typography>
                  <Typography>Withdrawn from waitlist</Typography>
                </Stack>
              </Grid>

              <Grid item>
                <Stack textAlign={"center"}>
                  <Typography fontSize={"1.25rem"}>
                    {waitlist ? waitlist?.length : 0}
                  </Typography>
                  <Typography>Total waitlisted</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          //   <Grid
          //     container
          //     spacing={2}
          //     justifyContent="space-"
          //     alignItems="center"
          //   >
          //     {waitlist?.filter((student) => student.waitlistStatusId === 1)
          //       ?.length > 0 && (
          //       <Grid item xs={6}>
          //         <Typography variant="h3">
          //           {
          //             waitlist?.filter(
          //               (student) => student.waitlistStatusId === 1
          //             ).length
          //           }{" "}
          //           students on the waitlist
          //         </Typography>
          //       </Grid>
          //     )}
          //   </Grid>
          // </Box>
          <> </>
        )}

        <WaitlistParticipantRegistration
          waitlist={waitlist}
          open={waitlistRegisterOpen}
          onClose={closeWaitlistRegistrationDialog}
          student={studentForWaitlistMenu}
          programDetails={details}
        />

        <ParticipantRegistration
          programId={programId}
          participantId={participantIdToEdit}
          open={registerOpen}
          onClose={closeRegistrationDialog}
          programDetails={details}
        />

        {/* Participants */}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h3">Participants</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  component="p"
                  color={
                    details?.enrolled > details?.maxEnrollment &&
                    theme.palette.error.main
                  }
                >
                  ({details?.enrolled} / {details?.maxEnrollment})
                </Typography>
              </Grid>
              {parseInt(details?.enrolled) > 0 && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<FontAwesomeIcon icon={faEnvelopes} />}
                    onClick={() => setIsGuardianEmailsDialogOpen(true)}
                  >
                    Get Guardian Emails
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          {user?.permissions?.includes(RegisterStudents) &&
            //show register if no active student on the waitlist
            !details?.hasActiveWaitlist && (
              <Grid item>
                <Button
                  disabled={
                    details?.isDraft ||
                    details?.enrolled >= details?.maxEnrollment ||
                    details?.manualEnrollment === false
                  }
                  sx={commonStyles.commonMargin}
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                  onClick={() => {
                    setRegisterOpen(true);
                  }}
                >
                  Register Student
                </Button>
                {details?.isDraft && (
                  <Typography>Enrollment not available when Draft</Typography>
                )}
                {details?.manualEnrollment === false && (
                  <Typography>Manual enrollment not enabled</Typography>
                )}
              </Grid>
            )}
        </Grid>
      </>
      <br />
      <Paper>
        <Error
          message={"There was a problem loading the participant list."}
          query={participantsQuery}
        />
        {participantsQuery.isSuccess && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Student</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Grade</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Registered</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Withdrawal</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Withdrawal Reason</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Transportation</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">T-Shirt&nbsp;Size</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participantsQuery.isLoading &&
                  [...Array(4)].map((a, i) => (
                    <TableRow key={i}>
                      <TableCell colSpan="6">
                        <Skeleton variant="rect" />
                      </TableCell>
                    </TableRow>
                  ))}
                {participantsQuery.isSuccess && participants?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                      <Typography sx={{ color: theme.palette.text.secondary }}>
                        No participants
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {participants?.map((p, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={
                      Boolean(p.withdrawalDate)
                        ? { ...participantRowStyles, ...deemphasizedStyles }
                        : participantRowStyles
                    }
                    onClick={() => {
                      setInspectParticipant(p);
                    }}
                  >
                    <TableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography>{p.studentName}</Typography>
                        </Grid>
                        {p.hasMedicalAlerts && (
                          <Grid item>
                            <FontAwesomeIcon
                              color={theme.palette.error.main}
                              icon={faMessageMedical}
                            />
                          </Grid>
                        )}
                        {!!p.restrainingProtectionNote && (
                          <Grid item>
                            <FontAwesomeIcon
                              size="xl"
                              color={theme.palette.error.main}
                              icon={faCircleExclamation}
                            />
                          </Grid>
                        )}
                        {p.photoOptOut && (
                          <Grid item>
                            <FontAwesomeIcon
                              color={theme.palette.primary.main}
                              icon={faCameraSlash}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography align="center">{p.grade}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center">
                        {dayjs(p.entryDate).format("M/D/YY")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center">
                        {p.withdrawalDate &&
                          dayjs(p.withdrawalDate).format("M/D/YY")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {p.withdrawalReason && p.withdrawalDate && (
                        <Typography align="center">
                          {p.withdrawalReason}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>{p.transportation}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{p?.teeShirtSize}</Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        id={`composition-button-${p.participantId}`}
                        aria-controls={
                          studentForParticipantsMenu === p
                            ? "composition-menu"
                            : undefined
                        }
                        aria-expanded={
                          studentForParticipantsMenu === p ? "true" : undefined
                        }
                        aria-haspopup="true"
                        onClick={(e) => {
                          //prevent propagation
                          e.stopPropagation();
                          setStudentForParticipantsMenu(p);
                          setStudentForParticipantsMenuEl(e.target);
                        }}
                        sx={{ width: "2.5rem" }}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      {/* Withdraw Participant Dialog */}
      <Dialog
        open={participantToWithdraw != null}
        onClose={() => {
          setParticipantToWithdraw(null);
        }}
      >
        <DialogTitle>
          Withdraw Participant {participantToWithdraw?.studentId}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to withdraw{" "}
            <strong>{participantToWithdraw?.studentName}</strong> from{" "}
            {details?.title}?
          </Typography>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={withdrawParticipant?.isLoading}
              label="Withdrawal Date"
              format="MM/DD/YY"
              value={dayjs(withdrawalDate)}
              onChange={(value) => {
                setWithdrawalDate(value);
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  label: "Withdrawal Date",
                },
              }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setParticipantToWithdraw(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            disabled={withdrawParticipant?.isLoading}
            onClick={() => {
              withdrawParticipant
                .mutateAsync({
                  participantId: participantToWithdraw?.participantId,
                  date: dayjs(withdrawalDate).format("MM-DD-YYYY"),
                })
                ?.then((res) => {
                  setParticipantToWithdraw(null);
                  setWithdrawalDate(dayjs());
                });
            }}
          >
            Withdraw
          </Button>
        </DialogActions>
      </Dialog>

      {/* Waitlist menu options */}
      <Menu
        id="waitlist-options-menu"
        aria-labelledby="waitlist-options-button"
        anchorEl={studentForWaitlistMenuEl}
        open={studentForWaitlistMenu !== null}
        onClose={closeWaitlistMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            closeWaitlistMenu();
            setInspectParticipant(studentForWaitlistMenu);
          }}
        >
          Details
        </MenuItem>
        {user?.permissions?.includes(RegisterStudents) && (
          <MenuItem
            onClick={() => {
              closeWaitlistMenu();
              setWaitlistedStudent(studentForWaitlistMenu);
              setEditStudentWaitlist(true);
            }}
            // disabled={studentForParticipantsMenu?.withdrawalDate != null}
          >
            Edit
          </MenuItem>
        )}
        {user?.permissions?.includes(RegisterStudents) &&
          studentForWaitlistMenu?.studentId === nextOnWaitlist?.studentId && (
            <MenuItem
              disabled={
                waitlist
                  ?.filter((wl) => wl.studentId === nextOnWaitlist?.studentId)
                  ?.map(
                    (wl) => wl.student.conflictingEnrolledPrograms?.length > 0
                  )?.[0] || details?.enrolled >= details?.maxEnrollment
              }
              onClick={() => {
                setWaitlistRegisterOpen(true);
              }}
              // onClick={() => {
              //   closeParticipantMenu();
              //   setParticipantToWithdraw(studentForParticipantsMenu);
              // }}
              // disabled={studentForParticipantsMenu?.withdrawalDate != null}
            >
              Register
            </MenuItem>
          )}
      </Menu>

      {/* Participant menu options */}
      <Menu
        id="participant-options-menu"
        aria-labelledby="participant-options-button"
        anchorEl={studentForParticipantsMenuEl}
        open={studentForParticipantsMenu !== null}
        onClose={closeParticipantMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            closeParticipantMenu();
            setInspectParticipant(studentForParticipantsMenu);
          }}
        >
          Details
        </MenuItem>
        {user?.permissions?.includes(RegisterStudents) && (
          <MenuItem
            onClick={() => {
              closeParticipantMenu();
              setParticipantIdToEdit(studentForParticipantsMenu.participantId);
              setRegisterOpen(true);
            }}
            disabled={studentForParticipantsMenu?.withdrawalDate != null}
          >
            Edit Registration
          </MenuItem>
        )}
        {user?.permissions?.includes(RegisterStudents) && (
          <MenuItem
            onClick={() => {
              closeParticipantMenu();
              setParticipantToWithdraw(studentForParticipantsMenu);
            }}
            disabled={studentForParticipantsMenu?.withdrawalDate != null}
          >
            Withdraw
          </MenuItem>
        )}
      </Menu>

      {/* Edit Student Waitlist Details */}
      <Dialog open={editStudentWaitlist} onClose={closeWaitlistEditDialog}>
        <DialogTitle>Edit Student Waitlist Details</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                disabled={
                  waitlistStatusQuery?.isLoading || waitlistedStudent == null
                }
                options={
                  waitlistedStudent?.waitlistStatusId === 2
                    ? waitlistStatusData?.filter((w) => w.status === "Active")
                    : waitlistStatusData?.filter(
                        (w) => w.status !== "Inactive - registered"
                      ) ?? []
                }
                getOptionLabel={(option) => `${option.status}`}
                getOptionDisabled={(option) =>
                  option.waitlistStatusId ===
                  waitlistedStudent?.waitlistStatusId
                }
                onChange={(event, val) => {
                  setUpdatedWaitlistStatus(val?.waitlistStatusId);
                  setSelectedWaitlistStatus(val);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Waitlist Status" />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Note"
                fullWidth
                multiline
                placeholder="unable to contact guardian..."
                onChange={(e) => setWaitlistNote(e.target.value)}
              >
                Enter a brief note explaining the option selected above
              </TextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={updateWaitlistStatus}
              variant="contained"
              disabled={disableSubmit}
            >
              Submit
            </Button>

            <Button onClick={closeWaitlistEditDialog}>Cancel</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isGuardianEmailsDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Fade}
      >
        <DialogTitle>Copy Guardian Emails</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsGuardianEmailsDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: "40px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "20px" }}>
            <TextField
              id="guardianEmails"
              label="Guardian Emails"
              defaultValue={guardianEmails && guardianEmails}
              InputProps={{
                readOnly: true,
              }}
              autoFocus={true}
              onFocus={(event) => {
                event.target.select();
              }}
              fullWidth={true}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faClipboard} />}
            onClick={() => {
              navigator.clipboard.writeText(guardianEmails);
              snackbar.enqueueSnackbar(`Guardian emails copied`, {
                variant: "success",
                autoHideDuration: 2500,
              });
              setIsGuardianEmailsDialogOpen(false);
            }}
          >
            Copy to clipboard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
