import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/system";
import TableSample from "./TableSample";

export default function TableTipsIntro(props) {
  const { sx } = props;
  const theme = useTheme();
  const mode = theme?.palette?.mode;
  const bulbColor = theme?.palette?.warning?.[mode];

  const slideStyles = {
    ...sx,
    background: theme?.palette?.text?.primary,
    color: theme?.palette?.background?.default,
  };

  return (
    <Box sx={slideStyles}>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 0px)",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography component="h1" variant="h1">
            <FontAwesomeIcon icon={faLightbulbOn} color={bulbColor} />
            &nbsp;Did you know?
          </Typography>
          <Typography variant="h5" component="div" sx={{ fontWeight: 300 }}>
            <strong>
              Data tables in this app are interactive and customizable.
            </strong>{" "}
            You can move, pin, hide, sort, and filter columns to organize the
            data and discover&nbsp;insights.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontWeight: 300 }}
            gutterBottom
          >
            Here's a sample table to play with. Learn how on the
            next&nbsp;slides...
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TableSample density="compact" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
