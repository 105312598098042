import { Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import HomeChartCountsBySession from "./HomeChartCountsBySession";
import HomeChartActivityTypesPie from "./HomeChartActivityTypesPie";
import HomeChartStudentPrograms from "./HomeChartStudentPrograms";
import HomeChartGenderRace from "./HomeChartGenderRace";
import HomeTableProviderProgramCounts from "./HomeTableProviderProgramCounts";
import HomeChartWaitlist from "./HomeChartWaitlist";
import HomeHowWeGotStarted from "./HomeHowWeGotStarted";

export default function HomePartner() {
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <HomeChartCountsBySession />
        </Grid>

        <Grid item xs={12} md={5}>
          <HomeChartActivityTypesPie />
        </Grid>

        {/* Removing Student Programs chart until we can ensure accuracy
        <Grid item xs={12} md={4}>
          <HomeChartStudentPrograms />
        </Grid>

        <Grid item xs={12} md={8}>
          <HomeChartGenderRace />
        </Grid> */}

        <Grid item xs={12}>
          <HomeChartGenderRace />
        </Grid>

        <Grid item xs={12} md={6}>
          <HomeTableProviderProgramCounts />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeChartWaitlist />
        </Grid>

        {/* <Grid item xs={12} md={12}>
          {/* <HomeHowWeGotStarted /> 
        </Grid> */}
      </Grid>
    </>
  );
}
