import { useCallback, useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  CircularProgress,
  Grid,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentEnrollment({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const generateChartOptions = useCallback(
    (filteredData, gradeLabels) => {
      const groupedData = {};
      filteredData.forEach((item) => {
        const grade = item.grade.toString();
        if (!groupedData[grade]) {
          groupedData[grade] = 0;
        }
        groupedData[grade] += 1;
      });
      const colors = theme.palette.chart;

      const chartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: gradeLabels,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Count",
            type: "bar",
            data: gradeLabels.map((grade) => groupedData[grade] || 0),
            itemStyle: {
              color: (params) => colors[params.dataIndex % colors.length],
            },
          },
        ],
      };

      return chartOptions;
    },
    [theme]
  );

  const chartOptions1to5 = useMemo(() => {
    const filteredData = data.filter(
      (item) =>
        item.grade.toLowerCase() === "k" || (item.grade >= 1 && item.grade <= 5)
    );
    return generateChartOptions(filteredData, [
      "K",
      "01",
      "02",
      "03",
      "04",
      "05",
    ]);
  }, [data, generateChartOptions]);

  const chartOptions6to8 = useMemo(() => {
    const filteredData = data.filter(
      (item) => item.grade >= 6 && item.grade <= 8
    );
    return generateChartOptions(filteredData, ["06", "07", "08"]);
  }, [data, generateChartOptions]);

  const chartOptions9to12 = useMemo(() => {
    const filteredData = data.filter(
      (item) => item.grade >= 9 && item.grade <= 12
    );
    return generateChartOptions(filteredData, ["09", "10", "11", "12"]);
  }, [data, generateChartOptions]);

  const hasChart6to8 = chartOptions6to8.series[0].data.some(
    (value) => value > 0
  );
  const hasChart9to12 = chartOptions9to12.series[0].data.some(
    (value) => value > 0
  );

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <>
          {chartOptions1to5.series[0].data.some((value) => value > 0) && (
            <>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Enrollment by Grade (K-5)
              </Typography>
              <EChartsReact option={chartOptions1to5} />
            </>
          )}
          <Grid container spacing={2}>
            {hasChart6to8 && (
              <Grid item xs={12} md={hasChart9to12 ? 6 : 12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Enrollment by Grade (6-8)
                </Typography>
                <EChartsReact option={chartOptions6to8} />
              </Grid>
            )}
            {hasChart9to12 && (
              <Grid item xs={12} md={hasChart6to8 ? 6 : 12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Enrollment by Grade (9-12)
                </Typography>
                <EChartsReact option={chartOptions9to12} />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
}
