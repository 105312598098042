import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
} from "@mui/material";

//Table functions
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    // no properties of el that you want to sort can be null or the sort won't work
    Object.keys(el).forEach((key) => {
      if (el[key] !== null && key === "grade") {
        if (el[key] === "PS" || el[key] === "PP") {
          el[key] = "-01";
        } // grade is preschool
        else if (
          el[key] === "K" ||
          el[key] === "K2" ||
          el[key] === "K3" ||
          el[key] === "KP"
        )
          el[key] = "00"; // grade is kindergarten
        else if (isNaN(parseInt(el[key]))) el[key] = ""; // grade we do not know about
        // else {
        //   el[key] = el[key];
        // } // grade number
      }
      if (el[key] === null) el[key] = ""; // null, so set to empty string
    });
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.noSort ? (
              <Typography>{headCell.label}</Typography>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography>{headCell.label}</Typography>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
