import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons";

export default function ChartStudentDiscipline({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const chartOptions = useMemo(() => {
    const distinctYears = Array.from(
      new Set(data?.map((d) => d.schoolYear))
    ).sort();

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      xAxis: {
        type: "category",
        data: data ? distinctYears : [],
      },
      yAxis: {
        type: "value",
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        textStyle: {
          color: theme?.palette?.text?.secondary,
        },
      },
      series: distinctYears.map((year, i) => ({
        name: year.toString(), // Set the name to the year
        type: "bar",
        stack: "total",
        label: {
          show: true,
          formatter: (params) => (params.value === 0 ? "" : params.value),
        },
        emphasis: {
          focus: "series",
        },
        data: distinctYears.map((y) => {
          return y === year
            ? data
                .filter((item) => item.schoolYear === year)
                .reduce(
                  (sum, item) => sum + item.totalExclusionaryDiscipline,
                  0
                )
            : 0; // Use 0 instead of null to ensure the bar is not rendered
        }),
        color: theme?.palette?.chart && theme?.palette?.chart[i], // Use the index to set the color
      })),
    };
  }, [data, theme]);

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography variant="h6" color={theme.palette.text.secondary}>
        Incidences by school year
      </Typography>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : data?.length === 0 ? (
        <Box sx={{ textAlign: "center", mx: "auto", p: 3 }}>
          <FontAwesomeIcon
            icon={faPartyHorn}
            size="5x"
            color={theme?.palette?.secondary?.main}
          />
          <br />
          <Typography variant="h5" component="p" sx={{ mt: 2 }}>
            Yay!{" "}
            <Typography component="span" variant="body1">
              Looks like you have no students with exclusionary
              discipline&nbsp;incidents...
            </Typography>
          </Typography>
        </Box>
      ) : (
        <EChartsReact option={chartOptions} key={JSON.stringify(data)} />
      )}
    </Paper>
  );
}
