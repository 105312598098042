import React, { useState, useMemo } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useGetPartners } from "../../services/hooks/partnersHook";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import Error from "../common/Error";
import { ManagePartners } from "../../services/permissions";
import { useUser } from "../../services/contexts/userContext";
import { ScrollToTopOnMount } from "../../services/utilities";
import { getComparator, stableSort } from "../../services/tableFunctions";
import GlossaryButtonDialog from "../common/GlossaryButtonDialog";

export default function Programs() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const navigate = useNavigate();
  const { user } = useUser();

  const partnerQuery = useGetPartners();
  const [searchInput, setSearchInput] = useState("");
  const [showInactive, setShowInactive] = useState(
    "partnerListShowInactive",
    false
  );
  const filteredPartners = useMemo(() => {
    let copyPartners = partnerQuery?.data?.data;
    if (searchInput?.length > 0) {
      copyPartners = copyPartners?.filter((partner) => {
        return partner.name.toLowerCase().includes(searchInput?.toLowerCase());
      });
    }
    if (!showInactive) {
      copyPartners = copyPartners?.filter((partner) => partner.active);
    }

    copyPartners = copyPartners?.map((partner) => {
      return { ...partner, type: partner?.type?.name };
    });

    return copyPartners;
  }, [partnerQuery, searchInput, showInactive]);

  //table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "PartnersListRowsPerPage",
    10
  );

  const tableHeadCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      label: "Type",
    },
    {
      id: "icon",
      numeric: false,
      label: "Icon",
    },
    {
      id: "active",
      numeric: false,
      label: "Active",
    },
  ];

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      // rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {tableHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography>{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Styles
  const partnerTitleStyles = {
    fontSize: "1.2rem",
  };

  return (
    <>
      <ScrollToTopOnMount />

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h1">
            Partners
            <sup>
              <GlossaryButtonDialog term="partner" />
            </sup>
          </Typography>
        </Grid>
        {user?.permissions?.includes(ManagePartners) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/partners/create");
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Create Partner
            </Button>
          </Grid>
        )}
      </Grid>

      <br />

      <Paper>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={commonStyles.paperPadding}
        >
          {/* Search bar */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                value={searchInput}
                id="program-search"
                label="Search"
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setPage(0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!showInactive}
                  onChange={(e) => {
                    setShowInactive(e.target.checked);
                  }}
                />
              }
              label="Show Inactive"
            />
          </Grid>
        </Grid>

        <Error
          message={"There was a problem loading partners."}
          query={partnerQuery}
        />

        <>
          <TableContainer>
            <Table
              aria-label="partner table"
              size="small"
              sx={commonStyles.tableStyles}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredPartners?.length ?? 0}
              />

              <TableBody>
                {partnerQuery.isSuccess && filteredPartners?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="8">
                      <Typography
                        sx={{
                          m: theme.spacing(2),
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <strong>No partners found</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {partnerQuery.isLoading &&
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan="8">
                          <Skeleton variant="rect" height={60} />
                        </TableCell>
                      </TableRow>
                    ))}
                {filteredPartners &&
                  stableSort(filteredPartners, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((partner, index) => (
                      <TableRow
                        key={index}
                        hover={true}
                        sx={{
                          cursor:
                            user?.permissions?.includes(ManagePartners) &&
                            "pointer",
                          backgroundColor:
                            !partner.active && theme.palette.background.default,
                        }}
                        onClick={() => {
                          if (user?.permissions?.includes(ManagePartners)) {
                            navigate(`/partners/edit/${partner.partnerId}`);
                          }
                        }}
                      >
                        <TableCell>
                          <Typography sx={partnerTitleStyles}>
                            {partner.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{partner.type}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {partner.iconUrl && (
                              <img
                                style={{ maxHeight: 100, maxWidth: 200 }}
                                src={partner.iconUrl}
                                alt="Partner icon"
                              />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {partner.active ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={filteredPartners?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </>
  );
}
