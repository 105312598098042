import React from "react";
import { Skeleton, useTheme } from "@mui/material";
import { DayPicker } from "react-day-picker";
import { useGetProgramDaysByProgramId } from "../../services/hooks/programDaysHook";
import dayjs from "dayjs";

export default function ProgramCalendar(props) {
  const { programId } = props;
  const programDaysQuery = useGetProgramDaysByProgramId(programId);
  const programDays = programDaysQuery?.data?.data;
  const theme = useTheme();

  const calendarStyles = {
    // --rdp-cell-size: 40px;
    // --rdp-accent-color: #0000ff;
    // --rdp-background-color: #e7edff;
    // --rdp-accent-color-dark: #3003e1;
    // --rdp-background-color-dark: #180270;
    // --rdp-outline: 2px solid var(--rdp-accent-color);
    // --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  };

  return (
    <>
      {programDaysQuery.isLoading && (
        <Skeleton variant="rectangular" width="270px" height="320px" />
      )}
      {programDays && (
        <DayPicker
          modifiersStyles={calendarStyles}
          selected={programDays.map((pd) => {
            return new Date(dayjs(pd.scheduledDate));
          })}
        />
      )}
    </>
  );
}
