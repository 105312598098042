import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetProgramDaysByProgramId(programId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `ProgramDays/GetProgramDaysByProgramId/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (programId == null) return Promise.resolve();

      return AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting calendar. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return query;
}

export function useGetProgramDaysAndAttendanceCountByProgramId(programId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `ProgramDays/GetProgramDaysAndAttendanceCountByProgramId/${programId}`;
  const query = useQuery(
    url,
    ({ signal }) => {
      if (programId == null) return Promise.resolve();

      return AppsGet(token, url, signal);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting program days. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return query;
}
