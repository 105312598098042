//GetTransportations

import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation, QueryCache } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

export function useGetTransportations() {
  const token = GetAccessToken();
  const url = "transportations/GetTransportations";
  const query = useQuery(url, async ({ signal }) => {
    return await AppsGet(token, url, signal);
  });
  return query;
}
