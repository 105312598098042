import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetEligibleStudents,
  useUpsertWaitlist,
  useGetGetWaitlistStatuses,
} from "../../services/hooks/participantsHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { getGradeName } from "../../services/utilities";
import { useUser } from "../../services/contexts/userContext";

export default function WaitlistParticipantRegistration(props) {
  const { programDetails, open, onClose, student, waitlist } = props;
  const user = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const waitlistStatusQuery = useGetGetWaitlistStatuses();
  const waitlistStatuses = waitlistStatusQuery?.data?.data;
  const [registeredStatus, setRegisteredStatus] = useState();
  const [studentId, setStudentId] = useState();

  const eligibleStudentsQuery = useGetEligibleStudents(
    programDetails?.programId,
    open
  );
  const eligibleStudents = eligibleStudentsQuery?.data?.data;

  useEffect(() => {
    waitlistStatuses?.forEach((s) => {
      if (s.status === "Inactive - registered") {
        setRegisteredStatus(s?.waitlistStatusId);
      }
    });
  }, [waitlistStatuses]);

  const close = () => {
    onClose();
  };

  const waitlistObject = {
    waitlistId: !!student ? student?.waitlistId : 0,
    programId: !!student ? student?.programId : programDetails?.programId,
    studentId: !!student ? student?.student?.studentId : studentId,
    waitlistStatusId: !!student ? registeredStatus : 1, //TODO MAKE DYNAMIC
    note: !!student ? student?.note : null,
    TransportationId: !!student ? student?.transportationId : null,
    Cost: !!student ? student?.cost : null,
    createdDate: !!student ? student?.createdDate : dayjs(),
    createdBy: !!student ? student?.createdBy : user?.email,
    modifiedDate: dayjs(),
    orderDate: !!student ? student.orderDate : dayjs(),
    modifiedBy: user?.email,
  };

  const upsertWaitlist = useUpsertWaitlist(waitlistObject);

  const registerStudent = () => {
    upsertWaitlist.mutateAsync().then((res) => {
      close();
      // postParticipant.mutateAsync().then((res) => {

      // });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      sx={{ "& .MuiPaper-root": { overflowY: "visible" } }}
    >
      <DialogTitle>
        {!!student
          ? "Waitlist Participant Registration"
          : "Add a student to the waitlist"}
      </DialogTitle>
      <Box sx={[commonStyles.paperPadding]}>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            paddingBottom: theme.spacing(2),
          }}
        >
          {!!student
            ? `Please confirm you would like to register ${student?.student?.lastName}
          , ${student?.student?.firstName} for ${programDetails?.title} from the
          waitlist.`
            : "The students available for registration are limited to active students"}
        </Typography>
        {!student && (
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(2) }}>
              <Autocomplete
                //   PaperComponent={({ children }) => (
                //     <Paper style={{ maxHeight: "300px" }}>{children}</Paper>
                //   )}
                //   ListboxProps={{ style: { maxHeight: "300px" } }}
                loading={!eligibleStudents}
                loadingText={<CircularProgress size={20} />}
                disablePortal
                getOptionDisabled={(option) =>
                  waitlist?.some(
                    (waitlistStudent) =>
                      (waitlistStudent?.studentId === option?.studentId &&
                        waitlistStudent?.waitlistStatusId === 1) ||
                      option?.enrolled
                  )
                }
                options={eligibleStudents ?? []}
                // getOptionDisabled={(option) => option?.enrolled ?? false}
                getOptionLabel={(option) =>
                  `${option.lastName}, ${option.firstName} (${
                    option.studentId
                  }) - ${option.school} - ${getGradeName(option.grade)}`
                }
                onChange={(event, value) => {
                  setStudentId(value?.studentId);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      !eligibleStudentsQuery?.isLoading ? (
                        "Student"
                      ) : (
                        <CircularProgress size={20} />
                      )
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            variant="contained"
            disabled={
              !!student ? student?.studentId == null : studentId == null
            }
            onClick={() => {
              registerStudent();
            }}
            endIcon={
              upsertWaitlist?.isLoading && <CircularProgress size={20} />
            }
          >
            {!!student ? "Register" : "Add to waitlist"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
