import EChartsReact from "echarts-for-react";
import { Paper, Typography, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartActivityTypeCounts({ status, slicerData }) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const chartOptions = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        top: -30,
        color: status ? theme.palette.text.secondary : theme.palette.chart,
        label: {
          color: theme.palette.text.secondary,
        },
        data: slicerData?.map((d, i) => {
          return {
            name: d.name,
            value: d.programs,
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography
        variant="h6"
        color={theme.palette.text.secondary}
        sx={{ pb: 0 }}
      >
        Programs by Activity Type
      </Typography>
      <EChartsReact option={chartOptions} />
    </Paper>
  );
}
