import { useTheme } from "@mui/material";

export default function TpsLogo(props) {
  const { width, height } = props;
  const theme = useTheme();

  return (
    <svg
      id="tps-logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={`${width ?? 200}px`}
      height={`${height ?? 63}px`}
      viewBox="0 0 200 63"
      enableBackground={`new 0 0 ${width ?? 200} ${height ?? 63}`}
    >
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M109.8,6.3c5.3,0,10.1,1.9,13.6,5.1l6.3-2.2L110.4,0l-19,9.1l5.2,2.1C100.1,8.1,104.7,6.3,109.8,6.3z"
      ></path>
      <path
        fill={theme.palette.primary.main}
        d="M109.9,8c-5.5,0-10.4,2.3-13.8,5.9c-1.4,1.5-2.5,3.3-3.3,5.3c-0.8,2-1.3,4.3-1.3,6.6c0,2.4,0.5,4.8,1.4,6.8 c0.8,1.9,1.9,3.6,3.2,5.1c3.3,3.6,8.2,5.9,13.7,5.9c10.2,0,18.3-7.8,18.3-17.8S120.1,8,109.9,8z M109.9,37.6 c-6.1,0-11.7-5.2-11.7-11.8S103.7,14,109.9,14c6.1,0,11.7,5.2,11.7,11.8S116,37.6,109.9,37.6z"
      ></path>
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M9.3,14.5H0V8.9h24.5v5.6h-9.3v26.2H9.3V14.5z"
      ></path>
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M38.7,14.2h-7.3V8.9h13.2l13.5,31.8h-6.3l-2.4-6H35.8l-2.4,6h-6.3L38.7,14.2z M47.2,29.4l-4.6-11.4L38,29.4 H47.2z"
      ></path>
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M135,8.2l14.1,14.3l14.1-14.3v32.4h-5.9V21l-8.2,8.6l-8.2-8.6v19.6H135V8.2z"
      ></path>
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M180.3,14.2H173V8.9h13.2l13.5,31.8h-6.3l-2.4-6h-13.5l-2.4,6h-6.3L180.3,14.2z M188.8,29.4l-4.6-11.4 l-4.7,11.4H188.8z"
      ></path>
      <path
        fill={theme.palette.nav?.contrastTextSecondary}
        d="M75.7,35.8c-5.7,0-10.9-4.8-10.9-11c0-6.2,5.2-11,10.9-11c3.5,0,6.5,1.6,8.5,4.3c0.8-2,1.9-3.7,3.3-5.3 C84.2,9.7,80,8.2,75.7,8.2c-9.5,0-17,7.2-17,16.5s7.5,16.5,17,16.5c4.3,0,8.5-1.5,11.9-4.7c-1.4-1.5-2.5-3.2-3.2-5.1 C82.3,34.2,79.2,35.8,75.7,35.8z"
      ></path>
      <g>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M15.1,58.6c-0.8,0.5-1.5,0.6-2.5,0.6h-1.3v3.6h-2V53H12c1.9,0,2.4,0.2,3,0.5c1,0.5,1.6,1.5,1.6,2.6 C16.6,57.2,15.9,58.2,15.1,58.6z M12.5,54.6h-1.2v3h1.3c1.1,0,1.7-0.3,1.7-1.5C14.3,54.9,13.5,54.6,12.5,54.6z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M30.6,62c-0.6,0.6-1.5,1-3,1c-1.8,0-2.9-0.6-3.5-1.4c-0.3-0.5-0.4-1.1-0.4-2.1V53h2.1v6.2 c0,0.7,0,1.1,0.2,1.4c0.3,0.5,0.9,0.8,1.6,0.8c0.7,0,1.1-0.2,1.4-0.5c0.3-0.3,0.4-0.6,0.4-1.6V53h2.1v6.4 C31.5,60.9,31.3,61.4,30.6,62z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M45.6,62.5c-0.6,0.2-1.2,0.3-2.2,0.3h-3.7V53h3.6c1,0,1.5,0.1,2,0.3c0.9,0.4,1.5,1.2,1.5,2.1 c0,1-0.7,1.8-1.7,2.1c1.2,0.3,2.1,1.2,2.1,2.5C47.3,61.1,46.7,62,45.6,62.5z M43.1,54.6h-1.4v2.3h1.4c0.9,0,1.6-0.1,1.6-1.1 C44.8,54.8,43.9,54.6,43.1,54.6z M43.2,58.5h-1.5v2.7h1.4c0.9,0,1.9-0.2,1.9-1.3C45.1,58.8,44.1,58.5,43.2,58.5z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M60.8,62.8h-5.7V53h2v8.1h4L60.8,62.8z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M67.9,62.8V53H70v9.8H67.9z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M82.5,62.9c-1.4,0-2.5-0.3-3.4-1.2c-1-0.9-1.5-2.2-1.5-3.7c0-1.3,0.3-2.3,0.8-3.1c0.9-1.3,2.4-2.2,4.1-2.2 c1,0,2.1,0.3,2.8,0.8l-1,1.3c-0.6-0.3-1.1-0.5-1.8-0.5c-0.9,0-1.6,0.4-2.1,1.1c-0.4,0.5-0.6,1.3-0.6,2.4c0,1.4,0.1,2.1,0.7,2.7 c0.5,0.6,1.2,0.9,2,0.9c0.7,0,1.3-0.2,1.9-0.6l1,1.2C84.7,62.6,83.7,62.9,82.5,62.9z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M105.2,63c-1.2,0-2.5-0.3-3.6-0.9l0.7-1.6c1,0.5,1.9,0.8,3,0.8c0.8,0,2-0.2,2-1.2c0-0.6-0.5-1-1.6-1.3 l-1.3-0.3c-1.4-0.4-2.5-1.2-2.5-2.8c0-1.8,1.6-3,3.8-3c1.3,0,2.7,0.4,3.6,1l-1,1.4c-0.9-0.5-1.8-0.8-2.6-0.8c-1,0-1.6,0.4-1.6,1.1 c0,0.6,0.3,0.9,1.3,1.1l1.3,0.3c1.5,0.4,2.7,1.1,2.7,2.8C109.6,62.1,107.2,63,105.2,63z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M121.3,62.9c-1.4,0-2.5-0.3-3.4-1.2c-1-0.9-1.5-2.2-1.5-3.7c0-1.3,0.3-2.3,0.8-3.1c0.9-1.3,2.4-2.2,4.1-2.2 c1,0,2.1,0.3,2.8,0.8l-1,1.3c-0.6-0.3-1.1-0.5-1.8-0.5c-0.9,0-1.6,0.4-2.1,1.1c-0.4,0.5-0.6,1.3-0.6,2.4c0,1.4,0.1,2.1,0.7,2.7 c0.5,0.6,1.2,0.9,2,0.9c0.7,0,1.3-0.2,1.9-0.6l1,1.2C123.5,62.6,122.5,62.9,121.3,62.9z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M136.8,62.8v-4.4h-3.4v4.4h-2V53h2v3.8h3.4V53h2.1v9.8H136.8z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M151.2,62.9c-3,0-4.7-2-4.7-5c0-3.2,1.8-5.1,4.6-5.1c3.1,0,4.7,2.1,4.7,5C155.8,60.8,154.4,62.9,151.2,62.9 z M151.1,54.4c-2,0-2.3,1.9-2.3,3.5c0,1.8,0.2,3.6,2.4,3.6c2.1,0,2.3-1.9,2.3-3.5C153.5,56.2,153.2,54.4,151.1,54.4z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M167.7,62.9c-3,0-4.7-2-4.7-5c0-3.2,1.8-5.1,4.6-5.1c3.1,0,4.7,2.1,4.7,5C172.3,60.8,170.9,62.9,167.7,62.9 z M167.5,54.4c-2,0-2.3,1.9-2.3,3.5c0,1.8,0.2,3.6,2.4,3.6c2.1,0,2.3-1.9,2.3-3.5C169.9,56.2,169.7,54.4,167.5,54.4z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M185.7,62.8h-5.7V53h2v8.1h4L185.7,62.8z"
        ></path>
        <path
          fill={theme.palette.nav?.contrastTextSecondary}
          d="M195.6,63c-1.2,0-2.5-0.3-3.6-0.9l0.7-1.6c1,0.5,1.9,0.8,3,0.8c0.8,0,2-0.2,2-1.2c0-0.6-0.5-1-1.6-1.3 l-1.3-0.3c-1.4-0.4-2.5-1.2-2.5-2.8c0-1.8,1.6-3,3.8-3c1.3,0,2.7,0.4,3.6,1l-1,1.4c-0.9-0.5-1.8-0.8-2.6-0.8c-1,0-1.6,0.4-1.6,1.1 c0,0.6,0.3,0.9,1.3,1.1l1.3,0.3c1.5,0.4,2.7,1.1,2.7,2.8C200,62.1,197.6,63,195.6,63z"
        ></path>
      </g>
    </svg>
  );
}
