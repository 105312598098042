import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useGetMyInstructedPrograms } from "../../services/hooks/partnerHomeHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import ProgramTable from "./ProgramTable";

export default function HomeTableLocationProgramCounts() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const query = useGetMyInstructedPrograms();
  const data = query?.data?.data;

  return (
    <Paper>
      <Box
        sx={[
          commonStyles.paperPadding,
          { color: theme.palette.text.secondary },
        ]}
      >
        <Typography variant="h6">My Instructed Programs</Typography>
      </Box>
      <ProgramTable programs={data} query={query} />
    </Paper>
  );
}
