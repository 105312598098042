import { useQuery } from "react-query";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetCriteriaChoices() {
    const token = GetAccessToken();
    const url = `Dsa/GetCriteriaChoices`;
    const query = useQuery(url, async () => {
      return await AppsGet(token, url);
    });
  
    return query;
  }