import { useMemo } from "react";
import { Button, Collapse } from "@mui/material";
import { useState } from "react";

export default function Overflow(props) {
  const { wordCount = 10 } = props;
  const text = props?.children?.props?.children?.join(" ") ?? "";

  const [open, setOpen] = useState(false);

  const shortText = useMemo(
    () => text?.split(" ")?.slice(0, wordCount)?.join(" "),
    [text, wordCount]
  );
  const longText = useMemo(
    () =>
      text
        ?.split(" ")
        ?.slice(wordCount + 1, text?.split(" ")?.length)
        ?.join(" "),
    [text, wordCount]
  );

  return (
    <>
      {text?.split(" ")?.length > wordCount ? (
        <>
          <span>{shortText}</span>
          <Collapse in={open} out={!open && ""}>
            {longText}
          </Collapse>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            Show {open ? "less" : "more"}
          </Button>
        </>
      ) : (
        text
      )}
    </>
  );
}
