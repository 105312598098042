import { useEffect, useState } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import axios from "axios";

//Implemention from this guide:
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
export const GetAccessToken = async () => {
  var { instance, accounts, loginRequest } = useMsal();

  var silentRequest = {
    account: accounts[0],
    forceRefresh: false,
    scopes: [`${process.env.REACT_APP_GRAPH_APPS_SCOPE}`],
  };

  var request = {
    ...loginRequest,
    account: accounts[0],
    scopes: [`${process.env.REACT_APP_GRAPH_APPS_SCOPE}`],
  };

  if (accounts[0]) {
    return await instance
      .acquireTokenSilent(silentRequest)
      .then((response) => response)
      .catch(async (error) => {
        // console.error("acquireTokenSilent error: ", error);
        if (error instanceof InteractionRequiredAuthError) {
          console.error("acquireTokenSilent failed: ", error);
          return await instance.loginRedirect();
        }
      });
  }
};

export const AppsGet = async (token, url, signal) => {
  return token.then((token) => {
    return axios.get(`${process.env.REACT_APP_APPS_API_URI}/api/${url}`, {
      signal,
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
  });
};

export const AppsGetImage = async (token, url, signal) => {
  return await token.then(async (token) => {
    return await axios
      .get(`${process.env.REACT_APP_APPS_API_URI}/api/${url}`, {
        signal,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          "Content-Type": "image/jpg",
        },
      })
      .then((response) => {
        return "data:image/jpeg;base64," + response.data;
      });
  });
};

export const AppsPost = async (token, url, postBody, signal) => {
  return token.then((token) => {
    return axios.post(
      `${process.env.REACT_APP_APPS_API_URI}/api/${url}`,
      postBody,
      {
        signal,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          // signal,
        },
      }
    );
  });
};

export const AppsPut = async (token, url, putBody, signal) => {
  return token.then((token) => {
    return axios.put(
      `${process.env.REACT_APP_APPS_API_URI}/api/${url}`,
      putBody,
      {
        signal,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
  });
};

export const AppsPatch = async (token, url, patchBody, signal) => {
  return token.then((token) => {
    return axios.patch(
      `${process.env.REACT_APP_APPS_API_URI}/api/${url}`,
      patchBody,
      {
        signal,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
  });
};
