export const ViewPrograms = "View Programs";
export const ManagePrograms = "Manage Programs";
export const DraftPrograms = "Draft Programs";

export const ViewActivities = "View Activities";
export const ManageActivities = "Manage Activities";

export const ViewUsers = "View Users";
export const ManageUsers = "Manage Users";

export const ViewProviders = "View Providers";
export const ManageProviders = "Manage Providers";

export const ViewPartners = "View Partners";
export const ManagePartners = "Manager Partners";

export const ViewRoster = "View Roster";
export const ViewReports = "View Reports";
export const ViewFRL = "View FRL";
export const InstructPrograms = "Instruct Programs";
export const TakeAttendance = "Take Attendance";
export const RegisterStudents = "Register Students";
