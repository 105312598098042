import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import { Paper, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useGetPartnerHomeCounts } from "../../services/hooks/partnerHomeHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function HomeChartGenderRace() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const xsScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const query = useGetPartnerHomeCounts();
  const data = query?.data?.data?.races;

  const genders = useMemo(() => {
    let distinctGenders = [];
    data?.forEach((d) => {
      d?.genders?.forEach((gc) => {
        if (!distinctGenders?.includes(gc.gender))
          distinctGenders.push(gc.gender);
      });
    });
    return distinctGenders;
  }, [data]);

  const chartOptions = {
    grid: {
      left: xsScreen ? 20 : 60,
      right: 0,
      height: xsScreen ? 271 : 170,
      containLabel: xsScreen ? true : false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    xAxis: {
      type: "category",
      name: "Races",
      nameLocation: "middle",
      nameGap: xsScreen ? 120 : 60,
      nameTextStyle: {
        fontWeight: "bold",
      },
      data: data?.map((d) => d.race),
      axisLabel: {
        show: true,
        interval: 0,
        width: xsScreen ? 120 : 70,
        overflow: "break",
        rotate: xsScreen ? 90 : 0,
        hideOverlap: true,
      },
    },
    yAxis: {
      type: "value",
      name: "Students",
      nameLocation: "end",
      nameTextStyle: {
        align: "right",
        fontWeight: "bold",
      },
      axisLabel: {
        show: true,
        rotate: 0,
      },
    },
    legend: {
      orient: "horizontal",
      right: 5,
      textStyle: {
        color: theme.palette.text.secondary,
      },
    },
    series: genders?.map((g, i) => {
      return {
        color: theme.palette.chart && theme.palette.chart[i],
        name: g,
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.map((d) => {
          return d.genders?.find((gc) => gc.gender === g)?.students;
        }),
      };
    }),
  };

  return (
    <Paper
      sx={{ ...commonStyles.paperPadding, minHeight: xsScreen ? 365 : 300 }}
    >
      <Typography
        variant="h6"
        component="h2"
        color={theme.palette.text.secondary}
      >
        {xsScreen ? (
          <span>Current Enrollment by Gender/Race</span>
        ) : (
          <span>Current Enrollment by Gender and&nbsp;Race</span>
        )}
      </Typography>
      <EChartsReact option={chartOptions} style={xsScreen && { height: 365 }} />
    </Paper>
  );
}
